import './index.css';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

function ParticipantHistoryModal(props) {
  const { title, participantsHistory, meetingId, close } = props;
  return (
    <div>
      <div className="fixed h-screen w-full popup flex justify-center top-0 left-0 items-center z-[999]">
        <div
          role="button"
          tabIndex="0"
          aria-label="Close Modal"
          className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
          onKeyDown={() => {}}
          onClick={close}
        />
        <div className="relative popup-container">
          <h2 className="font-bold text-body">{title}</h2>
          <div style={{ overflowX: 'auto', width: '99%' }}>
            <table className="grid-meeting table-responsive">
              <thead>
                <tr className="text-[13px]">
                  <th className="bg-[#FFE1C2] font-normal rounded-tl-[20px] p-2">Created at</th>
                  <th className="bg-[#FFE1C2] font-normal">Email</th>
                  <th className="bg-[#FFE1C2] font-normal">Participant removed</th>
                  <th className="bg-[#FFE1C2] font-normal">Voucher Status</th>
                  <th className="bg-[#FFE1C2] font-normal rounded-tr-[20px]">Voucher Price</th>
                </tr>
              </thead>
              <tbody>
                {participantsHistory.length > 0 ? (
                  participantsHistory.map(
                    participant =>
                      participant.MeetingId === meetingId && (
                        <tr key={participant.Id} style={{ height: '0px' }}>
                          <td className="grid-meeting__time" style={{ fontSize: '13px' }}>
                            {moment(participant.CreatedAt.toDate()).format('MM-DD-YYYY')} <br />
                            {moment(participant.CreatedAt?.toDate()).format('hh:mm A')}
                          </td>
                          <td className="text-body text-center text-base">{participant.Email}</td>
                          <td className="text-body text-center text-base">{participant?.isDeleted ? 'YES' : 'NO'}</td>
                          <td className="text-body text-center text-base">{participant.VoucherCodeStatus}</td>
                          <td className="text-body text-center text-base">
                            {participant.VoucherPrice > 0 && `$ ${participant.VoucherPrice.toFixed()}`}
                          </td>
                        </tr>
                      ),
                  )
                ) : (
                  <span>History Not Found</span>
                )}
              </tbody>
            </table>
          </div>
          <div className="center-button">
            <button type="button" className="btn btn-secondary w-64" onClick={close}>
              {' '}
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ParticipantHistoryModal.propTypes = {
  title: PropTypes.string,
  participantsHistory: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  meetingId: PropTypes.string,
  close: PropTypes.func,
};

ParticipantHistoryModal.defaultProps = {
  title: '',
  participantsHistory: [],
  meetingId: '',
  close: () => {},
};
export default ParticipantHistoryModal;
