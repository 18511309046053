import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  REQUEST_DOWNLOAD_INVOICES,
  DOWNLOAD_INVOICES_SUCCESS,
  DOWNLOAD_INVOICES_FAILURE,
} from '../constants/actionTypes';

// GET INVOICES

export const requestGetInvoices = () => {
  return {
    type: GET_INVOICES_REQUEST,
  };
};

export const receiveAllInvoices = invoices => {
  return {
    type: GET_INVOICES_SUCCESS,
    invoices,
  };
};

export const getInvoicesError = message => {
  return {
    type: GET_INVOICES_FAILURE,
    message: message,
  };
};

// DOWNLOAD INVOICES

export const requestDownloadInvoices = () => {
  return {
    type: REQUEST_DOWNLOAD_INVOICES,
  };
};

export const downloadInvoiceSuccessfully = invoices => {
  return {
    type: DOWNLOAD_INVOICES_SUCCESS,
    invoices,
  };
};

export const downloadInvoiceError = message => {
  return {
    type: DOWNLOAD_INVOICES_FAILURE,
    message: message,
  };
};
