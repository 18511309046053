/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import CloseIcon from 'components/icons/close-icon';
import HamburgerIcon from 'components/icons/ham-burger';
import { setHeader } from '../../redux/actions/meetings';
import logo from '../../assets/full-logo.png';
import logoutImg from '../../assets/logout.svg';
import { logout } from '../../services/firebase.service';

function Header(props) {
  const { user, currentUser } = props;
  const header = useSelector(state => state.header);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState('/dashboard');
  const location = useLocation();

  const onLogOut = () => {
    logout(dispatch, navigate);
  };

  const changePath = _route => {
    navigate(_route);
    setCurrentPath(_route);
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  return (
    <div className="static flex items-center justify-between mt-8 md:mt-0">
      <div
        role="presentation"
        onKeyDown={() => changePath('/dashboard')}
        onClick={() => changePath('/dashboard')}
        className="point"
      >
        <img src={logo} className="h-25 w-36 m-auto" alt="Logo" />
      </div>
      <div className="md:flex space-x-12 hidden">
        <div
          role="presentation"
          onKeyDown={() => changePath('/profile')}
          onClick={() => changePath('/profile')}
          className="point"
        >
          <div
            className={`${
              currentPath === '/profile'
                ? 'bg-primary hover:bg-primary-hover rounded-primary rounded-tl-none rounded-tr-none text-white font-bold'
                : ''
            }
            pt-16 pb-4 px-2`}
          >
            {user && user?._delegate && user?._delegate?.photoURL ? (
              <img src={user?._delegate?.photoURL} className="h-14 w-14 rounded-full" alt="User Profile" />
            ) : (
              <div className="h-14 w-14 rounded-full border-primary uppercase text-[27px] rounded-full bg-primary hover:bg-primary-hover text-white text-center p-1.5 font-bold">
                {user && user?._delegate && user?._delegate?.displayName
                  ? user?._delegate?.displayName.charAt(0)
                  : user?._delegate?.email.charAt(0)}
              </div>
            )}
          </div>
        </div>
        <div
          role="presentation"
          onKeyDown={() => changePath('/dashboard')}
          onClick={() => changePath('/dashboard')}
          className="point"
        >
          <div
            className={`${
              currentPath === '/dashboard'
                ? 'bg-primary hover:bg-primary-hover rounded-primary rounded-tl-none rounded-tr-none text-white'
                : 'text-primary'
            }
            font-bold pt-16 w-40 text-[27px] flex flex-col text-left`}
          >
            <span className="block ml-5">Your</span>
            <span className="block -mt-6 mb-4 ml-7 pl-0.5">Meetings</span>
          </div>
        </div>
        <div
          role="presentation"
          onKeyDown={() => changePath('/invoices')}
          onClick={() => {
            if (currentUser?.IsAccount === 'enable') {
              changePath('/invoices');
            }
          }}
          className={`point ${currentUser?.IsAccount !== 'enable' ? 'disabled' : ''}`}
        >
          <div
            className={`${
              currentPath === '/invoices'
                ? 'bg-primary hover:bg-primary-hover rounded-primary rounded-tl-none rounded-tr-none text-white'
                : 'text-primary hover:text-primary-hover'
            }
            pt-16 pb-4 px-2 text-[27px] font-bold`}
          >
            Invoices
          </div>
        </div>
        <div role="presentation" className="pt-16 point" onClick={() => onLogOut()}>
          <img src={logoutImg} className="h-8 w-8 " alt="Logout" />
        </div>
      </div>
      <button
        type="button"
        className="md:hidden"
        onClick={() => {
          dispatch(setHeader(true));
        }}
      >
        <HamburgerIcon />
      </button>
      <div
        className={`mobile-menu fixed top-0 left-0 w-full h-full bg-white z-[999] pt-8 px-6 ${
          header.isOpen ? 'block' : 'hidden'
        }`}
      >
        <div className="">
          <div className="flex justify-between">
            <a href="/dashboard">
              <img src={logo} className="w-32" alt="logo" />
            </a>
            <button
              type="button"
              className=""
              onClick={() => {
                dispatch(setHeader(false));
              }}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-col items-center space-y-5 mt-8 text-3xl font-bold text-body">
            <div
              role="presentation"
              onKeyDown={() => changePath('/invoices')}
              onClick={() => {
                changePath('/dashboard');
                dispatch(setHeader(false));
              }}
              className="point"
            >
              Your Meetings
            </div>
            <div
              role="presentation"
              onClick={() => {
                changePath('/profile');
                dispatch(setHeader(false));
              }}
              className="point"
            >
              Profile
            </div>
            <div
              role="presentation"
              onKeyDown={() => changePath('/invoices')}
              onClick={() => {
                if (currentUser?.IsAccount === 'enable') {
                  changePath('/invoices');
                  dispatch(setHeader(false));
                }
              }}
              className="point"
            >
              Invoices
            </div>
            <a href="/" onClick={() => onLogOut()}>
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
Header.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
  currentUser: PropTypes.oneOfType([PropTypes.object]),
};

Header.defaultProps = {
  user: {},
  currentUser: {},
};

function mapStateToProps(state) {
  return {
    currentUser:
      state.firebase && state.firebase.logginUser && state.firebase.logginUser.data
        ? state.firebase.logginUser.data
        : state.firebase.logginUser,
  };
}

export default connect(mapStateToProps)(Header);
