import moment from 'moment';
import { downloadInvoiceApi, sendInvoiceApi } from './api.service';
import { DB } from '../firebase/firebase';
import { SERVER_ERROR } from '../redux/constants/messages';
import { getDataFromSnapshot, getSortValueFromDate } from './util.service';

// actions
import {
  requestGetInvoices,
  receiveAllInvoices,
  getInvoicesError,
  // requestDownloadInvoices,
  // downloadInvoiceSuccessfully,
  // downloadInvoiceError,
} from '../redux/actions/invoices';

export const getInvoices = (dispatch, userId, startDate, endDate) => {
  dispatch(requestGetInvoices());
  if (startDate && endDate) {
    DB.collection('invoices')
      .where('StartTime', '>=', startDate)
      .where('StartTime', '<=', endDate)
      .where('UserId', '==', userId)
      .where('IsDeleted', '==', false)
      .orderBy('StartTime')
      .get()
      .then(snapshot => {
        const data = getDataFromSnapshot(snapshot);
        data.forEach(x => {
          // const startTime = x.StartTime;
          // x.StartTime = startTime.toDateString();
          x.SortField = getSortValueFromDate(x.StartTime);
        });
        dispatch(receiveAllInvoices(data));
      })
      .catch(() => {
        dispatch(getInvoicesError(SERVER_ERROR));
      });
  } else {
    DB.collection('invoices')
      .where('UserId', '==', userId)
      .where('IsDeleted', '==', false)
      .orderBy('StartTime')
      .get()
      .then(snapshot => {
        const data = getDataFromSnapshot(snapshot);
        data.forEach(x => {
          // const startTime = x.StartTime;
          // x.StartTime = startTime.toDateString();
          x.SortField = getSortValueFromDate(x.StartTime);
        });
        dispatch(receiveAllInvoices(data));
      })
      .catch(() => {
        dispatch(getInvoicesError(SERVER_ERROR));
      });
  }
};

export const downloadInvoices = async (dispatch, invoice, fileType) => {
  // dispatch((requestDownloadInvoices()));
  // const response =  downloadInvoiceApi(invoice, fileType).then(data => {
  //   if(data && data.data && data.data.isSuccessful){
  //     dispatch((downloadInvoiceSuccessfully(data)));
  //   }else{
  //     dispatch((downloadInvoiceError(data.data.message)));
  //   }
  // });
  const response = await downloadInvoiceApi(invoice, fileType);
  return response;
};

export const sendInvoice = async (invoice, emails) => {
  const response = await sendInvoiceApi(invoice, emails);
  return response;
};

export const filterInvoicesByMonth = async (invoices, month, year) => {
  let newInvoices = [];

  const startOfMonth = moment(`${month} - ${year}`, 'MMMM-YYYY').clone().startOf('month').startOf('day').valueOf();
  const endOfMonth = moment(`${month} - ${year}`, 'MMMM-YYYY').clone().endOf('month').endOf('day').valueOf();
  if (startOfMonth === endOfMonth) {
    newInvoices = await invoices.filter(x => x.SortField === startOfMonth);
  } else {
    newInvoices = await invoices.filter(x => x.SortField >= startOfMonth && x.SortField <= endOfMonth);
  }
  await newInvoices.forEach(async inv => {
    inv.displayDate = await moment(inv.StartTime).format('DD MMM, YYYY');
  });
  return newInvoices;
};
