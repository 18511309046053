import React from 'react';
import PropTypes from 'prop-types';
import visa from '../../../assets/visa.png';
import './index.scss';

function Cards(props) {
  const { name, expiry, cvv, last4, deleteFuntion } = props;

  return (
    <div className="card-container">
      <div className="card bg-body rounded-primary  p-6 ">
        <div className=" flex flex-col">
          <div className="flex justify-end w-full">
            <img src={visa} alt="logo" className=" w-12 h-4" />
          </div>
          <div className="flex text-base text-white mt-7 space-x-3">
            <span>****</span>
            <span>-</span>
            <span>****</span>
            <span>-</span> <span>****</span>
            <span>-</span> <span>{last4}</span>
          </div>
          <div className="flex owner-info text-white mt-7 space-x-6">
            <div className="space-y-1">
              <div>Owner</div>
              <div className=" text-base">{name}</div>
            </div>
            <div className="space-y-1">
              <div>Expiry</div>
              <div className="text-base">{expiry}</div>
            </div>
            <div className="space-y-1">
              <div>CVV</div>
              <div className="text-base">{cvv}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center space-x-24 mt-4">
        <button type="button">
          <i
            role="button"
            tabIndex="0"
            aria-label="click to delete"
            className="fas fa-trash text-red-500"
            onClick={() => deleteFuntion()}
            onKeyDown={() => {}}
          />
        </button>
      </div>
    </div>
  );
}

Cards.propTypes = {
  name: PropTypes.string.isRequired,
  deleteFuntion: PropTypes.func.isRequired,
  expiry: PropTypes.string.isRequired,
  cvv: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
};

export default Cards;
