/* eslint-disable react/prop-types */
import './dashboard.css';
import React, { useEffect, useState, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import Header from 'components/header/Header';
import { useNavigate } from 'react-router-dom';
import GridIcon from 'components/icons/grid-icon';
import ListIcon from 'components/icons/list-icon';
// import AddIcon from 'components/icons/add-icon';
import moment from 'moment';
import Calendar from 'react-calendar';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import RangeSelection from 'components/rangeSelection';
import MeetingCard from './meeting-card/meetingCard';
import allPeriod from '../../assets/all-period.svg';
import allPeriodBlack from '../../assets/all-period-black.svg';
import MeetingNotFound from '../../assets/plate3.png';

function Dashboard(props) {
  const { loading, user, participantsHistory, meetings, getFilterMeetings, clearFilterMeeting, currentUser } = props;
  const navigate = useNavigate();
  const [isListView, setIsListView] = useState(false);
  const [headingText, setHeadingText] = useState('Your Meetings');
  const [menuActive, setMenuActive] = useState(false);
  const [calendarActive, setCalendarActive] = useState(false);
  const [meetingDate, setMeetingDate] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const calendarRef = useRef();

  const handleClickOutside = event => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarActive(false);
    }
  };

  useEffect(() => {
    const endOffset = itemOffset + 4;
    setCurrentItems(meetings.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(meetings.length / 4));
  }, [itemOffset, 4, meetings]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef]);

  const handlePageClick = event => {
    const newOffset = (event.selected * 4) % meetings.length;
    setItemOffset(newOffset);
  };

  let meetingItems = [];

  const handaleMeetingNavigation = () => {
    navigate('/createNew');
  };

  const meetingFilter = date => {
    setMeetingDate(date);
    setCalendarActive(false);
    getFilterMeetings(date);
  };

  const calculateTotal = (meetingIndex, perUserPrice) => {
    const _meetings = meetings;
    if (_meetings[meetingIndex]) {
      _meetings[meetingIndex].VoucherPrice = parseInt(perUserPrice, 10);
    }
  };

  meetingItems = currentItems?.map((meeting, index) => (
    <React.Fragment key={index}>
      <MeetingCard
        {...props}
        meetingIndex={index}
        today={moment(meeting.StartTime).format('DD') === moment().format('DD')}
        menuActive={setMenuActive}
        headingText={setHeadingText}
        date={moment(meeting.StartTime).format('ll')}
        time={moment(meeting?.StartTime).format('hh:mm A')}
        title={meeting.TopicName}
        startTime={meeting.StartTime}
        description={meeting.Description}
        perUserPrice={meeting.VoucherPrice}
        generalPrice={meeting.Participants.length * meeting.VoucherPrice}
        isListView={isListView}
        participants={meeting.Participants}
        id={meeting.Id}
        participantsHistory={participantsHistory}
        calculateTotal={calculateTotal}
        className="m-2"
        isMeetingStart={meeting.IsMeetingStarted}
      />
      {/* <button
        type="button"
        onClick={() => {
          handaleMeetingNavigation();
        }}
        className="create-new-btn text-tertiary"
      >
        <AddIcon />
      </button> */}
    </React.Fragment>
  ));

  const handleCalendar = () => {
    if (calendarActive) {
      setCalendarActive(false);
    } else {
      setCalendarActive(true);
    }
  };

  const clearFilter = () => {
    setCalendarActive(false);
    setMeetingDate([]);
    clearFilterMeeting();
  };

  return (
    <div>
      <div className={`overlay w-full h-full fixed left-0 top-0 ${menuActive ? 'block' : 'hidden'}`} />
      <div
        className={` ${menuActive ? 'overflow-y-clip' : ''} 
        } flex flex-col w-full responsive-view-scroll`}
      >
        <div className="container-fluid w-full" style={{ marginTop: '0px', marginBottom: '0px' }}>
          <div className={menuActive ? 'z-10 relative' : ''}>
            <Header user={user} />
          </div>
          <div className="flex flex-col md:flex-row items-center space-y-6 my-12">
            <h1 className={`text-4xl lg:text-7xl font-bold text-body flex-1 ${menuActive ? 'z-[1] relative' : ''}`}>
              {headingText}
            </h1>
            {/* {menuActive}  */}
            <div className={`justify-between space-x-4 lg:space-x-12 ${menuActive ? 'hidden' : 'flex'}`}>
              {/* <div className="flex items-center">
                <div className="text-[10px] text-body font-bold mr-2">NEXT MEETING</div>
                <div className="text-white space-x-0.5 text-sm ">
                  <span className="bg-[#5E4A6E] rounded p-1">4</span>
                  <span className="bg-[#5E4A6E] rounded p-1">4</span>
                  <span className="text-black">:</span>
                  <span className="bg-[#5E4A6E] rounded p-1">2</span>
                  <span className="bg-[#5E4A6E] rounded p-1">5</span>
                </div>
              </div> */}
              <div className="flex items-center space-x-6">
                <div className="hidden lg:flex space-x-12">
                  <div className="flex space-x-3 items-center">
                    <button
                      type="button"
                      onClick={() => setIsListView(false)}
                      className={`${isListView ? 'text-gray-900' : 'text-primary'}`}
                    >
                      <GridIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => setIsListView(true)}
                      className={`${!isListView ? 'text-gray-900' : 'text-primary'}`}
                    >
                      <ListIcon />
                    </button>
                  </div>
                  <div className="relative" ref={calendarRef}>
                    <button
                      type="button"
                      className={`uppercase flex flex-nowrap items-center font-bold space-x-2 ${
                        meetingDate.length ? 'filter-block color-white' : 'color-black'
                      }`}
                      onClick={e => handleCalendar(e)}
                    >
                      <img alt="calendar" src={meetingDate.length ? allPeriod : allPeriodBlack} />
                      <RangeSelection date={meetingDate} clearFilter={clearFilter} />
                    </button>
                    {calendarActive && (
                      <Calendar
                        onChange={date => {
                          if (date && date.length === 2) {
                            setMeetingDate(date);
                            meetingFilter(date);
                          }
                        }}
                        value={meetingDate?.length > 0 ? meetingDate : [new Date(), new Date()]}
                        selectRange
                        allowPartialRange
                        className="dashboard-calendar"
                      />
                    )}
                  </div>
                </div>
                <button
                  disabled={currentUser && currentUser?.IsAccount !== 'enable'}
                  type="button"
                  onClick={() => {
                    handaleMeetingNavigation();
                  }}
                  className="btn button px-6"
                >
                  New
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <div role="button" tabIndex="0">
              <i className="far fa-2x fa-spin fa-spinner" />
            </div>
          </div>
        ) : (
          <div>
            {meetingItems && meetingItems.length > 0 ? (
              <>
                {meetings && meetings.length > 4 && (
                  <ReactPaginate
                    previousLabel={
                      <ArrowBackIosNewIcon
                        style={{
                          color: 'white',
                          backgroundColor: '#EF4B5A',
                          fontSize: 50,
                          borderRadius: '50%',
                          padding: '7px',
                        }}
                      />
                    }
                    nextLabel={
                      <ArrowForwardIosOutlinedIcon
                        style={{
                          color: 'white',
                          backgroundColor: '#EF4B5A',
                          fontSize: 50,
                          borderRadius: '50%',
                          padding: '7px',
                        }}
                      />
                    }
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                )}
                <div
                  className={`${
                    isListView
                      ? 'list-view container-fluid w-full overflow-auto'
                      : 'grid-view meetingsContainer min-h-full overflow-x-auto'
                  } ${menuActive ? 'menuActive overflow-x-hidden' : ''}`}
                >
                  <div>{meetingItems}</div>
                </div>
              </>
            ) : (
              <div
                className="grid-view meetingsContainer overflow-auto"
                style={{ height: '51vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <div style={{ display: 'grid', placeItems: 'center', marginLeft: 0 }}>
                  <p className="mb-2">Your plate&apos;s empty.</p>
                  <img src={MeetingNotFound} alt="logo" className="w-56" />
                  <p style={{ marginTop: '1rem', textAlign: 'center' }}>
                    Looks like you have no meetings scheduled. Click New to{' '}
                    <button
                      className="text-red-600 font-semibold"
                      type="button"
                      disabled={currentUser && currentUser?.IsAccount !== 'enable'}
                      onClick={() => {
                        handaleMeetingNavigation();
                      }}
                    >
                      create a meeting.
                    </button>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
