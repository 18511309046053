/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import moment from 'moment-timezone';

function PrintInvoiceContent(props) {
  const { invoiceArrayForPrint, user, groupByParticipantHistory } = props;
  return (
    <>
      {invoiceArrayForPrint?.map((invoice, index) => {
        return (
          <div key={index}>
            <table
              style={{ pageBreakBefore: 'always' }}
              className="es-wrapper"
              align="center"
              cellSpacing="0"
              cellPadding="0"
            >
              <tbody>
                <tr>
                  <td className="esd-email-paddings" valign="top">
                    <div
                      style={{
                        borderRadius: '20px 20px 0px 0px',
                        background: 'linear-gradient(46.56deg, #EF4B5A 52.66%, #FB7480 91.24%)',
                        height: '256px',
                      }}
                    >
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        className="es-content es-visible-simple-html-only"
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td className="esd-stripe es-stripe-html" align="center">
                              <table
                                className="es-content-body"
                                align="center"
                                cellPadding="0"
                                cellSpacing="0"
                                width="580"
                              >
                                <tbody>
                                  <tr className="es-visible-simple-html-only">
                                    <td className="esd-structure es-struct-html" align="left">
                                      <table cellPadding="0" cellSpacing="0" width="100%">
                                        <tbody>
                                          <tr>
                                            <td width="650" className="esd-container-frame" align="center" valign="top">
                                              <table cellPadding="0" cellSpacing="0" width="100%">
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <table>
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              align="center"
                                                              style={{
                                                                background: 'rgba(255, 255, 255, 0.11)',
                                                                fontSize: '12px',
                                                                borderRadius: '4px',
                                                                color: 'white',
                                                                width: '160px',
                                                                height: '30px',
                                                                fontweight: 'bold',
                                                              }}
                                                            >
                                                              <span>
                                                                {invoice.StartTime
                                                                  ? moment(invoice.StartTime).format(
                                                                      'dddd DD, MMM YYYY',
                                                                    )
                                                                  : ''}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                    <td
                                                      align="right"
                                                      style={{
                                                        width: '111px',
                                                        height: '80px',
                                                        top: '0px',
                                                        background: '#EF4B5A',
                                                        borderRadius: '0px 14.08px 14.08px 14.08px',
                                                        textAlign: 'center',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontWeight: '700',
                                                          fontSize: '14px',
                                                          lineHeight: '18px',
                                                          letterSpacing: '-0.0944059px',
                                                          color: '#FFFDFB',
                                                        }}
                                                      >
                                                        Invoice
                                                      </span>
                                                      <br />
                                                      <span
                                                        style={{
                                                          fontWeight: '700',
                                                          fontSize: '14px',
                                                          lineHeight: '18px',
                                                          letterSpacing: '-0.0944059px',
                                                          color: '#FFFDFB',
                                                        }}
                                                      >
                                                        #000{invoice.InvoiceId}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="esd-container-frame" align="center" valign="top">
                                              <table
                                                cellPadding="0"
                                                cellSpacing="0"
                                                width="100%"
                                                style={{ marginTop: '25px' }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td width="250" style={{ borderRight: '2px solid white' }}>
                                                      <img
                                                        style={{ padding: '20px' }}
                                                        src="https://firebasestorage.googleapis.com/v0/b/greeteat-web-qa.appspot.com/o/InvoiceLogo.png?alt=media&token=cee02486-8697-407d-bb33-3fdfc1d13733"
                                                        alt="Logo"
                                                        width="50%"
                                                      />
                                                    </td>
                                                    <td
                                                      style={{
                                                        color: 'white',
                                                        paddingLeft: '35px',
                                                        fontSize: '12px',
                                                      }}
                                                    >
                                                      <p style={{ fontWeight: 'bold' }}>Greet Eat LLC</p>
                                                      <p>401 N. 8th St. Worland, WY, 82401</p>
                                                      <img
                                                        src="https://firebasestorage.googleapis.com/v0/b/greeteat-web-qa.appspot.com/o/smallLogo.png?alt=media&token=558efa8b-523c-4f49-b9ea-01f91e9d910b"
                                                        alt=""
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        className="es-content es-visible-simple-html-only"
                        cellPadding="0"
                        cellSpacing="0"
                        align="center"
                        style={{
                          background: '#FFFFFF',
                          boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.03), 0px 10px 22px rgba(0, 0, 0, 0.03)',
                          borderRadius: '10px',
                          marginTop: '20px',
                          height: '150px',
                          width: '529px',
                        }}
                      >
                        <tbody>
                          <tr>
                            <td width="60%" style={{ padding: '25px' }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        lineHeight: '14px',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      <span style={{ fontWeight: '400' }}>Invoice for</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <img
                                                src="https://i.pinimg.com/originals/d5/b0/4c/d5b04cc3dcd8c17702549ebc5f1acf1a.png"
                                                alt=""
                                                width="25px"
                                                height="25px"
                                              />
                                            </td>
                                            <td>
                                              <span
                                                style={{ fontSize: '14px', paddingLeft: '5px', fontWeight: 'bold' }}
                                              >
                                                {user?.Name ? user.Name : user?.UserEmail}
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        lineHeight: '140.5%',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      <span>
                                        {user?.Name ? user.Name : invoice.UserEmail}
                                        <br />
                                        {user?.City ? user.City : ''},
                                        {user?.StateOrProvince ? user.StateOrProvince : ''}{' '}
                                        {user?.Country ? user.Country : ''}{' '}
                                        {user?.ZipOrPostalCode ? user.ZipOrPostalCode : ''}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td width="40%" style={{ padding: '25px' }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '14px',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      <span style={{ fontWeight: '400' }}>Total Amount</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <span
                                                style={{
                                                  fontWeight: '700',
                                                  fontSize: '18px',
                                                  lineHeight: '29px',
                                                  letterSpacing: '0.662552px',
                                                  color: '#3A2B46',
                                                }}
                                              >
                                                {`$ ${groupByParticipantHistory[index]?.totalPrice}`}
                                              </span>
                                            </td>
                                            <td>
                                              <span
                                                style={{
                                                  fontWeight: '400',
                                                  fontSize: '12px',
                                                  lineHeight: '14px',
                                                  letterSpacing: '0.662552px',
                                                  color: '#3A2B46',
                                                  opacity: '0.5',
                                                  margin: '0px 0px',
                                                }}
                                              >
                                                + Tax
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: '75px',
                                                height: '28px',
                                                left: '0px',
                                                top: '38px',
                                                background: '#33D267',
                                                borderRadius: '4px',
                                              }}
                                            >
                                              <table align="center">
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <img
                                                        src="https://firebasestorage.googleapis.com/v0/b/greeteat-web-qa.appspot.com/o/b5.png?alt=media&token=e89b0ee8-50cb-4f0b-9b2b-5af8d9d63e98"
                                                        alt=""
                                                      />
                                                      <span
                                                        style={{
                                                          fontWeight: '700',
                                                          fontSize: '10px',
                                                          lineHeight: '12px',
                                                          letterSpacing: '0.662552px',
                                                          color: '#FFFDFB',
                                                        }}
                                                      >
                                                        PAID
                                                      </span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        cellPadding="8"
                        cellSpacing="8"
                        className="es-content es-visible-simple-html-only"
                        align="left"
                        style={{ margin: '40px 40px 0px 40px', borderBottom: '1px solid lightgray' }}
                      >
                        <tbody style={{ borderBottom: '2px solid green' }}>
                          <tr>
                            <th
                              style={{
                                textAlign: 'left',
                                fontWeight: '400',
                                fontSize: '10px',
                                lineHeight: '11px',
                                letterSpacing: '0.662552px',
                                color: '#3A2B46',
                              }}
                              width="70px"
                            >
                              QTY
                            </th>
                            <th
                              style={{
                                textAlign: 'left',
                                fontWeight: '400',
                                fontSize: '10px',
                                lineHeight: '11px',
                                letterSpacing: '0.662552px',
                                color: '#3A2B46',
                              }}
                              width="230px"
                            >
                              Description
                            </th>
                            <th
                              style={{
                                textAlign: 'left',
                                fontWeight: '400',
                                fontSize: '10px',
                                lineHeight: '11px',
                                letterSpacing: '0.662552px',
                                color: '#3A2B46',
                              }}
                              width="100px"
                            >
                              Unit Price
                            </th>
                            <th
                              style={{
                                textAlign: 'left',
                                fontWeight: '400',
                                fontSize: '10px',
                                lineHeight: '11px',
                                letterSpacing: '0.662552px',
                                color: '#3A2B46',
                              }}
                              width="100px"
                            >
                              Total
                            </th>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                lineHeight: '12px',
                                letterSpacing: '0.662552px',
                                color: '#3A2B46',
                              }}
                            >
                              {groupByParticipantHistory[index]?.quantity}
                            </td>
                            <td
                              style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                lineHeight: '12px',
                                letterSpacing: '0.662552px',
                                color: '#3A2B46',
                              }}
                            >
                              {groupByParticipantHistory[index]?.description}
                            </td>
                            <td
                              style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                lineHeight: '12px',
                                letterSpacing: '0.662552px',
                                color: '#3A2B46',
                              }}
                            >
                              {`$ ${parseFloat(groupByParticipantHistory[index]?.voucherCost).toFixed(2)}`}
                            </td>
                            <td
                              style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                lineHeight: '12px',
                                letterSpacing: '0.662552px',
                                color: '#3A2B46',
                              }}
                            >
                              {`$ ${groupByParticipantHistory[index]?.totalPrice.toFixed(2)}`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        className="es-content es-visible-simple-html-only"
                        align="left"
                        style={{ margin: '40px 0px 0px 40px' }}
                      >
                        <tbody>
                          <tr>
                            <td width="60%">
                              <h3>Notes</h3>
                              <p style={{ fontSize: '10px', letterSpacing: '0.662552px', color: 'black' }}>
                                Hi {user.Name ? user.Name : user.UserEmail}, have a look at the invoice for the month of
                                February. Let me know if you have questions, thanks.
                              </p>
                            </td>
                            <td wdth="40%">
                              <table cellPadding="6" cellSpacing="6" style={{ borderBottom: '1px solid lightgray' }}>
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        fontWeight: '400',
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      Service Fee ({invoice.ServiceFee}%)
                                    </th>
                                    <td
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        lineHeight: '12px',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      {`$ 
                                          ${(
                                            (groupByParticipantHistory[index].totalPrice * invoice.ServiceFee) /
                                            100
                                          ).toFixed(2)}
                                        `}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        fontWeight: '400',
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      Subtotal
                                    </th>
                                    <td
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        lineHeight: '12px',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      {`$ ${groupByParticipantHistory[index]?.totalPrice.toFixed(2)}`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        fontWeight: '400',
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      Sales Tax
                                    </th>
                                    <td
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        lineHeight: '12px',
                                        letterSpacing: '0.662552px',
                                        color: '#3A2B46',
                                      }}
                                    >
                                      {`$ ${invoice.SalesTax.toFixed(2)}`}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <table align="left" cellPadding="6" cellSpacing="6">
                                        <tbody>
                                          <tr>
                                            <th>
                                              <img
                                                src="https://firebasestorage.googleapis.com/v0/b/greeteat-web-qa.appspot.com/o/rightGreen.png?alt=media&token=34e2be6b-ff47-4457-b7c2-91098aa5cb98"
                                                alt=""
                                              />
                                              <span
                                                style={{
                                                  fontWeight: '400',
                                                  fontSize: '12px',
                                                  lineHeight: '14px',
                                                  letterSpacing: '0.662552px',
                                                  color: '#3A2B46',
                                                }}
                                              >
                                                Amount Paid
                                              </span>
                                              <td
                                                style={{
                                                  fontWeight: '700',
                                                  fontSize: '14px',
                                                  lineHeight: '17px',
                                                  letterSpacing: '0.662552px',
                                                  color: '#3A2B46',
                                                }}
                                              >
                                                {`$ ${
                                                  (groupByParticipantHistory[index].totalPrice * invoice.ServiceFee) /
                                                    100 +
                                                  groupByParticipantHistory[index].totalPrice
                                                }`}
                                              </td>
                                            </th>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table width="86%" style={{ marginLeft: '40px' }}>
                        <tbody align="center">
                          <tr>
                            <td>
                              <div
                                style={{ background: 'rgba(239, 75, 90, 0.1)', borderRadius: '4px', padding: '15px' }}
                              >
                                <span
                                  style={{
                                    fontWeight: '700',
                                    fontSize: '10px',
                                    lineHeight: '12px',
                                    letterSpacing: '0.662552px',
                                    color: '#EF4B5A',
                                  }}
                                >
                                  Thank you for your business! looking forward to your next visit 😄
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
}
export default PrintInvoiceContent;
