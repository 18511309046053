import React from 'react';
import PropTypes from 'prop-types';

function Loader(props) {
  const { visible } = props;

  return (
    visible && (
      <div role="button" tabIndex="0" className="flex items-center justify-center h-screen w-screen overlay fixed z-10">
        <i className="far fa-2x fa-spin fa-spinner" />
      </div>
    )
  );
}

Loader.propTypes = {
  visible: PropTypes.bool,
};

Loader.defaultProps = {
  visible: false,
};

export default Loader;
