import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function InvalidModal(props) {
  const { title, cancelButton, close, message } = props;
  useEffect(() => {
    function handleKeypress(e) {
      if (e.key === 'Escape') {
        close();
      }
    }

    document.addEventListener('keydown', handleKeypress);

    return () => {
      document.removeEventListener('keydown', handleKeypress);
    };
  }, []);

  return (
    <div className="fixed h-screen w-full popup flex justify-center top-0 left-0 items-center z-[999]">
      <div
        role="button"
        tabIndex="0"
        aria-label="Close Modal"
        className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
        onKeyDown={() => {}}
        onClick={close}
      />
      <div className="relative popup-container">
        <h2 className="font-bold text-body mb-7">{title}</h2>
        <div className="p-2">
          <p>{message}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
          <button type="button" className="btn btn-secondary w-60" onClick={close}>
            {cancelButton}
          </button>
        </div>
      </div>
    </div>
  );
}

InvalidModal.propTypes = {
  title: PropTypes.string.isRequired,
  cancelButton: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
export default InvalidModal;
