import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function Menu(props) {
  const [open, setOpen] = useState(false);
  const menuBtnRef = useRef(null);
  const menuRef = useRef(null);
  const { children } = props;

  useEffect(() => {
    function handleKeypress(e) {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    }

    const handleClickOutside = e => {
      if (menuRef.current && !menuRef.current.contains(e.target) && !menuBtnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeypress);
    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handleKeypress);
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div role="button" tabIndex="0" ref={menuBtnRef} onClick={() => setOpen(true)} onKeyDown={() => {}}>
        {children[0]}
      </div>
      {open && (
        <div ref={menuRef} className="absolute dropdown">
          {children[1]}
        </div>
      )}
    </>
  );
}
Menu.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Menu;
