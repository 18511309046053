import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/loader';

// components
import ForgotPassword from 'components/forgotPassword';

// services
import { sendPasswordResetEmailToUser } from '../../services/firebase.service';

function ForgotPasswordScreen(props) {
  const navigate = useNavigate();
  const { dispatch, error, loading } = props;

  const sendPasswordReset = (e, email) => {
    e.preventDefault();
    sendPasswordResetEmailToUser(dispatch, email, navigate);
  };

  // loading
  if (loading) return <Loader visible />;

  return (
    <ForgotPassword
      sendPasswordReset={(e, email) => sendPasswordReset(e, email)}
      error={error}
      loading={loading}
      dispatch={dispatch}
    />
  );
}

ForgotPasswordScreen.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.string,
};

ForgotPasswordScreen.defaultProps = {
  error: '',
  dispatch: () => {},
  loading: false,
};

const mapStateToProps = state => {
  return {
    loading: state.forgotPassword.loading,
    error: state.forgotPassword.message,
  };
};

export default connect(mapStateToProps)(ForgotPasswordScreen);
