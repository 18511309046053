/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from './ButtonGroup';

function GenericModal(props) {
  const {
    title,
    children,
    close,
    button,
    submit,
    downloadAllInvoice,
    isAll,
    printAllReceipt,
    selectedParticipants,
    invoicesArray,
  } = props;
  const [fileType, setFileTypeState] = useState('pdf');
  useEffect(() => {
    function handleKeypress(e) {
      if (e.key === 'Escape') {
        close();
      }
    }

    document.addEventListener('keydown', handleKeypress);

    return () => {
      document.removeEventListener('keydown', handleKeypress);
    };
  }, []);

  const printButtonLabel = event => {
    setFileTypeState(event.target.name);
  };

  return (
    <div className="fixed h-screen w-full popup flex justify-center top-0 left-0 items-center z-[999]">
      <div
        role="button"
        tabIndex="0"
        aria-label="Close Modal"
        className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
        onKeyDown={() => {}}
        onClick={close}
      />
      <div className="relative popup-container">
        <h2 className="font-bold text-body mb-7">{title}</h2>
        <div>{children}</div>
        <div style={{ marginTop: '15px' }}>
          <ButtonGroup buttons={['pdf', 'excel']} doSomethingAfterClick={printButtonLabel} />
        </div>
        <div className="container" style={{ padding: 0 }}>
          <div
            className={`${
              !isAll ? 'block md:flex justify-between	' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'
            } `}
          >
            <div>
              <button
                type="button"
                className={`btn btn-secondary ${!isAll ? 'md:w-60 w-full mb-1	' : 'w-full'}`}
                onClick={close}
              >
                Cancel
              </button>
            </div>
            {isAll && (
              <>
                <div>
                  <button
                    type="button"
                    className="btn btn-secondary w-full genericModalButton"
                    onClick={() => downloadAllInvoice(fileType)}
                    disabled={invoicesArray === 1 || invoicesArray < 1}
                  >
                    Download All Invoice
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-secondary w-full genericModalButton"
                    onClick={() => printAllReceipt()}
                  >
                    Print Invoice
                  </button>
                </div>
              </>
            )}
            <div>
              <button
                type="button"
                className={`${
                  selectedParticipants.length === 0 ? 'btn btn-secondary genericModalButton' : 'btn button'
                } ${!isAll ? 'md:w-60 w-full' : 'w-full'}`}
                disabled={selectedParticipants.length === 0}
                onClick={() => submit(fileType)}
              >
                {button}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

GenericModal.propTypes = {
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  button: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  downloadAllInvoice: PropTypes.func.isRequired,
  isAll: PropTypes.bool.isRequired,
  printAllReceipt: PropTypes.func.isRequired,
  selectedParticipants: PropTypes.oneOfType([PropTypes.array]).isRequired,
  invoicesArray: PropTypes.number.isRequired,
};
export default GenericModal;
