import {
  addStripUserApi,
  getPaymentCardApi,
  addPaymentCardApi,
  deletePaymentCardApi,
  getSetupIntentApi,
} from './api.service';
import { SERVER_ERROR, USER_CREATE_FAILED } from '../redux/constants/messages';
import {
  addStripUserRequest,
  addStripUserSuccesfully,
  addStripUserFailure,
  addPaymentCardRequest,
  addPaymentCardSuccess,
  addPaymentCardFailure,
  getPaymentCardRequest,
  getPaymentCardSuccess,
  getPaymentCardFailure,
  deletePaymentCardRequest,
  deletePaymentCardSuccess,
  deletePaymentCardFailure,
  setUpIntentRequest,
  setUpIntentSuccess,
  setUpIntentFailure,
} from '../redux/actions/strip';

export const addStripUser = (dispatch, data) => {
  dispatch(addStripUserRequest());
  addStripUserApi(data).then(
    result => {
      dispatch(addStripUserSuccesfully(result));
    },
    () => {
      dispatch(addStripUserFailure(USER_CREATE_FAILED));
    },
  );
};

export const addPaymentCard = dispatch => {
  dispatch(addPaymentCardRequest());
  addPaymentCardApi().then(
    result => {
      dispatch(addPaymentCardSuccess(result));
    },
    () => {
      dispatch(addPaymentCardFailure(SERVER_ERROR));
    },
  );
};

export const getPaymentCard = dispatch => {
  dispatch(getPaymentCardRequest());
  getPaymentCardApi().then(
    result => {
      dispatch(getPaymentCardSuccess(result));
    },
    () => {
      dispatch(getPaymentCardFailure(SERVER_ERROR));
    },
  );
};

export const deletePaymentCard = (dispatch, id) => {
  dispatch(deletePaymentCardRequest());
  deletePaymentCardApi(id).then(
    result => {
      dispatch(deletePaymentCardSuccess(result));
      getPaymentCard(dispatch);
    },
    () => {
      dispatch(deletePaymentCardFailure(SERVER_ERROR));
    },
  );
};

export const setUpIntent = dispatch => {
  dispatch(setUpIntentRequest());
  getSetupIntentApi().then(
    result => {
      dispatch(setUpIntentSuccess(result));
    },
    () => {
      dispatch(setUpIntentFailure(SERVER_ERROR));
    },
  );
};
