import React, { useState } from 'react';
import Loader from 'components/loader';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// import logo from '../../assets/logo.svg';
import logo from '../../assets/full-logo.png';
import google from '../../assets/google.svg';

function Login(props) {
  const { loading, onLogin, onGoogleLogin, error } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  return (
    <div className="login-container lg:bg-cover min-h-screen md:bg-inherit">
      {loading && <Loader visible />}
      <div className="container-fluid text-center py-16">
        <img src={logo} className="h-25 w-36 m-auto" alt="Logo" />
        <h1 className="mt-10 text-7xl font-bold text-body">Welcome</h1>
        <form
          action=""
          onSubmit={e => onLogin(e, email, password)}
          className="p-5 md:p-0 mt-14 max-w-xl w-full m-auto space-y-5"
        >
          <div className="flex">
            <input
              type="text"
              required
              name="email"
              id="email"
              autoComplete="off"
              className="form-input order-1"
              placeholder="Email:"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              required
              name="password"
              id="password"
              autoComplete="off"
              className="form-input order-1"
              placeholder="Password:"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          {error && <div className="bg-red-500 rounded-sm p-2 font-bold text-white">{error}</div>}
          <div className="flex flex-col items-center">
            <button type="submit" className="button btn btn-md mt-10">
              SIGN IN
            </button>
            <div className="flex justify-center items-center">
              <hr
                style={{
                  color: 'gray',
                  backgroundColor: 'gray',
                  height: 3,
                  width: 100,
                }}
              />
              <span className="m-2">or</span>
              <hr
                style={{
                  color: 'gray',
                  backgroundColor: 'gray',
                  height: 3,
                  width: 100,
                }}
              />
            </div>
            <div className="flex justify-center items-center m-0">
              <button
                type="button"
                onClick={e => {
                  onGoogleLogin(e);
                }}
                style={{ height: '50px', padding: '20px', boxShadow: '0px 4px 9px rgb(0 0 0 / 10%)' }}
                className="flex justify-center items-center	relative bg-white border border-opacity-30 border-[#ffe1c2]"
              >
                <img src={google} alt="google" width="18" height="18" />
                <span className="f-14 ml-4 text-[#757575] font-face-rb">Sign in with Google</span>
              </button>
            </div>
            <button type="button" className="button btn btn-sm mt-5" onClick={() => navigate('/register')}>
              Sign up
            </button>
            <button type="button" className="button btn btn-sm mt-5" onClick={() => navigate('/forgot-password')}>
              Forgot Password
            </button>
            {/* <div className="flex">
              <div
                className="flex bg-[#ffffff]"
                style={{ height: '48px', padding: '8px', border: '1px solid #4285F4' }}
              >
                <img src={google} alt="google" className="m-auto" />
              </div>
              <button
                onClick={e => {
                  onGoogleLogin(e);
                }}
                style={{ padding: '6px' }}
                type="button"
                className="text-[#ffffff] bg-[#4285F4] focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium text-sm text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
              >
                <sapn className="text-sm m-2">Sign in with Google</sapn>
              </button>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}

Login.propTypes = {
  onGoogleLogin: PropTypes.func.isRequired,
  error: PropTypes.bool || PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
};

Login.defaultProps = {
  error: null,
};
export default Login;
