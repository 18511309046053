import React from 'react';
import PropTypes from 'prop-types';

function AcceptInvitation(props) {
  const { message, error } = props;

  return (
    <div>
      <b>{message || error}</b>
    </div>
  );
}

AcceptInvitation.propTypes = {
  message: PropTypes.string,
  error: PropTypes.string,
};

AcceptInvitation.defaultProps = {
  message: '',
  error: '',
};

export default AcceptInvitation;
