import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-inner.svg';

function Page404() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col h-screen w-full">
      <div className="static flex items-center justify-between mt-8 md:mt-0 p-6">
        <img src={logo} alt="logo" className="w-32" />
      </div>
      <div className="h-full flex flex-col items-center justify-center text-primary font-bold text-[200px]">
        404
        <div>
          <button type="button" className="button btn btn-lg mt-10" onClick={() => navigate(`/`)}>
            GO BACK
          </button>
        </div>
      </div>
    </div>
  );
}

export default Page404;
