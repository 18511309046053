import { GET_MEETINGS_REQUEST, GET_MEETINGS_SUCCESS, GET_MEETINGS_FAILURE } from '../../constants/actionTypes';

export default (
  state = {
    meetings: [],
    loading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case GET_MEETINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        meetings: [],
      };
    case GET_MEETINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        meetings: action.meetings,
      };
    case GET_MEETINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
        meetings: [],
      };
    default:
      return state;
  }
};
