import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS_RESPONSE,
  RESET_PASSWORD_FAILURE,
  CLEAR_ERROR_MESSAGE,
} from '../constants/actionTypes';

export default (
  state = {
    loading: false,
    error: false,
    resetPasswordError: {},
  },
  action,
) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.message,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        resetPasswordError: action.message,
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error: null,
        message: '',
        loading: false,
      };
    default:
      return state;
  }
};
