/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Popover, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Toast from 'components/toast';
import EditIcon from '@mui/icons-material/Edit';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import ParticipantPopUp from '../participant-popup/ParticipantPopUp';
import Participants from '../participants/index';
import CalendarModal from '../changeDate/index';
import './meetingCard.scss';
import { deleteMeeting, resetMeetingErrors } from '../../../services/meetings.service';
import ParticipantHistoryModal from './ParticipantsHistoryModal';
import { participantIdForMeeting } from '../../../services/util.service';
import DeleteMeetingModal from './DeleteMeetingModal';
import EditMeetingModal from './EditMeetingModal';

const _ = require('underscore');

function MeetingCard(props) {
  const {
    headingText,
    menuActive,
    meetingIndex,
    participants,
    dispatch,
    id,
    user,
    isListView,
    today,
    date,
    time,
    title,
    startTime,
    generalPrice,
    description,
    participantsHistory,
    calculateTotal,
    deleteMeetingError,
    isMeetingStart,
  } = props;
  const navigate = useNavigate();
  const [participantModal, setParticipantModal] = useState(false);
  const [groupModal, setGroupModal] = useState(false);
  const [perUserPrice, setPerUserPrice] = useState(0);
  const [calendarModal, setCalendarModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [deleteMeetingModal, setDeleteMeetingModal] = useState(false);
  const [meetingNotEditModal, setMeetingNotEditModal] = useState(false);
  const header = useSelector(state => state.header);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDis, setAnchorDis] = useState(null);

  useEffect(() => {
    const { perUserPrice: pPerUserPrice } = props;

    setPerUserPrice(parseInt(pPerUserPrice, 10));
    if (deleteMeetingError) {
      setTimeout(() => {
        resetMeetingErrors(dispatch);
      }, 5000);
    }
  }, []);

  const participantList = _.pluck(participants, 'Email');
  const isVoucherUsed = participants?.filter(_participant => _participant?.VoucherCodeStatus === 'redeemed');
  const timeDifference = moment(startTime).diff(moment(), 'minutes');

  const openParticipantModal = isOpen => {
    setParticipantModal(isOpen);
    menuActive(isOpen);

    if (isOpen) {
      headingText('Adjust Voucher Price');
    } else {
      headingText('Your Meeting');
    }
  };

  useEffect(() => {
    if (header.isOpen) {
      setParticipantModal(false);
      menuActive(false);
    }
  });

  const openGroupModal = isOpen => {
    setGroupModal(isOpen);
    menuActive(isOpen);

    if (isOpen) {
      headingText(`Total for ${participantList.length} participants`);
    } else {
      headingText('Your Meeting');
    }
  };

  const openCalendarModal = isOpen => {
    setCalendarModal(isOpen);
    menuActive(isOpen);

    if (isOpen) {
      headingText('Change Date and Time');
    } else {
      headingText('Your Meeting');
    }
  };

  const handleDelete = () => {
    setDeleteMeetingModal(true);
  };

  const conferenceLobby = () => {
    const participantId = participantIdForMeeting(participants, user?._delegate?.email);
    navigate(`/vc/meeting/${id}/participant/${participantId}?topic=${title}`);
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpenDis = event => {
    setAnchorDis(event.currentTarget);
  };

  const handlePopoverCloseDis = () => {
    setAnchorDis(null);
  };

  const deleteMeetingCallback = e => {
    e.preventDefault();
    deleteMeeting(dispatch, id, user.uid);
  };

  const handleEdit = () => {
    if (isMeetingStart || moment().isAfter(moment(startTime))) {
      setMeetingNotEditModal(true);
    } else {
      navigate(`/edit/${id}`);
    }
  };

  const open = Boolean(anchorEl);
  const openDis = Boolean(anchorDis);

  if (isListView) {
    return (
      <>
        <table className={`grid-meeting ${today ? 'grid-meeting--today' : ''} dynamic-width`}>
          <thead>
            <tr className="text-[10px] ">
              <th className="grid-meeting__header">
                <div>
                  {today ? <span className="font-bold"> Today</span> : ''} {date}
                </div>
              </th>
              <th className="bg-[#FFE1C2] font-normal text-left pl-5 rounded-tl-[10px] w-3/5">Topic</th>
              <th className="bg-[#FFE1C2] font-normal">Per User</th>
              <th className="bg-[#FFE1C2] font-normal px-6">Total</th>
              <th className="bg-[#FFE1C2] font-normal text-left">Participants</th>
              <th className="bg-[#FFE1C2] font-normal rounded-tr-[20px] text-left px-5">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="grid-meeting__time">
                {time}
                <div className="text-primary font-bold text-[10px]">{isMeetingStart && 'Meeting ended'}</div>
              </td>
              <td className="text-body text-[21px] pl-5">
                {title}
                <br />
                <button
                  type="button"
                  className="font-medium text-primary text-[18px]"
                  onClick={() => setIsActive(true)}
                >
                  {' '}
                  View History{' '}
                </button>
              </td>
              <td className="text-body text-center text-base">${perUserPrice}</td>
              <td className="text-body text-center text-base">${generalPrice}</td>
              <td className="relative">
                <div className="flex items-center">
                  <Participants list={participantList} isListView={isListView} />
                </div>
              </td>
              <td className="relative px-5">
                <div className="flex w-full h-full items-center rounded-tr-[20px] left-0 top-0">
                  <Tooltip title="Edit Meeting" arrow>
                    <button type="button" className="custom-icon btn-sm" onClick={() => handleEdit()}>
                      <EditIcon color="red" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Enter Lobby" arrow>
                    <button
                      type="button"
                      className="custom-icon btn-sm"
                      disabled={isMeetingStart || moment().isAfter(moment(startTime))}
                      onClick={() => conferenceLobby(id, user, participants)}
                    >
                      <MeetingRoomIcon color="red" />
                    </button>
                  </Tooltip>
                  {!isMeetingStart && !moment().isAfter(moment(startTime)) ? (
                    <Tooltip title="Delete Meeting" arrow>
                      <button
                        type="button"
                        disabled={isMeetingStart}
                        className="custom-icon btn-sm"
                        onClick={() => handleDelete(meetingIndex)}
                      >
                        <DeleteIcon color="red" />
                      </button>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          {isActive && (
            <ParticipantHistoryModal
              participantsHistory={participantsHistory}
              title="Participants History"
              close={() => setIsActive(false)}
              meetingId={id}
            />
          )}
        </div>

        <div>
          {deleteMeetingModal && (
            <DeleteMeetingModal
              title="Delete Meeting"
              text={
                isVoucherUsed.length
                  ? 'An invitee has already deposited the voucher. Meeting cannot be deleted.'
                  : timeDifference > 15
                  ? 'Are you sure you would like to delete the meeting?'
                  : moment().isAfter(moment(startTime))
                  ? 'This meeting has already started, so it cannot be deleted.'
                  : 'Meeting cannot be deleted less then 15min from meeting time.'
              }
              close={() => setDeleteMeetingModal(false)}
              data={{
                timeDifference: timeDifference,
                isVoucherUsed: isVoucherUsed,
                isButton: timeDifference > 15 && !isVoucherUsed.length,
              }}
              submit={e => {
                deleteMeetingCallback(e);
                setDeleteMeetingModal(false);
              }}
            />
          )}
        </div>

        <div>
          {meetingNotEditModal && isListView && (
            <EditMeetingModal
              title="Edit Meeting"
              text="This meeting has already started, so it can't be edit."
              close={() => setMeetingNotEditModal(false)}
            />
          )}
        </div>
      </>
    );
  }
  return (
    <>
      {deleteMeetingError && <Toast visible message={deleteMeetingError} type="error" />}
      <div
        className={`meeting-grid-item ${
          participantModal || groupModal || calendarModal ? 'meeting-grid-item--active' : ''
        }`}
      >
        {/* <ParticipantPopUp onModal={modal} date={props.date} time={props.time} title={props.title} description={props.description} pricePerUser={props.pricePerUser} generalPrice={props.generalPrice}/> */}

        {/* <CreateNew onModal={modal3}/> */}
        {calendarModal ? (
          <div className="relative">
            <CalendarModal openCalendarModal={openCalendarModal} />
          </div>
        ) : (
          <div className="meeting-card">
            <div className="flex justify-between">
              <div
                // onClick={() => openCalendarModal(true)}
                className="text-[#52DEB9] text-[10px] flex"
              >
                {today ? 'TODAY ' : ''}
                <div>
                  <span className="text-body text-opacity-70 ml-2">{date}</span>
                </div>
              </div>
              <div className="text-primary font-bold text-[10px]">{time}</div>
            </div>
            <div className="text-primary font-bold text-[10px]">{isMeetingStart && 'Meeting ended'}</div>
            <div className="flex-col flex mt-7 text-body ">
              <p
                className="text-2xl tracking-[0.79px] line-clamp-2"
                aria-owns={open ? `mouse-over-popover-${id}` : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                {title}
              </p>
              <div>
                <Popover
                  id={`mouse-over-popover-${id}`}
                  sx={{
                    pointerEvents: 'none',
                    Width: '100%',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1, fontSize: '1rem' }}>{title}</Typography>
                </Popover>
              </div>
              <div className="mt-2 text-base text-opacity-70 tracking-[1px] line-clamp-2">
                <p
                  className=" text-1xl tracking-[0.79px] line-clamp-2"
                  aria-owns={open ? `mouse-over-popover-dis-${id}` : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpenDis}
                  onMouseLeave={handlePopoverCloseDis}
                >
                  {description}
                </p>
                <div>
                  <Popover
                    id={`mouse-over-popover-dis-${id}`}
                    sx={{
                      pointerEvents: 'none',
                      maxWidth: '40rem',
                    }}
                    open={openDis}
                    anchorEl={anchorDis}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverCloseDis}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1, fontSize: '1rem' }}>{description}</Typography>
                  </Popover>
                </div>
              </div>
            </div>
            <div className="participants mt-5">
              <Participants list={participantList} isListView={isListView} />
            </div>
            <div className="flex justify-between mt-6 relative">
              <div>
                <button
                  type="button"
                  onClick={() => openParticipantModal(false)}
                  className="bg-[#FFE1C2] bg-opacity-40 px-7 py-4 rounded-primary rounded-tl-none text-body cursor-default"
                >
                  <div className="font-bold text-2xl">${perUserPrice}</div>
                  <div className="text-[10px]">Per user</div>
                </button>
                <button
                  type="button"
                  className={` z-10 bg-[#FFE1C2] rounded-primary rounded-tl-none absolute px-7 py-4 top-0 -left-24  ${
                    participantModal ? 'block' : ' hidden'
                  }`}
                  onClick={() => openParticipantModal(false)}
                >
                  <div className="flex">
                    <div
                      className="bg-[#3A2B46] justify-center relative top-5 -left-12 tracking-[3px] h-7 w-28 rounded-[15px] rounded-tr-none text-white text-sm font-bold flex items-center"
                      role="presentation"
                      onClick={() => calculateTotal(meetingIndex, perUserPrice)}
                      onKeyDown={() => calculateTotal(meetingIndex, perUserPrice)}
                    >
                      Save
                    </div>
                    <div>
                      <div className="font-bold text-2xl">${perUserPrice}</div>
                      <div className="text-[10px]">Per user</div>
                    </div>
                  </div>
                </button>
              </div>
              <button
                // onClick={() => openGroupModal(true)}
                type="button"
                className="bg-[#FFE1C2] bg-opacity-40 px-7 py-4 rounded-primary rounded-br-none text-body cursor-default"
              >
                <div className="font-bold text-2xl">${generalPrice}</div>
                <div className="text-[10px]">Total</div>
              </button>
              <button
                type="button"
                className={` z-10 bg-[#FFE1C2] rounded-primary rounded-tl-none absolute px-7 py-4 top-0 -right-24  ${
                  groupModal ? 'block' : ' hidden'
                }`}
                onClick={() => openGroupModal(false)}
              >
                <div className="flex">
                  <div>
                    <div className="font-bold text-2xl">${generalPrice}</div>
                    <div className="text-[10px]">Total</div>
                  </div>
                  <div className="bg-[#3A2B46] justify-center relative top-5 -right-12 tracking-[3px] h-7 w-28 rounded-[15px] rounded-tr-none text-white text-sm font-bold flex items-center">
                    Change
                  </div>
                </div>
              </button>
            </div>
            {participantModal && (
              <ParticipantPopUp
                date={date}
                time={time}
                title={title}
                perUserPrice={perUserPrice}
                setPrice={setPerUserPrice}
                calculatePrice={calculateTotal}
              />
            )}

            <div className="flex flex-col mt-6">
              <button
                type="button"
                className="btn button"
                disabled={isMeetingStart || moment().isAfter(moment(startTime))}
                onClick={() => conferenceLobby(id, user, participants)}
              >
                Enter Lobby
              </button>
              <button type="button" className="font-medium mt-4 hover:text-primary" onClick={() => setIsActive(true)}>
                View History
              </button>
              <button type="button" className="font-medium mt-4 hover:text-primary" onClick={() => handleEdit()}>
                Edit Meeting
              </button>
              {!isMeetingStart && !moment().isAfter(moment(startTime)) ? (
                <button
                  type="button"
                  disabled={isMeetingStart}
                  className="font-medium mt-4 hover:text-primary"
                  onClick={() => handleDelete(meetingIndex)}
                >
                  Delete Meeting
                </button>
              ) : (
                ''
              )}
            </div>
            {/* <div><button className='w-full bg-[#3A2B46] bg-opacity-40 uppercase rounded-primary text-base font-bold text-white tracking-[3.36px] h-16 rounded-tl-none mt-6'>Enter Lobby</button></div> */}

            <div className="flex justify-center space-x-2 mt-6">
              <span className=" w-2 h-2 rounded-full bg-[#FFF0E1]" />
              <span className=" w-2 h-2 rounded-full bg-[#FFF0E1]" />
              <span className=" w-2 h-2 rounded-full bg-[#FFF0E1]" />
            </div>
          </div>
        )}
      </div>
      <div>
        {isActive && (
          <ParticipantHistoryModal
            participantsHistory={participantsHistory}
            title="Participants History"
            close={() => setIsActive(false)}
            meetingId={id}
          />
        )}
      </div>

      <div>
        {deleteMeetingModal && (
          <DeleteMeetingModal
            title="Delete Meeting"
            text={
              isVoucherUsed.length
                ? 'An invitee has already deposited the voucher. Meeting cannot be deleted.'
                : timeDifference > 15
                ? 'Are you sure you would like to delete the meeting?'
                : moment().isAfter(moment(startTime))
                ? 'This meeting has already started, so it cannot be deleted.'
                : 'Meeting cannot be deleted less then 15min from meeting time.'
            }
            close={() => setDeleteMeetingModal(false)}
            data={{
              timeDifference: timeDifference,
              isVoucherUsed: isVoucherUsed,
              isButton: timeDifference > 15 && !isVoucherUsed.length,
            }}
            submit={e => {
              deleteMeetingCallback(e);
              setDeleteMeetingModal(false);
            }}
          />
        )}
      </div>

      <div>
        {meetingNotEditModal && (
          <EditMeetingModal
            title="Edit Meeting"
            text="This meeting has already started, so it can't be edit."
            close={() => setMeetingNotEditModal(false)}
          />
        )}
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return {
    isLoggingIn: state.firebase.isLoggingIn,
    loginError: state.firebase.loginError,
    isAuthenticated: state.firebase.isAuthenticated,
    user: state.firebase.user,
    meeting: state.meetings,
    deleteMeetingError: state.deleteMeeting.error,
  };
}
MeetingCard.propTypes = {
  headingText: PropTypes.func.isRequired,
  menuActive: PropTypes.func.isRequired,
  meetingIndex: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isListView: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  generalPrice: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  perUserPrice: PropTypes.number.isRequired,
  calculateTotal: PropTypes.func.isRequired,
  deleteMeetingError: PropTypes.string,
  isMeetingStart: PropTypes.bool.isRequired,
};

MeetingCard.defaultProps = {
  deleteMeetingError: null,
};
export default connect(mapStateToProps)(MeetingCard);
// export default MeetingCard;
