import moment from 'moment';
import { DB } from '../firebase/firebase';
import { getDataFromSnapshot } from './util.service';
import {
  NO_MEETINGS_FOUND,
  MEETING_CREATE_FAILED,
  MEETING_EDIT_FAILED,
  MEETING_DELETE_FAILED,
  SERVER_ERROR,
  VIDEOCONFERENCEDATA_FAILURE,
} from '../redux/constants/messages';
import {
  createMeetingApi,
  editMeetingApi,
  deleteMeetingApi,
  sendGuestsInvitation,
  acceptMeetingInvitation,
  startMeeting,
  generateVideoConferenceData,
} from './api.service';

import {
  requestGetMeetings,
  receiveAllMeetings,
  getMeetingsError,
  requestGetSingleMeeting,
  receiveSingleMeeting,
  getSingleMeetingError,
  requestCreateMeetings,
  createMeetingSuccessResponse,
  createMeetingError,
  requestEditMeetings,
  editMeetingSuccessResponse,
  editMeetingError,
  requestDeleteMeetings,
  deleteMeetingSuccessResponse,
  deleteMeetingError,
  resetMeetingError,
  requestGetParticipants,
  receiveAllParticipants,
  getParticipantsError,
  requestSendGuestsInvitations,
  sendGuestsInvitationSuccessResponse,
  sendGuestsInvitationsError,
  requestAcceptMeetingInvitation,
  acceptMeetingInvitationSuccessResponse,
  acceptMeetingInvitationError,
  requestStartMeeting,
  startMeetingSuccessResponse,
  startMeetingFailure,
  requestGenerateVideoconferenceData,
  generateVideoconferenceDataFailure,
  generateVideoconferenceDataSuccessResponse,
} from '../redux/actions/meetings';

export const getMeetings = (dispatch, userId, startDate, endDate) => {
  dispatch(requestGetMeetings());
  if (startDate && endDate) {
    DB.collection('meetings')
      .where('StartTime', '>=', startDate)
      .where('StartTime', '<=', endDate)
      .where('UserId', '==', userId)
      .where('IsDeleted', '==', false)
      .orderBy('StartTime')
      .get()
      .then(snapshot => {
        const data = getDataFromSnapshot(snapshot);
        dispatch(receiveAllMeetings(data));
      })
      .catch(() => {
        dispatch(getMeetingsError(NO_MEETINGS_FOUND));
      });
  } else {
    DB.collection('meetings')
      .where('StartTime', '>=', moment().startOf('day').toDate())
      .where('UserId', '==', userId)
      .where('IsDeleted', '==', false)
      .orderBy('StartTime')
      .get()
      .then(snapshot => {
        const data = getDataFromSnapshot(snapshot);
        dispatch(receiveAllMeetings(data));
      })
      .catch(() => {
        dispatch(getMeetingsError(NO_MEETINGS_FOUND));
      });
  }
};

export const getMeeting = (dispatch, meetingId) => {
  dispatch(requestGetSingleMeeting());

  DB.collection('meetings')
    .where('Id', '==', meetingId)
    .where('IsDeleted', '==', false)
    .get()
    .then(snapshot => {
      const data = getDataFromSnapshot(snapshot);
      dispatch(receiveSingleMeeting(data[0]));
    })
    .catch(() => {
      dispatch(getSingleMeetingError());
    });
};

export const createMeeting = (dispatch, data, navigate) => {
  dispatch(requestCreateMeetings());

  createMeetingApi(data).then(
    result => {
      dispatch(createMeetingSuccessResponse(result.data.message));
      navigate('/dashboard');
    },
    error => {
      if (error && error.data && error.data.message) {
        dispatch(createMeetingError(error.data.message));
      } else {
        dispatch(createMeetingError(MEETING_CREATE_FAILED));
      }
    },
  );
};

export const editMeeting = (dispatch, data, navigate) => {
  dispatch(requestEditMeetings());

  editMeetingApi(data).then(
    result => {
      dispatch(editMeetingSuccessResponse(result.data.message));
      navigate('/dashboard');
    },
    error => {
      if (error && error.data && error.data.message) {
        dispatch(editMeetingError(error.data.message));
      } else {
        dispatch(editMeetingError(MEETING_EDIT_FAILED));
      }
    },
  );
};

export const deleteMeeting = (dispatch, meetingId, userId) => {
  dispatch(requestDeleteMeetings());
  deleteMeetingApi(meetingId).then(
    result => {
      dispatch(deleteMeetingSuccessResponse(result.data.message));
      getMeetings(dispatch, userId);
    },
    error => {
      if (error && error.data && error.data.message) {
        dispatch(deleteMeetingError(error.data.error));
      } else {
        dispatch(deleteMeetingError(MEETING_DELETE_FAILED));
      }
    },
  );
};

export const getAllParticipants = (dispatch, userId) => {
  dispatch(requestGetParticipants());
  DB.collection('participantsHistory')
    .where('UserId', '==', userId)
    .get()
    .then(snapshot => {
      const data = getDataFromSnapshot(snapshot);
      dispatch(receiveAllParticipants(data));
    })
    .catch(() => {
      dispatch(getParticipantsError('Participants are not found'));
    });
};

export const sendGuestsInvitations = (dispatch, data, enqueueSnackbar) => {
  dispatch(requestSendGuestsInvitations());

  sendGuestsInvitation(data).then(
    result => {
      dispatch(sendGuestsInvitationSuccessResponse(result.data.data));
      enqueueSnackbar(result.data.data, {
        variant: 'success',
        persist: false,
        preventDuplicate: false,
      });
    },
    error => {
      if (error && error.data && error.data.message) {
        dispatch(sendGuestsInvitationsError(error.data.message));
        enqueueSnackbar(error.data.message, {
          variant: 'error',
          persist: false,
          preventDuplicate: false,
        });
      } else {
        enqueueSnackbar('invitation failed', {
          variant: 'error',
          persist: false,
          preventDuplicate: false,
        });
        dispatch(sendGuestsInvitationsError(MEETING_CREATE_FAILED));
      }
    },
  );
};

export const acceptInvitation = (dispatch, meetingId, token) => {
  dispatch(requestAcceptMeetingInvitation());

  acceptMeetingInvitation(meetingId, token).then(
    result => {
      dispatch(acceptMeetingInvitationSuccessResponse(result.data.message));
      window.location.href = result.data.data;
    },
    error => {
      if (error && error.response && error.response.data && error.response.data.error) {
        dispatch(acceptMeetingInvitationError(error.response.data.error));
      } else {
        dispatch(acceptMeetingInvitationError(SERVER_ERROR));
      }
    },
  );
};

export const joinToMeeting = (dispatch, meetingId) => {
  dispatch(requestStartMeeting());
  startMeeting(meetingId).then(
    result => {
      dispatch(startMeetingSuccessResponse(result.data.message));
    },
    error => {
      if (error && error.response && error.response.data && error.response.data.error) {
        dispatch(startMeetingFailure(error.response.data.error));
      } else {
        dispatch(startMeetingFailure(SERVER_ERROR));
      }
    },
  );
};

export const initVideoConference = (dispatch, meetingId, participantId) => {
  dispatch(requestGenerateVideoconferenceData());
  generateVideoConferenceData(meetingId, participantId).then(
    result => {
      dispatch(generateVideoconferenceDataSuccessResponse(result.data.data));
    },
    error => {
      if (error && error.data && error.data.message) {
        dispatch(generateVideoconferenceDataFailure(error.data.message));
      } else {
        dispatch(generateVideoconferenceDataFailure(VIDEOCONFERENCEDATA_FAILURE));
      }
    },
  );
};

export const resetMeetingErrors = dispatch => {
  dispatch(resetMeetingError());
};
