import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from 'react-google-autocomplete';
import PropTypes from 'prop-types';
import profileFormValidationSchema from '../../formGroups/profileFormSchema';
import { extractFields } from '../../../services/profile.service';
import CardModal from '../cards/CardModal';

function ProfileContent(props) {
  const { user, onSubmit, onDeleteAccount, linkGoogleUser, currentUser } = props;
  const [defaultValue, setDefaultValue] = useState('');
  const [isActive, setIsActive] = useState('');
  // get functions to build form with useForm() hook
  const { register, handleSubmit, trigger, formState, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(profileFormValidationSchema),
    defaultValues: {
      Name: currentUser && currentUser?.Name ? currentUser?.Name : user?.displayName,
    },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    const fieldsArray = [
      'BillingAddress',
      'City',
      'Country',
      'StateOrProvince',
      'ZipOrPostalCode',
      'AddressLine2',
      'Position',
      'PhoneNumber',
      'Name',
      'Company',
    ];
    fieldsArray.forEach(field => {
      if (field && currentUser[field] && currentUser[field] !== '') {
        if (field === 'BillingAddress') {
          setDefaultValue(currentUser[field].toString());
        }
        setValue(field, currentUser[field] || '', {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    });
  }, [currentUser]);
  const { errors, isDirty, isValid } = formState;
  const onSubmitData = data => {
    onSubmit(data);
  };
  const handleAddressChange = place => {
    const address = extractFields(place.address_components);
    address.formatted_address = place.formatted_address;
    setValue('BillingAddress', address.formatted_address, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue('City', address.city, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    setValue('Country', address.country, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    setValue('StateOrProvince', address.state, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    setValue('ZipOrPostalCode', address.postal_code, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    setValue('AddressLine2', address.addressLine2, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };

  const deleteAccount = () => {
    onDeleteAccount();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitData)}
      className="w-full text-2xl text-body placeholder-body h-full flex flex-col justify-between space-y-6 profile-content"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <input type="text" className="form-input" placeholder="Full Name" {...register('Name')} />
          {errors.Name?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.Name?.message}
              </div>
            </div>
          )}
        </div>
        <div>
          <input
            type="text"
            value={user?._delegate?.email}
            className="form-input"
            placeholder="Email"
            {...register('UserEmail')}
          />
          {errors.UserEmail?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.UserEmail?.message}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <input type="text" className="form-input" placeholder="Company Name" {...register('Company')} />
          {errors.Company?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.Company?.message}
              </div>
            </div>
          )}
        </div>
        <div>
          <input type="text" className="form-input" placeholder="Phone Number" {...register('PhoneNumber')} />
          {errors.PhoneNumber?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.PhoneNumber?.message}
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <Autocomplete
          placeholder="Street Address"
          className="form-input"
          apiKey={process.env.REACT_APP_PEOPLE_API_KEY}
          onPlaceSelected={place => {
            handleAddressChange(place);
          }}
          {...register('BillingAddress')}
          options={{
            types: ['address'],
            componentRestrictions: { country: ['us', 'ca'] },
          }}
          defaultValue={defaultValue}
        />
        {errors.BillingAddress?.message && (
          <div>
            <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
              {errors.BillingAddress?.message}
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div>
          <input type="text" className="form-input" placeholder="Apartment #" {...register('AddressLine2')} />
          {errors.AddressLine2?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.AddressLine2?.message}
              </div>
            </div>
          )}
        </div>
        <div>
          <input type="text" className="form-input" placeholder="City" {...register('City')} />
          {errors.City?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.City?.message}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div>
          <input type="text" className="form-input" placeholder="State / Province" {...register('StateOrProvince')} />
          {errors.StateOrProvince?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.StateOrProvince?.message}
              </div>
            </div>
          )}
        </div>
        <div>
          <input type="text" className="form-input" placeholder="Zip / Postal Code" {...register('ZipOrPostalCode')} />
          {errors.ZipOrPostalCode?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.ZipOrPostalCode?.message}
              </div>
            </div>
          )}
        </div>
        <div>
          <input type="text" className="form-input" placeholder="Country" {...register('Country')} />
          {errors.Country?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.Country?.message}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <input type="text" className="form-input" placeholder="Position" {...register('Position')} />
          {errors.Position?.message && (
            <div>
              <div style={{ fontSize: '12px' }} className="ml-6 text-red-600 font-semibold">
                {errors.Position?.message}
              </div>
            </div>
          )}
        </div>
        <button
          type="button"
          style={{ marginTop: '8px' }}
          onClick={() => linkGoogleUser()}
          className="btn btn-secondary w-full"
        >
          {' '}
          Linked accounts{' '}
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <button
            disabled={!isDirty || !isValid}
            type="submit"
            className={!isDirty || !isValid ? 'btn btn-secondary w-full' : 'btn button w-full'}
          >
            Update
          </button>
        </div>
        <div>
          <button type="button" className="btn btn-secondary w-full" onClick={() => setIsActive('deleteAccount')}>
            Delete Account
          </button>
        </div>
      </div>
      {isActive === 'deleteAccount' && (
        <CardModal
          title="Delete Account"
          button="Confirm"
          close={() => setIsActive('')}
          submit={() => {
            deleteAccount();
            setIsActive('');
          }}
          type="deleteAccount"
        />
      )}
    </form>
  );
}
ProfileContent.propTypes = {
  onSubmit: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.object]),
  currentUser: PropTypes.oneOfType([PropTypes.object]),
  linkGoogleUser: PropTypes.func,
  onDeleteAccount: PropTypes.func,
};

ProfileContent.defaultProps = {
  onSubmit: () => {},
  onDeleteAccount: () => {},
  user: {},
  currentUser: {},
  linkGoogleUser: () => {},
};
export default ProfileContent;
