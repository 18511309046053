export const NO_USER_FOUND = 'No users found!';
export const SERVER_ERROR = 'Oops! Something went wrong please try again';
export const NO_MEETINGS_FOUND = "Start Greet n' Eating by click the NEW Button to create a Meeting";
export const MEETING_CREATE_FAILED = 'Meeting is not created! Please try again later';
export const MEETING_EDIT_FAILED = 'Meeting is not edited! Please try again later';
export const MEETING_DELETE_FAILED = 'Meeting is not deleted! Please try again later';
export const USER_CREATE_FAILED = 'User is not created! Please try again later';
export const USER_CREATE_SUCCESS = 'User was Created Successfully.';
export const WRONG_CREDENTIALS = 'Incorrect email or password.';
export const VIDEOCONFERENCEDATA_FAILURE = 'An error occured while initializing your meeting. Please try again.';
