import { combineReducers } from 'redux';
import firebase from './firebase';
import meetings from './meetings/meetings';
import createMeeting from './meetings/createMeeting';
import editMeeting from './meetings/editMeeting';
import deleteMeeting from './meetings/deleteMeeting';
import meeting from './meetings/meeting';
import addStripUser from './addStripUser';
import addPaymentCard from './paymentCards/addPaymentCard';
import getPaymentCard from './paymentCards/getPaymentCard';
import deletePaymentCard from './paymentCards/deletePaymentCard';
import setupIntent from './paymentCards/setupIntent';
import changePassword from './changePassword';
import header from './header';
import invoices from './invoices/getInvoices';
import downloadInvoice from './invoices/downloadInvoice';
import profile from './profile';
import forgotPassword from './forgotPassword';
import participantHistory from './meetings/participantHistory';
import sendGuestInvitations from './meetings/sendGuestInvitations';
import acceptMeetingInvitation from './meetings/acceptMeetingInvitation';
import startMeeting from './meetings/startMeeting';
import videoConference from './meetings/videoConference';

export default combineReducers({
  firebase,
  meetings,
  createMeeting,
  editMeeting,
  deleteMeeting,
  meeting,
  addStripUser,
  addPaymentCard,
  getPaymentCard,
  deletePaymentCard,
  setupIntent,
  changePassword,
  header,
  invoices,
  downloadInvoice,
  profile,
  forgotPassword,
  participantHistory,
  sendGuestInvitations,
  acceptMeetingInvitation,
  startMeeting,
  videoConference,
});
