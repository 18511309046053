import { ADD_STRIP_USER_REQUEST, ADD_STRIP_USER_SUCCESS, ADD_STRIP_USER_FAILURE } from '../constants/actionTypes';

export default (
  state = {
    loading: false,
    error: null,
    message: null,
  },
  action,
) => {
  switch (action.type) {
    case ADD_STRIP_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_STRIP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.data?.message,
      };
    case ADD_STRIP_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.data.message,
      };
    default:
      return state;
  }
};
