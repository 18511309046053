import React, { useState } from 'react';
import WheelPicker from 'react-simple-wheel-picker';
import PropTypes from 'prop-types';
import { perUserPriceList } from '../../../services/util.service';

function ParticipantPopUp(props) {
  const [windowSize] = useState(window.innerWidth);
  const { setPrice, perUserPrice } = props;
  const handlePriceChange = option => {
    setPrice(parseInt(option?.id, 10));
  };

  return (
    <>
      <div className="absolute -left-10" />
      <div className="price-popup absolute bg-transparent mr-20 top-0 right-full ">
        <div className="wheelpicker relative overflow-hidden" style={{ direction: 'rtl' }}>
          <WheelPicker
            data={perUserPriceList()}
            onChange={handlePriceChange}
            height={windowSize > 1280 ? 800 : 480}
            titleText="Enter value same as aria-label"
            itemHeight={30}
            selectedID={perUserPrice}
            color="#FFE1C2"
            activeColor="#3A2B46"
            backgroundColor="transparent"
            shadowColor="none"
            fontSize={windowSize > 1280 ? 94 : 48}
          />
        </div>
      </div>
    </>
  );
}

ParticipantPopUp.propTypes = {
  setPrice: PropTypes.func.isRequired,
  perUserPrice: PropTypes.number.isRequired,
};
export default ParticipantPopUp;
