/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Header from 'components/header/Header';
import PropTypes from 'prop-types';
import Password from './password';
import Cards from './cards';
import './index.scss';
import CardModal from './cards/CardModal';
import ProfileContent from './profileContent';

function Profile(props) {
  const {
    paymentCards,
    deletePaymentCard,
    setupIntent,
    getPaymentCard,
    profilePasswordChanage,
    user,
    profileUpdate,
    deleteUserAccount,
    linkGoogleUser,
    currentUser,
    loading,
  } = props;
  const [isActive, setIsActive] = useState('cards');
  const [paymentCardId, setPaymentCardId] = useState('');

  const profileUpdateHandler = data => {
    profileUpdate(data);
  };

  const onDeleteAccount = () => {
    deleteUserAccount();
  };

  return (
    <div className="container-fluid pb-24 profile-container">
      <Header user={user} IsAccount={currentUser && currentUser.IsAccount} />

      <h1 className="text-4xl lg:text-7xl font-bold text-body flex-1 mt-10">Profile</h1>

      <div className="flex flex-col lg:flex-row mt-16 lg:space-x-24">
        <div className="flex flex-col lg:w-80 space-y-8 mb-10">
          <button
            type="button"
            className={`profile-tab ${isActive === 'cards' ? ' profile-tab--active' : ''}`}
            onClick={() => setIsActive('cards')}
          >
            <i className="fas fa-credit-card" />
            <span>Cards</span>
          </button>
          <button
            type="button"
            className={`profile-tab ${isActive === 'profile' ? ' profile-tab--active' : ''}`}
            onClick={() => setIsActive('profile')}
          >
            <i className="fas fa-user-tie" />
            <span>Profile</span>
          </button>
          <button
            type="button"
            className={`profile-tab ${isActive === 'password' ? ' profile-tab--active' : ''}`}
            onClick={() => setIsActive('password')}
          >
            <i className="fas fa-lock" />
            <span>Password</span>
          </button>
        </div>
        {loading ? (
          <div
            className="flex-1"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}
          >
            <div role="button" tabIndex="0">
              <i className="far fa-2x fa-spin fa-spinner" />
            </div>
          </div>
        ) : (
          <>
            <div className="flex-1">
              {isActive === 'cards' && (
                <div className="flex flex-wrap">
                  {props && paymentCards?.length
                    ? paymentCards.map((_card, _index) => (
                        <Cards
                          key={_index}
                          name={_card?.card.name ? _card?.card.name : 'Not available'}
                          expiry={
                            _card && _card.card.exp_month && _card.card.exp_year
                              ? // eslint-disable-next-line prefer-template
                                _card.card.exp_month + '/' + _card.card.exp_year
                              : 'Not available'
                          }
                          cvv="***"
                          last4={_card.card.last4}
                          id={_card.id}
                          deleteFuntion={() => {
                            setIsActive('deleteCardModal');
                            setPaymentCardId(_card.id);
                          }}
                        />
                      ))
                    : ''}
                  <button
                    type="button"
                    className="add-card"
                    onClick={() => setIsActive('addCardModal')}
                    disabled={currentUser?.IsAccount !== 'enable'}
                  >
                    {currentUser?.IsAccount !== 'enable' ? (
                      'Please contact support@greeteat.com to request BETA access!'
                    ) : (
                      <>
                        <i className="fa-regular fa-plus text-4xl" />
                        <div className=" text-base text-body">Add new card</div>
                      </>
                    )}
                  </button>
                </div>
              )}
              {isActive === 'password' && (
                <Password
                  profilePasswordChanage={(oldPassword, newPassword) =>
                    profilePasswordChanage(oldPassword, newPassword)
                  }
                />
              )}
              {isActive === 'profile' && (
                <ProfileContent
                  user={user}
                  currentUser={currentUser}
                  onSubmit={data => profileUpdateHandler(data)}
                  linkGoogleUser={linkGoogleUser}
                  onDeleteAccount={() => onDeleteAccount()}
                />
              )}
            </div>
            <div />
          </>
        )}
      </div>
      {isActive === 'addCardModal' && (
        <CardModal
          title="Add Card"
          button="Add Card"
          close={() => setIsActive('cards')}
          submit={() => {
            setIsActive('cards');
            getPaymentCard();
          }}
          type="addCardModal"
          setupIntentData={setupIntent}
        />
      )}
      {isActive === 'deleteCardModal' && (
        <CardModal
          title="Remove Credit Card"
          button="Remove"
          close={() => setIsActive('cards')}
          submit={() => {
            deletePaymentCard(paymentCardId);
            setIsActive('cards');
          }}
          type="deleteCardModal"
        />
      )}
    </div>
  );
}
Profile.propTypes = {
  deletePaymentCard: PropTypes.func,
  setupIntent: PropTypes.oneOfType([PropTypes.object]),
  getPaymentCard: PropTypes.func,
  profilePasswordChanage: PropTypes.func,
  profileUpdate: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.object]),
  currentUser: PropTypes.oneOfType([PropTypes.object]),
};

Profile.defaultProps = {
  setupIntent: () => {},
  getPaymentCard: () => {},
  profilePasswordChanage: () => {},
  deletePaymentCard: () => {},
  profileUpdate: () => {},
  user: {},
  currentUser: {},
};
export default Profile;
