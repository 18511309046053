import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';

function RangeSelection(props) {
  const { date, clearFilter } = props;
  const [selectedRang, setSelectedRange] = useState({});

  useEffect(() => {
    if (date.length) {
      const selectedFilterData = {
        startDate: moment(date[0]).format('MMM DD'),
        endDate: moment(date[1]).format('MMM DD'),
        year: moment(date[1]).format('YYYY'),
      };
      setSelectedRange(selectedFilterData);
    }
  }, [date]);

  const clearDateFilter = e => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedRange({});
    clearFilter(e);
  };

  return (
    <div className="ml-2">
      {selectedRang && selectedRang.startDate && selectedRang.endDate ? (
        <div>
          {selectedRang.startDate} - {selectedRang.endDate}, {selectedRang.year}
          <ClearIcon className="ml-2" onClick={e => clearDateFilter(e)} />
        </div>
      ) : (
        <div>
          <span>Filter by date </span>
        </div>
      )}
    </div>
  );
}

RangeSelection.propTypes = {
  date: PropTypes.oneOfType([PropTypes.array]),
  clearFilter: PropTypes.func,
};

RangeSelection.defaultProps = {
  date: [],
  clearFilter: () => {},
};

export default RangeSelection;
