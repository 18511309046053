import {
  ADD_STRIP_USER_REQUEST,
  ADD_STRIP_USER_SUCCESS,
  ADD_STRIP_USER_FAILURE,
  ADD_PAYMENT_CARD_REQUEST,
  ADD_PAYMENT_CARD_SUCCESS,
  ADD_PAYMENT_CARD_FAILURE,
  GET_PAYMENT_CARD_REQUEST,
  GET_PAYMENT_CARD_SUCCESS,
  GET_PAYMENT_CARD_FAILURE,
  DELETE_PAYMENT_CARD_REQUEST,
  DELETE_PAYMENT_CARD_SUCCESS,
  DELETE_PAYMENT_CARD_FAILURE,
  SETUP_INTENT_REQUEST,
  SETUP_INTENT_SUCCESS,
  SETUP_INTENT_FAILURE,
} from '../constants/actionTypes';

export const addStripUserRequest = () => {
  return {
    type: ADD_STRIP_USER_REQUEST,
  };
};

export const addStripUserSuccesfully = stripUser => {
  return {
    type: ADD_STRIP_USER_SUCCESS,
    stripUser,
  };
};

export const addStripUserFailure = message => {
  return {
    type: ADD_STRIP_USER_FAILURE,
    message,
  };
};

// ADD PAYMENT CARD

export const addPaymentCardRequest = () => {
  return {
    type: ADD_PAYMENT_CARD_REQUEST,
  };
};

export const addPaymentCardSuccess = message => {
  return {
    type: ADD_PAYMENT_CARD_SUCCESS,
    message,
  };
};

export const addPaymentCardFailure = message => {
  return {
    type: ADD_PAYMENT_CARD_FAILURE,
    message,
  };
};

// GET PAYMENT CARDS

export const getPaymentCardRequest = () => {
  return {
    type: GET_PAYMENT_CARD_REQUEST,
  };
};

export const getPaymentCardSuccess = message => {
  return {
    type: GET_PAYMENT_CARD_SUCCESS,
    message,
  };
};

export const getPaymentCardFailure = message => {
  return {
    type: GET_PAYMENT_CARD_FAILURE,
    message,
  };
};

// DELETE PAYMENT CARD
export const deletePaymentCardRequest = () => {
  return {
    type: DELETE_PAYMENT_CARD_REQUEST,
  };
};

export const deletePaymentCardSuccess = message => {
  return {
    type: DELETE_PAYMENT_CARD_SUCCESS,
    message,
  };
};

export const deletePaymentCardFailure = message => {
  return {
    type: DELETE_PAYMENT_CARD_FAILURE,
    message,
  };
};

// SETUP INTENT

export const setUpIntentRequest = () => {
  return {
    type: SETUP_INTENT_REQUEST,
  };
};

export const setUpIntentSuccess = message => {
  return {
    type: SETUP_INTENT_SUCCESS,
    message,
  };
};

export const setUpIntentFailure = message => {
  return {
    type: SETUP_INTENT_FAILURE,
    message,
  };
};
