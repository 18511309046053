import {
  GET_MEETINGS_REQUEST,
  GET_MEETINGS_SUCCESS,
  GET_MEETINGS_FAILURE,
  CREATE_MEETINGS_REQUEST,
  CREATE_MEETINGS_SUCCESS_RESPONSE,
  CREATE_MEETINGS_FAILURE,
  EDIT_MEETINGS_REQUEST,
  EDIT_MEETINGS_SUCCESS_RESPONSE,
  EDIT_MEETINGS_FAILURE,
  DELETE_MEETINGS_REQUEST,
  DELETE_MEETINGS_SUCCESS_RESPONSE,
  DELETE_MEETINGS_FAILURE,
  GET_SINGLE_MEETING_REQUEST,
  GET_SINGLE_MEETINGS_SUCCESS,
  GET_SINGLE_MEETINGS_FAILURE,
  SET_HEADER,
  TOGGLE_HEADER,
  GET_ALL_PARTICIPANTSHISTORY_REQUEST,
  GET_ALL_PARTICIPANTSHISTORY_SUCCESS_RESPONSE,
  GET_ALL_PARTICIPANTSHISTORY_FAILURE,
  SEND_GUESTS_INVITATIONS_REQUEST,
  GUESTS_INVITATIONS_SUCCESS_RESPONSE,
  GUESTS_INVITATIONS_FAILURE,
  ACCEPT_MEETING_INVITATIONS_REQUEST,
  ACCEPT_MEETING_INVITATIONS_SUCCESS_RESPONSE,
  ACCEPT_MEETING_INVITATIONS_FAILURE,
  START_MEETING_REQUEST,
  START_MEETING_SUCCESS_RESPONSE,
  START_MEETING_FAILURE,
  GENERATE_VIDEOCONFERENCEDATA_REQUEST,
  GENERATE_VIDEOCONFERENCEDATA_SUCCESS_RESPONSE,
  GENERATE_VIDEOCONFERENCEDATA_FAILURE,
  RESET_MEETING_ERROR,
} from '../constants/actionTypes';

// GET MEETINGS

export const requestGetMeetings = () => {
  return {
    type: GET_MEETINGS_REQUEST,
  };
};

export const receiveAllMeetings = meetings => {
  return {
    type: GET_MEETINGS_SUCCESS,
    meetings,
  };
};

export const getMeetingsError = message => {
  return {
    type: GET_MEETINGS_FAILURE,
    message: message,
  };
};

// CREATE MEETINGS

export const requestCreateMeetings = () => {
  return {
    type: CREATE_MEETINGS_REQUEST,
  };
};

export const createMeetingSuccessResponse = message => {
  return {
    type: CREATE_MEETINGS_SUCCESS_RESPONSE,
    message: message,
  };
};

export const createMeetingError = error => {
  return {
    type: CREATE_MEETINGS_FAILURE,
    error: error,
  };
};

// EDIT MEETINGS

export const requestEditMeetings = () => {
  return {
    type: EDIT_MEETINGS_REQUEST,
  };
};

export const editMeetingSuccessResponse = message => {
  return {
    type: EDIT_MEETINGS_SUCCESS_RESPONSE,
    message: message,
  };
};

export const editMeetingError = error => {
  return {
    type: EDIT_MEETINGS_FAILURE,
    error: error,
  };
};

// DELETE MEETINGS

export const requestDeleteMeetings = () => {
  return {
    type: DELETE_MEETINGS_REQUEST,
  };
};

export const deleteMeetingSuccessResponse = response => {
  return {
    type: DELETE_MEETINGS_SUCCESS_RESPONSE,
    response,
  };
};

export const deleteMeetingError = error => {
  return {
    type: DELETE_MEETINGS_FAILURE,
    error: error,
  };
};

export const resetMeetingError = () => {
  return {
    type: RESET_MEETING_ERROR,
  };
};

// GET SINGLE MEETINGS

export const requestGetSingleMeeting = () => {
  return {
    type: GET_SINGLE_MEETING_REQUEST,
  };
};

export const receiveSingleMeeting = meeting => {
  return {
    type: GET_SINGLE_MEETINGS_SUCCESS,
    meeting: meeting,
  };
};

export const getSingleMeetingError = () => {
  return {
    type: GET_SINGLE_MEETINGS_FAILURE,
  };
};

// HEADER HANDLER

export const handleToggleHeader = () => {
  return {
    type: TOGGLE_HEADER,
  };
};

export const setHeader = payload => {
  return {
    type: SET_HEADER,
    payload,
  };
};

// GET ALL PARTICIPANTS

export const requestGetParticipants = () => {
  return {
    type: GET_ALL_PARTICIPANTSHISTORY_REQUEST,
  };
};

export const receiveAllParticipants = participantsHistory => {
  return {
    type: GET_ALL_PARTICIPANTSHISTORY_SUCCESS_RESPONSE,
    participantsHistory: participantsHistory,
  };
};

export const getParticipantsError = message => {
  return {
    type: GET_ALL_PARTICIPANTSHISTORY_FAILURE,
    message: message,
  };
};

// SEND GUESTS INVITATIONS

export const requestSendGuestsInvitations = () => {
  return {
    type: SEND_GUESTS_INVITATIONS_REQUEST,
  };
};

export const sendGuestsInvitationSuccessResponse = message => {
  return {
    type: GUESTS_INVITATIONS_SUCCESS_RESPONSE,
    message: message,
  };
};

export const sendGuestsInvitationsError = message => {
  return {
    type: GUESTS_INVITATIONS_FAILURE,
    message: message,
  };
};

// ACCEPT MEETING INVITATIONS

export const requestAcceptMeetingInvitation = () => {
  return {
    type: ACCEPT_MEETING_INVITATIONS_REQUEST,
  };
};

export const acceptMeetingInvitationSuccessResponse = message => {
  return {
    type: ACCEPT_MEETING_INVITATIONS_SUCCESS_RESPONSE,
    message: message,
  };
};

export const acceptMeetingInvitationError = message => {
  return {
    type: ACCEPT_MEETING_INVITATIONS_FAILURE,
    message: message,
  };
};

// START MEETING

export const requestStartMeeting = () => {
  return {
    type: START_MEETING_REQUEST,
  };
};

export const startMeetingSuccessResponse = message => {
  return {
    type: START_MEETING_SUCCESS_RESPONSE,
    message: message,
  };
};

export const startMeetingFailure = message => {
  return {
    type: START_MEETING_FAILURE,
    message: message,
  };
};

// GENERATE VIDEO CONFERENCE DATA

export const requestGenerateVideoconferenceData = () => {
  return {
    type: GENERATE_VIDEOCONFERENCEDATA_REQUEST,
  };
};

export const generateVideoconferenceDataSuccessResponse = data => {
  return {
    type: GENERATE_VIDEOCONFERENCEDATA_SUCCESS_RESPONSE,
    data: data,
  };
};

export const generateVideoconferenceDataFailure = message => {
  return {
    type: GENERATE_VIDEOCONFERENCEDATA_FAILURE,
    message: message,
  };
};
