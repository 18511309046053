import '../../meeting/create-new.scss';
import React from 'react';
import Calendar from 'react-calendar';
import WheelPicker from 'react-simple-wheel-picker';
import Slider from '@mui/material/Slider';
// import "../create-new/style.css"
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function ChangeDate(props) {
  const { openCalendarModal } = props;
  const setKeyValue = arr => {
    return arr.map(item => {
      const dataSet = {
        id: item,
        value: item,
      };
      return dataSet;
    });
  };

  const newOptionGroups = optionGroups => {
    const groups = {};
    optionGroups.forEach(group => {
      groups[group] = setKeyValue(optionGroups[group]);
    });
    return groups;
  };
  const optionGroups = {
    time: [
      '12:30pm',
      '12:45pm',
      '1:00pm',
      '1:15pm',
      '1:30pm',
      '1:45pm',
      '2:00pm',
      '2:15pm',
      '2:30pm',
      '2:45pm',
      '3:00pm',
    ],
  };

  const opGroups = newOptionGroups(optionGroups);

  const pickerColumn = [];
  const handleOnChange = () => {};
  opGroups.forEach(group => {
    const data = opGroups[group];

    pickerColumn.push(
      <StyledWheelPicker
        data={data}
        onChange={handleOnChange}
        height={800}
        titleText="Enter value same as aria-label"
        itemHeight={1}
        selectedID={data[0].id}
        color="#FFE1C2"
        activeColor="#3A2B46"
        backgroundColor="transparent"
        shadowColor="none"
        fontSize={92}
      />,
    );
  });

  const [value, onChange] = React.useState(new Date());
  const [Value, setValue] = React.useState(0);

  const valuetext = _value => {
    setValue(_value);
    return `${_value}`;
  };

  return (
    <div className="flex items-start">
      <div className="w-64 rounded-primary flex justify-start flex-col items-center bg-white pb-12">
        <Calendar onChange={onChange} value={value} className="z-10 relative" />
        <div className="bg-primary pt-10 px-8 -mt-6 rounded-bl-[20px] rounded-br-[20px] w-full">
          <h3 className="font-bold text-5xl text-[#FFE1C2] text-center mb-8">{Value} min</h3>
          <div className="range-slider">
            <Slider
              aria-label="Small steps"
              defaultValue={0}
              getAriaValueText={() => valuetext}
              step={15}
              marks
              min={15}
              max={60}
              valueLabelDisplay="auto"
            />
            <div className="flex text-sm text-[#FFE1C2] space-x-12 -ml-1 -mt-1 pb-6">
              <span>15</span>
              <span>30</span>
              <span>45</span>
              <span>60</span>
            </div>
          </div>
        </div>
        <button type="button" className="change-btn relative top-16" onClick={() => openCalendarModal(false)}>
          Change
        </button>
      </div>
      <div className="wheelpicker relative overflow-hidden">
        <PickerContainer>{pickerColumn}</PickerContainer>
      </div>
    </div>
  );
}

const PickerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledWheelPicker = styled(WheelPicker)`
  box-shadow: none;
  background-color: red;
`;

ChangeDate.propTypes = {
  openCalendarModal: PropTypes.func,
};

ChangeDate.defaultProps = {
  openCalendarModal: () => {},
};
