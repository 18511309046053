import { getAuth, sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import Cookies from 'universal-cookie';
import { Firebase, GoogleProvider, firebaseAuth, DB } from '../firebase/firebase';
import { SERVER_ERROR, WRONG_CREDENTIALS } from '../redux/constants/messages';
import { addStripUser } from './stripe.service';
import { getUserAccount } from './api.service';

// actions
import {
  receiveLogin,
  receiveLogout,
  requestLogin,
  loginError,
  clearErrors,
  requestLogout,
  logoutError,
  requestCreateUser,
  createUserSuccessfully,
  createUserError,
  requestChangePassword,
  changePasswordSuccessfully,
  changePasswordError,
  requestResetPasswrod,
  resetPasswordSuccessfully,
  resetPasswordError,
  setGoogleContacts,
  setGoogleContactsError,
  getLoggingUserRequest,
  getLoggingUserSuccessfully,
  getLoggingUserError,
} from '../redux/actions/auth';

const cookies = new Cookies();

// export const verifyAuth = dispatch => {
//   Firebase.auth().onAuthStateChanged(user => {
//     if (user !== null) {
//       localStorage.setItem('user', JSON.stringify(user));
//       dispatch(receiveLogin(user));
//     } else {
//       dispatch(receiveLogout());
//     }
//   });
// };

export const verifyAuth = dispatch => {
  const TIMEOUT = 24 * 60 * 60 * 1000;
  Firebase.auth().onAuthStateChanged(user => {
    if (user !== null) {
      localStorage.setItem('user', JSON.stringify(user));
      const lastLoginAt = user?.metadata?.lastLoginAt;
      const currentTime = Date.now();
      const inactivityPeriod = currentTime - lastLoginAt;
      if (inactivityPeriod > TIMEOUT) {
        dispatch(receiveLogout());
        Firebase.auth().signOut();
        localStorage.removeItem('user');
      } else {
        dispatch(receiveLogin(user));
      }
    } else {
      dispatch(receiveLogout());
    }
  });
};

export const listConnectionNames = dispatch => {
  window.gapi.client.people.people.connections
    .list({
      resourceName: 'people/me',
      personFields: 'names,emailAddresses,photos',
    })
    .then(response => {
      const connections = response?.result.connections;
      localStorage.setItem('connections', JSON.stringify(connections));
      dispatch(setGoogleContacts(connections));
    })
    .catch(err => {
      dispatch(setGoogleContactsError(err));
    });
};

export const updateSigninStatus = (isSignedIn, dispatch) => {
  if (isSignedIn) {
    listConnectionNames(dispatch);
  } else {
    dispatch(setGoogleContactsError(SERVER_ERROR));
  }
};

export const initPeopleApi = dispatch => {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_PEOPLE_API_KEY;
  const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest'];
  const SCOPES = 'https://www.googleapis.com/auth/contacts.readonly';

  window.gapi.client
    .init({
      discoveryDocs: DISCOVERY_DOCS,
    })
    .then(
      () => {
        window.gapi.auth2
          .init({
            client_id: CLIENT_ID,
            apiKey: API_KEY,
            scope: SCOPES,
          })
          .then(() => {
            const isSignedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
            updateSigninStatus(isSignedIn, dispatch);
          });
      },
      error => {
        dispatch(setGoogleContactsError(error));
      },
    );
};

export const handleClientLoad = dispatch => {
  window.gapi.load('client:auth2', initPeopleApi(dispatch));
};

export const emailLogin = (dispatch, email, password) => {
  dispatch(requestLogin());
  Firebase.auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => {})
    .catch(() => {
      dispatch(loginError(WRONG_CREDENTIALS));
    });
};

export const googleLogin = async dispatch => {
  dispatch(requestLogin());
  GoogleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  Firebase.auth().useDeviceLanguage();
  Firebase.auth()
    .signInWithPopup(GoogleProvider)
    .then(async data => {
      await addStripUser(dispatch, data?.user);
      await handleClientLoad(dispatch);
    })
    .catch(() => {
      dispatch(loginError(SERVER_ERROR));
    });
};

export const logout = (dispatch, navigate) => {
  dispatch(requestLogout());
  Firebase.auth()
    .signOut()
    .then(() => {
      cookies.remove('authToken', { path: '/', domain: 'greeteat.com' });
      dispatch(receiveLogout());
      navigate('/');
    })
    .catch(() => {
      dispatch(logoutError());
    });
};

export const createUser = async (dispatch, userData) => {
  dispatch(requestCreateUser());
  Firebase.auth()
    .createUserWithEmailAndPassword(userData.Email, userData.Password)
    .then(async data => {
      await addStripUser(dispatch, userData);
      await dispatch(createUserSuccessfully(data));
    })
    .catch(error => {
      dispatch(createUserError(error));
    });
};

export const changeUserPassword = async (dispatch, oldPassword, newPassword, navigate) => {
  dispatch(requestChangePassword());
  const cred = await firebaseAuth.auth.EmailAuthProvider?.credential(Firebase.auth()?.currentUser?.email, oldPassword);
  Firebase.auth()
    ?.currentUser.reauthenticateWithCredential(cred)
    .then(() => {
      Firebase.auth()
        ?.currentUser.updatePassword(newPassword)
        .then(
          () => {
            dispatch(changePasswordSuccessfully());
            logout(dispatch, navigate);
          },
          error => {
            dispatch(changePasswordError(error));
          },
        );
    })
    .catch(error => {
      dispatch(changePasswordError(error));
    });
};

export const getUserIdToken = async () => {
  GoogleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  Firebase.auth().useDeviceLanguage();
  const userCred = await Firebase.auth().signInWithPopup(GoogleProvider);
  if (userCred && userCred.user) {
    const user = userCred?.user;
    const token = await user?.getIdToken();
    return token;
  }
  return null;
};

export const getCurrentUserProfile = async dispatch => {
  dispatch(getLoggingUserRequest);
  const userJson = localStorage.getItem('user');
  const user = JSON.parse(userJson);

  let entity = {};
  try {
    const snapshot = await DB.collection('users').where('UserId', '==', user.uid).where('IsDeleted', '==', false).get();
    entity = snapshot.docs[0].data();
    dispatch(getLoggingUserSuccessfully(entity));
  } catch (error) {
    dispatch(getLoggingUserError(error));
  }
};

export const getCurrentUserProfileById = async dispatch => {
  dispatch(getLoggingUserRequest);
  try {
    getUserAccount().then(
      result => {
        dispatch(getLoggingUserSuccessfully(result.data));
      },
      () => {
        dispatch(getLoggingUserError(SERVER_ERROR));
      },
    );
  } catch (error) {
    dispatch(getLoggingUserError(error));
  }
};

export const sendPasswordResetEmailToUser = async (dispatch, email, navigate) => {
  try {
    dispatch(requestResetPasswrod());
    const auth = await getAuth(Firebase);
    await sendPasswordResetEmail(auth, email);
    dispatch(resetPasswordSuccessfully('Password reset link sent!'));
    setTimeout(() => {
      navigate('/');
    }, [7000]);
  } catch (err) {
    dispatch(resetPasswordError(SERVER_ERROR));
  }
};

export const verifyPasswordResetCodeHandler = async oobCode => {
  const auth = await getAuth(Firebase);
  return verifyPasswordResetCode(auth, oobCode);
};

export const confirmPasswordResetHandler = async (oobCode, newPassword, navigate) => {
  const auth = await getAuth(Firebase);
  confirmPasswordReset(auth, oobCode, newPassword)
    .then(() => {
      navigate('/');
    })
    .catch(error => {
      return error;
    });
};

export const clearErrorMessage = dispatch => {
  dispatch(clearErrors());
};
