/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './button-group.scss';

function ButtonGroup({ buttons, doSomethingAfterClick }) {
  const [clickedId, setClickedId] = useState(-1);

  const handleClick = (event, id) => {
    setClickedId(id);
    doSomethingAfterClick(event);
  };

  return (
    <>
      <h1 className="font-bold" style={{ fontSize: '15px' }}>
        Send and Download as (pdf or excel)
      </h1>
      {buttons.map((buttonLabel, i) => (
        <button
          type="button"
          key={i}
          name={buttonLabel}
          onClick={event => handleClick(event, i)}
          className={i === clickedId ? 'customButton active' : 'customButton'}
        >
          {/* {buttonLabel === 'pdf' ? (
                <i className="fas fa-file-pdf text-xl" />
              ) : (
                <i className="fas fa-file-excel-o text-xl" />
              )} */}
          {buttonLabel}
        </button>
      ))}
    </>
  );
}

export default ButtonGroup;
