import moment from 'moment';
import { DefaultMeetingData, CopyDefaultMeetingData } from '../utils';

export const getDataFromSnapshot = snapshot => {
  const data = [];
  snapshot.docs.forEach(document => {
    if (document.exists) {
      const doc = document.data();
      doc.StartTime = doc.StartTime?.toDate() || doc.CreatedAt?.toDate();
      data.push(doc);
    }
  });
  return data;
};

export const extractMeetingData = (meeting, field, isExistingMeet, error, user) => {
  let configuredField = null;
  if (field === 'Participants') {
    CopyDefaultMeetingData[field] = [
      {
        value: user?._delegate?.email,
        isCreator: true,
      },
    ];
  }
  if (isExistingMeet) configuredField = meeting[field];
  else configuredField = error ? DefaultMeetingData[field] : CopyDefaultMeetingData[field];

  // if (isExistingMeet && field === 'StartTime' && configuredField) configuredField = configuredField.toDate();
  return configuredField;
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const generatePasscode = () => {
  return Math.random().toFixed(8).split('.')[1];
};

export const convertTimeStampToDate = (startDate, duration) => {
  const firebaseDate = {
    date: moment(startDate).format('MM/DD/YYYY'),
    startTime: moment(startDate).format('hh:mm A'),
    endTime: moment(startDate).add(duration, 'minutes').format('hh:mm A'),
  };
  return firebaseDate;
};

export const getThumbnail = name => {
  const fullName = name?.split(' ');
  const initials = fullName?.shift()?.charAt(0);
  return initials?.toUpperCase();
};

export const getSortValueFromDate = date => {
  let dateValue = '';
  if (date instanceof Date) {
    const copiedDate = new Date(date.getTime());
    copiedDate.setHours(0, 0, 0, 0);

    dateValue = copiedDate.valueOf();
  } else {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);

    dateValue = newDate.valueOf();
  }
  return dateValue;
};

export const perUserPriceList = () => {
  const perUserPriceListArray = [];
  for (let i = 10; i <= 500; i++) {
    perUserPriceListArray.push({
      id: i,
      value: i,
    });
  }
  return perUserPriceListArray;
};

export const trimVideoLink = link => {
  const regex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|(?:m\.)?youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/; // eslint-disable-line max-len
  const result = link.match(regex);
  return result ? result[1] : null;
};

export const participantIdForMeeting = (participants, userEmail) => {
  return participants.find(participant => participant.Email === userEmail)?.UID;
};
