import * as moment from 'moment-timezone';

export const timestamps = [
  {
    id: '1',
    value: '12:00am',
  },
  {
    id: '2',
    value: '12:15am',
  },
  {
    id: '3',
    value: '12:30am',
  },
  {
    id: '4',
    value: '12:45am',
  },
  {
    id: '5',
    value: '01:00am',
  },
  {
    id: '6',
    value: '01:15am',
  },
  {
    id: '7',
    value: '01:30am',
  },
  {
    id: '8',
    value: '01:45am',
  },
  {
    id: '9',
    value: '02:00am',
  },
  {
    id: '10',
    value: '02:15am',
  },
  {
    id: '11',
    value: '02:30am',
  },
  {
    id: '12',
    value: '02:45am',
  },
  {
    id: '13',
    value: '03:00am',
  },
  {
    id: '14',
    value: '03:15am',
  },
  {
    id: '15',
    value: '03:30am',
  },
  {
    id: '16',
    value: '03:45am',
  },
  {
    id: '17',
    value: '04:00am',
  },
  {
    id: '18',
    value: '04:15am',
  },
  {
    id: '19',
    value: '04:30am',
  },
  {
    id: '20',
    value: '04:45am',
  },
  {
    id: '21',
    value: '05:00am',
  },
  {
    id: '22',
    value: '05:15am',
  },
  {
    id: '23',
    value: '05:30am',
  },
  {
    id: '24',
    value: '05:45am',
  },
  {
    id: '25',
    value: '06:00am',
  },
  {
    id: '26',
    value: '06:15am',
  },
  {
    id: '27',
    value: '06:30am',
  },
  {
    id: '28',
    value: '06:45am',
  },
  {
    id: '29',
    value: '07:00am',
  },
  {
    id: '30',
    value: '07:15am',
  },
  {
    id: '31',
    value: '07:30am',
  },
  {
    id: '32',
    value: '07:45am',
  },
  {
    id: '33',
    value: '08:00am',
  },
  {
    id: '34',
    value: '08:15am',
  },
  {
    id: '35',
    value: '08:30am',
  },
  {
    id: '36',
    value: '08:45am',
  },
  {
    id: '37',
    value: '09:00am',
  },
  {
    id: '38',
    value: '09:15am',
  },
  {
    id: '39',
    value: '09:30am',
  },
  {
    id: '40',
    value: '09:45am',
  },
  {
    id: '41',
    value: '10:00am',
  },
  {
    id: '42',
    value: '10:15am',
  },
  {
    id: '43',
    value: '10:30am',
  },
  {
    id: '44',
    value: '10:45am',
  },
  {
    id: '45',
    value: '11:00am',
  },
  {
    id: '46',
    value: '11:15am',
  },
  {
    id: '47',
    value: '11:30am',
  },
  {
    id: '48',
    value: '11:45am',
  },
  {
    id: '49',
    value: '12:00pm',
  },
  {
    id: '50',
    value: '12:15pm',
  },
  {
    id: '51',
    value: '12:30pm',
  },
  {
    id: '52',
    value: '12:45pm',
  },
  {
    id: '53',
    value: '01:00pm',
  },
  {
    id: '54',
    value: '01:15pm',
  },
  {
    id: '55',
    value: '01:30pm',
  },
  {
    id: '56',
    value: '01:45pm',
  },
  {
    id: '57',
    value: '02:00pm',
  },
  {
    id: '58',
    value: '02:15pm',
  },
  {
    id: '59',
    value: '02:30pm',
  },
  {
    id: '60',
    value: '02:45pm',
  },
  {
    id: '61',
    value: '03:00pm',
  },
  {
    id: '62',
    value: '03:15pm',
  },
  {
    id: '63',
    value: '03:30pm',
  },
  {
    id: '64',
    value: '03:45pm',
  },
  {
    id: '65',
    value: '04:00pm',
  },
  {
    id: '66',
    value: '04:15pm',
  },
  {
    id: '67',
    value: '04:30pm',
  },
  {
    id: '68',
    value: '04:45pm',
  },
  {
    id: '69',
    value: '05:00pm',
  },
  {
    id: '70',
    value: '05:15pm',
  },
  {
    id: '71',
    value: '05:30pm',
  },
  {
    id: '72',
    value: '05:45pm',
  },
  {
    id: '73',
    value: '06:00pm',
  },
  {
    id: '74',
    value: '06:15pm',
  },
  {
    id: '75',
    value: '06:30pm',
  },
  {
    id: '76',
    value: '06:45pm',
  },
  {
    id: '77',
    value: '07:00pm',
  },
  {
    id: '78',
    value: '07:15pm',
  },
  {
    id: '79',
    value: '07:30pm',
  },
  {
    id: '80',
    value: '07:45pm',
  },
  {
    id: '81',
    value: '08:00pm',
  },
  {
    id: '82',
    value: '08:15pm',
  },
  {
    id: '83',
    value: '08:30pm',
  },
  {
    id: '84',
    value: '08:45pm',
  },
  {
    id: '85',
    value: '09:00pm',
  },
  {
    id: '86',
    value: '09:15pm',
  },
  {
    id: '87',
    value: '09:30pm',
  },
  {
    id: '88',
    value: '09:45pm',
  },
  {
    id: '89',
    value: '10:00pm',
  },
  {
    id: '90',
    value: '10:15pm',
  },
  {
    id: '91',
    value: '10:30pm',
  },
  {
    id: '92',
    value: '10:45pm',
  },
  {
    id: '93',
    value: '11:00pm',
  },
  {
    id: '94',
    value: '11:15pm',
  },
  {
    id: '95',
    value: '11:30pm',
  },
  {
    id: '96',
    value: '11:45pm',
  },
];

export const perUserPriceList = [
  {
    id: '10',
    value: '10',
  },
  {
    id: '11',
    value: '11',
  },
  {
    id: '12',
    value: '12',
  },
  {
    id: '13',
    value: '13',
  },
  {
    id: '14',
    value: '14',
  },
  {
    id: '15',
    value: '15',
  },
  {
    id: '16',
    value: '16',
  },
  {
    id: '17',
    value: '17',
  },
  {
    id: '18',
    value: '18',
  },
  {
    id: '19',
    value: '19',
  },
  {
    id: '20',
    value: '20',
  },
];

export const meetingsArray = [
  {
    title: 'Activated charcoal bitters swag vice',
    description:
      'Plaid seitan narwhal, air plant shoreditch 8-bit snackwave. Normcore green meditation, sustainable literally…',
    time: '12:00 PM',
    date: '16th July',
    pricePerUser: 20,
    generalPrice: 80,
    today: true,
    passCode: false,
  },
  {
    title: 'Kickstarter Distillery',
    description:
      'Tattooed freegan literally hella thundercats celiac. Meh af health goth post-ironic, whatever shabby chic paleo…',
    time: '12:00 PM',
    date: '16th July',
    pricePerUser: 20,
    generalPrice: 80,
    passCode: true,
  },
  {
    title: 'Leggings trade',
    description: 'Brunch affogato normcore. Normcore tbh glossier, freegan flannel subway tile cliche typewriter…',
    time: '12:00 PM',
    date: '16th July',
    pricePerUser: 20,
    generalPrice: 80,
    passCode: false,
  },
  {
    title: 'Tattooed freegan literally hella celiac',
    description: 'Stumptown everyday carry leggings, cold-pressed knausgaard edison bulb aesthetic hammock whatever…',
    time: '12:00 PM',
    date: '16th July',
    pricePerUser: 20,
    generalPrice: 80,
    passCode: false,
  },
  {
    title: 'Tattooed freegan literally hella celiac',
    description: 'Stumptown everyday carry leggings, cold-pressed knausgaard edison bulb aesthetic hammock whatever…',
    time: '12:00 PM',
    date: '16th July',
    pricePerUser: 20,
    generalPrice: 80,
    passCode: false,
  },
  {
    title: 'Tattooed freegan literally hella celiac',
    description: 'Stumptown everyday carry leggings, cold-pressed knausgaard edison bulb aesthetic hammock whatever…',
    time: '12:00 PM',
    date: '16th July',
    pricePerUser: 20,
    generalPrice: 80,
  },
];

export const invoicesArray = [
  {
    id: 4566987,
    date: 'Jan 26, 2022',
    time: '11:45 - 12:00 AM',
    topic: 'topic 1',
    participants: ['JG', 'LP', 'CK', 'TW', 'CF'],
    amountPerPerson: '$5',
    amount: '$4',
  },
  {
    id: 5424442,
    date: 'Feb 26, 2022',
    time: '18:32 - 08:00 PM',
    topic: 'topic 12',
    participants: ['JG', 'CF'],
    amountPerPerson: '$52',
    amount: '$34',
  },
];

export const DefaultMeetingData = {
  StartTime: new Date(),
  Duration: 30,
  VoucherPrice: 25,
  TopicName: '',
  Description: '',
  Participants: [],
  TimeZone: moment.tz.guess(),
  IsSecured: false,
  IsExpiry: false,
  Currency: 'USD',
};

export const CopyDefaultMeetingData = {
  StartTime: new Date(),
  Duration: 30,
  VoucherPrice: 25,
  TopicName: '',
  Description: '',
  TimeZone: moment.tz.guess(),
  IsSecured: false,
  IsExpiry: false,
  Currency: 'USD',
};
