/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
// import ReactSelect from 'react-select';
import './participant-input.scss';
import { getThumbnail } from '../../services/util.service';

const ParticipantInput = React.memo(props => {
  const { isExistingMeet, participants, parentCallback, title, user, currentUser } = props;
  const [participantInput, setParticipantInput] = useState(false);
  const [selectedListOpen, setSelectedListOpen] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [googleContacts, setGoogleContacts] = useState([]);
  const isVoucheruserd = participants?.filter(_participant => _participant?.VoucherCodeStatus === 'redeemed');

  useEffect(() => {
    let output;
    if (isExistingMeet) {
      output = participants?.map(s => ({
        value: s.Email,
        isCreator: s.IsModerator,
        isRemove: !isVoucheruserd.length > 0,
      }));
    } else {
      output = participants;
    }
    setSelectedParticipants(output);
    setGoogleContacts(JSON.parse(localStorage.getItem('connections') || '[]'));
  }, []);

  const options = googleContacts?.map(contact => {
    const obj = {};
    obj.value =
      contact && contact?.emailAddresses && contact?.emailAddresses.length
        ? contact?.emailAddresses[0]?.value
        : currentUser?.UserEmail;
    obj.label = (
      <div className="participant-list__option">
        <div>
          <div className="items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white text-[10px] text-center">
            <img src={contact?.photos.length ? contact?.photos[0]?.url : ''} alt="" />
          </div>
          <div>
            <div className="participant-list__option__title">
              {contact?.names ? contact?.names[0]?.displayName : currentUser.Fname}
            </div>
            <div className="participant-list__option__subtitle">
              {contact?.emailAddresses.length ? contact?.emailAddresses[0]?.value : currentUser?.UserEmail}
            </div>
          </div>
        </div>
      </div>
    );
    return obj;
  });

  const removeParticipant = index => {
    if (selectedParticipants[index].value === user?._delegate?.email || selectedParticipants[index].isCreator) {
      selectedParticipants[index + 1].isCreator = true;
    }
    setSelectedParticipants([...selectedParticipants.slice(0, index), ...selectedParticipants.slice(index + 1)]);
    if (selectedParticipants.length === 1) {
      setSelectedListOpen(false);
    }
    parentCallback([...selectedParticipants.slice(0, index), ...selectedParticipants.slice(index + 1)]);
  };
  const sendSelectedParticipants = (e, enter) => {
    if (enter) {
      setSelectedParticipants(selectedParticipants.concat([e]));
      if (selectedParticipants.length) {
        parentCallback(selectedParticipants.concat(e));
      } else {
        parentCallback([e]);
      }
    } else {
      setSelectedParticipants(e);
      parentCallback(e);
    }
  };

  const onKeyDown = e => {
    // if (e.keyCode === 13) {
    //   const emailPattern =
    //     // eslint-disable-next-line
    //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   if (emailPattern.test(e.target.value)) {
    //     sendSelectedParticipants({ value: e.target.value }, true);
    //     setParticipantInput(false);
    //   } else {
    //     parentCallback({ message: 'Invalid email' });
    //   }
    // } else
    if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  };

  const handleCreate = e => {
    if (!e) return;
    const emailPattern =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailPattern.test(e)) {
      sendSelectedParticipants({ value: e, isCreator: e === user?._delegate?.email || false, isRemove: true }, true);
      setParticipantInput(false);
    } else {
      parentCallback({ message: 'Invalid email' });
    }
  };

  const handleBlur = e => {
    handleCreate(e.target.value);
    setParticipantInput(false);
  };

  const handleInputHideShow = e => {
    e.preventDefault();
    e.stopPropagation();
    setParticipantInput(true);
  };

  const changeOption = e => {
    const lastSelectedOption = e[e.length - 1];
    if (lastSelectedOption && lastSelectedOption.value) {
      handleCreate(lastSelectedOption.value);
    }
  };

  const findImageUrl = emailAddress => {
    const contact = googleContacts.find(googleContact =>
      googleContact.emailAddresses.some(email => email.value === emailAddress),
    );
    return contact ? contact.photos[0]?.url : null;
  };

  return (
    <div className={`participants-input ${selectedListOpen ? 'participants-input--open' : ''}`}>
      <div className="participants-input__row">
        <div className="form-input flex items-center space-x-4 flex-1">
          <div className="title">{title}:</div>
          <div className="select-option-container flex-1">
            {participantInput ? (
              <CreatableSelect
                isClearable
                value={selectedParticipants}
                options={options}
                onChange={e => changeOption(e)}
                onCreateOption={e => handleCreate(e)}
                onBlur={e => handleBlur(e)}
                autoBlur
                className="participant-list"
                classNamePrefix="participant-list"
                placeholder="Type an email:"
                autoFocus
                menuIsOpen
                isMulti
                formatCreateLabel={userInput => userInput}
                onKeyDown={e => onKeyDown(e)}
                components={{
                  Option: ({ innerProps, label }) => (
                    <div {...innerProps} className="flex items-center px-2">
                      <span className="participant-list__option__title">{label}</span>
                      <button
                        type="button"
                        className="add-participant-button ml-3"
                        onClick={e => {
                          e.preventDefault();
                          handleCreate(label);
                        }}
                      >
                        +
                      </button>
                    </div>
                  ),
                }}
              />
            ) : (
              // <ReactSelect
              //   onBlur={handleBlur}
              //   autoBlur
              //   className="participant-list"
              //   classNamePrefix="participant-list"
              //   placeholder="Type an email:"
              //   autoFocus
              //   options={options}
              //   isMulti
              //   onChange={e => sendSelectedParticipants(e)}
              //   onKeyDown={e => onKeyDown(e)}
              //   value={selectedParticipants}
              // />
              <button type="button" className="add-participant-button" onClick={handleInputHideShow}>
                +
              </button>
            )}
          </div>
        </div>
        {selectedParticipants?.length > 0 && (
          <div
            className="pariticpant-thumbs cursor-pointer"
            role="button"
            tabIndex="0"
            aria-label="click to delete"
            onKeyDown={() => {}}
            onClick={() => setSelectedListOpen(!selectedListOpen)}
          >
            {!selectedListOpen ? (
              selectedParticipants?.map((d, i) =>
                d && d.label ? (
                  <div className="thumb" key={i}>
                    {d.label}
                  </div>
                ) : i < 4 ? (
                  <div className="items-center" key={i}>
                    <div
                      className={
                        // eslint-disable-next-line prefer-template
                        'items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white text-[10px] text-center ' +
                        (i === 0 ? 'bg-yellow-800' : 'bg-blue-800')
                      }
                    >
                      {findImageUrl(d.value) ? (
                        <img src={findImageUrl(d.value)} alt="User Thumbnail" className="google-img" />
                      ) : (
                        getThumbnail(d.value)
                      )}
                    </div>
                  </div>
                ) : null,
              )
            ) : (
              <div className="selected-participants__close">✕</div>
            )}
            {selectedParticipants?.length > 4 && !selectedListOpen && (
              <div
                className="items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-2 text-white text-[10px] text-center"
                style={{
                  background: 'black',
                }}
              >
                +{selectedParticipants.length - 4}
              </div>
            )}
          </div>
        )}
      </div>
      {selectedListOpen && (
        <div className="selected-participants">
          {selectedParticipants.map((d, i) => (
            <div className="flex justify-between mt-2" key={i}>
              <div className="flex space-x-2.5 items-center">
                <div className="participant-status__container">
                  <div className="participant-status">INVITE PENDING</div>
                </div>
                <div className="participant-list__option">
                  {d && d.label ? (
                    d.label
                  ) : (
                    <div>
                      <div
                        className={
                          // eslint-disable-next-line prefer-template
                          'items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white text-[10px] text-center ' +
                          (i === 0 ? 'bg-yellow-800' : 'bg-blue-800')
                        }
                      >
                        {getThumbnail(d.value)}
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        <div className="participant-list__option__title">{d.value}</div>
                        <div className="participant-list__option__subtitle flex">
                          {d.value} {d && d.isCreator && <span className="participant-status">Creator</span>}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <button
                  type="button"
                  disabled={
                    d.label === localStorage.getItem('userName') ||
                    d.value === localStorage.getItem('userName') ||
                    !d?.isRemove
                  }
                  className="selected-participants__remove"
                  onClick={() => removeParticipant(i)}
                >
                  -
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
ParticipantInput.propTypes = {
  parentCallback: PropTypes.func,
  isExistingMeet: PropTypes.bool,
  title: PropTypes.string,
};
ParticipantInput.defaultProps = {
  isExistingMeet: false,
  parentCallback: () => {},
  title: '',
};
export default ParticipantInput;
