import { SET_HEADER, TOGGLE_HEADER } from '../constants/actionTypes';

export default (
  state = {
    isOpen: false,
  },
  action,
) => {
  switch (action.type) {
    case TOGGLE_HEADER:
      return { isOpen: !state.isOpen };
    case SET_HEADER:
      return { isOpen: action.payload };
    default:
      return state;
  }
};
