import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// components
import Register from 'components/register';

// services
import { createUser, googleLogin } from '../../services/firebase.service';

function RegisterScreen(props) {
  const { createUserError, loading, dispatch } = props;
  const onRegister = (event, email, password, fullName) => {
    event.preventDefault();
    const userObj = {
      Email: email,
      Password: password,
      FullName: fullName,
    };
    createUser(dispatch, userObj);
  };

  const onGoogleLogin = event => {
    event.preventDefault();
    googleLogin(dispatch);
  };

  return (
    <Register
      onRegister={(event, email, password, fullName) => onRegister(event, email, password, fullName)}
      onGoogleLogin={event => onGoogleLogin(event)}
      error={createUserError}
      loading={loading}
    />
  );
}

RegisterScreen.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  createUserError: PropTypes.oneOfType([PropTypes.object]),
};

RegisterScreen.defaultProps = {
  createUserError: {},
  dispatch: () => {},
  loading: false,
};

const mapStateToProps = state => {
  return {
    loading: state.firebase.loading,
    createUserError: state.firebase.createUserError,
    isAuthenticated: state.firebase.isAuthenticated,
  };
};

export default connect(mapStateToProps)(RegisterScreen);
