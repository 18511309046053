/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import './timezone-input.scss';

function TimeZonesInput(props) {
  const { timeZones, callbackFunction, timeZone } = props;
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    label: timeZone && timeZone.label ? timeZone.label : timeZone,
    value: timeZone && timeZone.value ? timeZone.value : timeZone,
  });

  const handleZoneChange = _zone => {
    setSelectedTimeZone(_zone);
    callbackFunction(_zone);
  };

  return (
    timeZones && (
      <div className="form-input timezone-input">
        <div className="flex items-center space-x-4 flex-1">
          <div className="select-option-container flex-1">
            <ReactSelect
              defaultValue={selectedTimeZone}
              onChange={e => handleZoneChange(e)}
              options={timeZones}
              className="timezone-list"
              classNamePrefix="timezone-list"
            />
          </div>
        </div>
      </div>
    )
  );
}
export default TimeZonesInput;
