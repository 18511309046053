import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from 'components/loader';
import * as Sentry from '@sentry/react';
import AcceptInvitation from 'components/meeting/accept-invitation';
import { acceptInvitation } from '../../services/meetings.service';

function AcceptInvitationScreen(props) {
  const { meetingId, token } = useParams();
  const { loading, dispatch, message, error } = props;

  const accpetMeeting = (meetingID, UID) => {
    acceptInvitation(dispatch, meetingID, UID);
  };

  // const handleAcceptMeeting = async () => {
  //   try {
  //     await new Promise((resolve, reject) => {
  //       window.gapi.load('auth2', {
  //         callback: resolve,
  //         onerror: reject,
  //       });
  //     });

  //     const auth2 = await window.gapi.auth2.init({
  //       client_id: process.env.REACT_APP_CLIENT_ID,
  //       scope: 'https://www.googleapis.com/auth/calendar',
  //     });

  //     const user = await auth2.signIn();
  //     const accessToken = await user?.zc?.access_token;
  //     await accpetMeeting(meetingId, token, accessToken);
  //   } catch (_err) {
  //     console.error('error ============>', _err);
  //   } finally {
  //     console.log('=================>');
  //   }
  // };

  useEffect(() => {
    if ((meetingId, token)) {
      accpetMeeting(meetingId, token);
    }
  }, []);

  // loading
  if (loading) return <Loader visible />;

  return <AcceptInvitation message={message} error={error} />;
}

function mapStateToProps(state) {
  Sentry.captureMessage(state, 'debug');
  return {
    message: state.acceptMeetingInvitation.message,
    loading: state.acceptMeetingInvitation.loading,
    error: state.acceptMeetingInvitation.error,
  };
}

AcceptInvitationScreen.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  message: PropTypes.string,
  error: PropTypes.string,
};

AcceptInvitationScreen.defaultProps = {
  loading: false,
  dispatch: () => {},
  message: '',
  error: '',
};

export default connect(mapStateToProps)(AcceptInvitationScreen);
