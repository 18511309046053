import './index.scss';
import React, { useState, useEffect } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

function CardModal(props) {
  const { submit, close, type, title, setupIntentData, button } = props;
  const [cardInfo, setCardInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const elements = useElements();
  const stripe = useStripe();

  // configration function for paymentCard inputs

  const setupPaymentCardField = () => {
    try {
      const style = {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          lineHeight: '40px',
          fontWeight: 300,
          fontSize: '15px',

          '::placeholder': {
            color: 'gray',
          },
        },
      };

      if (elements) {
        const cardNumberElement = elements.getElement('cardNumber') || elements.create('cardNumber', { style });
        const cardExpiryElement = elements.getElement('cardExpiry') || elements.create('cardExpiry', { style });
        const cardCvvElement = elements.getElement('cardCvc') || elements.create('cardCvc', { style });

        cardNumberElement.on('change', event => {
          setError({ error: event.error?.message, isCcValid: event?.complete });
        });

        cardExpiryElement.on('change', event => {
          setError({ error: event.error?.message, isCcValid: event?.complete });
        });

        cardCvvElement.on('change', event => {
          setError({ error: event.error?.message, isCcValid: event?.complete });
        });

        cardNumberElement?.mount('#card-number-element');
        cardExpiryElement?.mount('#card-expiry-element');
        cardCvvElement?.mount('#card-cvv-element');
      }
      setCardInfo(elements.getElement('cardNumber'));
    } catch (_error) {
      setError({ error: _error });
    }
  };

  useEffect(() => {
    function handleKeypress(e) {
      if (e.key === 'Escape') {
        close();
      }
    }

    setupPaymentCardField();

    document.addEventListener('keydown', handleKeypress);

    return () => {
      document.removeEventListener('keydown', handleKeypress);
    };
  }, [elements]);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    if (cardInfo) {
      try {
        stripe
          .confirmCardSetup(setupIntentData?.client_secret, {
            payment_method: {
              card: cardInfo,
            },
          })
          .then(result => {
            if (result.error) {
              setLoading(false);
              setError({ error: 'Invalid Credit Card', isCcValid: false });
            } else if (result && result.setupIntent.status === 'succeeded') {
              setError({ error: '', isCcValid: true });
              setLoading(false);
              submit();
            } else {
              setLoading(false);
              setError({ error: 'Please Complate authenication proccess.', isCcValid: false });
            }
          });
      } catch (_error) {
        const errorMessage = _error?.error?.error?.raw?.message;
        setLoading(false);
        setError({ error: errorMessage || 'Invalid Credit Card', isCcValid: false });
      }
    }
  };
  return (
    <div>
      {props && type === 'addCardModal' && (
        <div className="fixed h-screen w-full popup flex justify-center top-0 left-0 items-center">
          <div
            role="button"
            aria-label="Close Modal"
            tabIndex={0}
            className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
            onClick={() => {}}
            onKeyDown={close}
          />
          <div className="relative popup-container">
            {loading && (
              <div className="list-view container-fluid w-full">
                <div
                  role="button"
                  tabIndex="0"
                  className="items-center justify-center overlay fixed z-10"
                  style={{ width: '45%', display: 'flex', alignItems: 'center', marginTop: '10%' }}
                >
                  <i className="far fa-2x fa-spin fa-spinner" />
                </div>
              </div>
            )}
            <h2 className="font-bold text-body mb-7">{title}</h2>
            <form className="w-full text-2xl text-body placeholder-body flex flex-col" onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6">
                <div id="card-number-element" className="form-card-input" />
                <div id="card-expiry-element" className="form-card-input" />
                <div id="card-cvv-element" className="form-card-input" />
                <div style={{ fontSize: '20px' }} className="-mt-2 ml-6 text-red-600 font-semibold">
                  {error?.error}
                </div>
              </div>

              <div className="popup-container__actions">
                <button type="button" className="btn btn-secondary w-full" onClick={close}>
                  {' '}
                  Cancel
                </button>
                <button
                  type="submit"
                  className={!error.isCcValid || loading ? 'btn btn-secondary w-full' : 'btn button w-full'}
                  disabled={!error.isCcValid || loading}
                >
                  {' '}
                  Save card{' '}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {type === 'deleteCardModal' && (
        <div className="absolute h-screen w-full popup flex justify-center top-0 left-0 items-center">
          <div
            role="button"
            aria-label="Close Modal"
            tabIndex={0}
            className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
            onClick={close}
            onKeyDown={() => {}}
          />
          <div className="relative popup-container">
            <h2 className="font-bold text-body mb-7">{title}</h2>
            <form className="w-full text-2xl text-body placeholder-body flex flex-col ">
              <div className="flex flex-col space-y-6">
                <p>Are you sure you want to remove the credit card ?</p>
                <span>You can’t undo this action.</span>
              </div>
              <div className="popup-container__actions">
                <button type="button" className="btn btn-secondary w-full" onClick={close}>
                  Cancel{' '}
                </button>
                <button type="button" className="btn button w-full" onClick={submit}>
                  {' '}
                  {button}{' '}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {type === 'deleteAccount' && (
        <div className="fixed h-screen w-full popup flex justify-center top-0 left-0 items-center">
          <div
            role="button"
            aria-label="Close Modal"
            tabIndex={0}
            className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
            onClick={close}
            onKeyDown={() => {}}
          />
          <div className="relative popup-container">
            <h2 className="font-bold text-body mb-7">{title}</h2>
            <form className="w-full text-2xl text-body placeholder-body flex flex-col ">
              <div className="flex flex-col space-y-6">
                <p>Are you sure you would like to delete your account?</p>
                <span>Warning: All of your Data will be gone.</span>
              </div>
              <div className="popup-container__actions">
                <button type="button" className="btn btn-secondary w-full" onClick={close}>
                  Cancel{' '}
                </button>
                <button type="button" className="btn button w-full" onClick={submit}>
                  {' '}
                  {button}{' '}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

CardModal.propTypes = {
  title: PropTypes.string,
  submit: PropTypes.func,
  close: PropTypes.func,
  type: PropTypes.string,
  setupIntentData: PropTypes.oneOfType([PropTypes.object]),
  button: PropTypes.string,
};

CardModal.defaultProps = {
  title: '',
  submit: () => {},
  close: () => {},
  type: PropTypes.string,
  setupIntentData: {},
  button: '',
};
export default CardModal;
