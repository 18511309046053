import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import moment from 'moment';

// components
import Loader from 'components/loader';
import Invoices from 'components/invoices';

import { getInvoices, downloadInvoices, sendInvoice } from '../../services/invoices.service';
import { getCurrentUserProfile } from '../../services/firebase.service';
import { getAllParticipants } from '../../services/meetings.service';

function InvoicesScreen(props) {
  const { error, loading, user, dispatch, invoices, currentUser, participantsHistory } = props;
  const [invoiceLoading, invoiceLoding] = useState(false);
  const [customError, setCustomError] = useState('');
  const uid = user?.uid;
  // get user profile

  const getUserProfile = async () => {
    getCurrentUserProfile(dispatch);
  };

  useEffect(async () => {
    if (uid) await getInvoices(dispatch, uid);
    await getUserProfile();
    await getAllParticipants(dispatch, uid);
  }, [user]);

  const base64ToArrayBuffer = base64 => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const downloadInvoice = async (invoice, fileType) => {
    invoiceLoding(true);
    downloadInvoices(dispatch, invoice, fileType)
      .then(res => {
        if (res && res.data && res.data.data) {
          const arr = base64ToArrayBuffer(res.data?.data);
          const mimeType = fileType === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
          const fileName = fileType === 'pdf' ? 'invoice.pdf' : 'invoice.xls';
          const blob = new Blob([arr], { type: mimeType });
          saveAs(blob, fileName);
          invoiceLoding(false);
        } else {
          invoiceLoding(false);
        }
      })
      .catch(e => {
        if (e && e.data && !e.data.isSuccessful) {
          setCustomError(e.data.message);
          invoiceLoding(false);
        }
      });
  };

  const sendInvoiceInMail = async (entity, fileType, item) => {
    item.type = fileType;
    if (entity?.emails.length) {
      invoiceLoding(true);
      sendInvoice(item, entity)
        .then(res => {
          if (res && res.data && res.data.isSuccessful) {
            invoiceLoding(false);
          }
        })
        .catch(e => {
          setCustomError(e.data.message);
          invoiceLoding(false);
        });
    } else {
      invoiceLoding(false);
    }
  };

  const getFilterInvoices = invoiceDate => {
    const startDate = moment(invoiceDate[0], 'DD-MM-YYYY').startOf('day').toDate();
    const endDate = moment(invoiceDate[1], 'DD-MM-YYYY').endOf('day').toDate();
    getInvoices(dispatch, uid, startDate, endDate);
  };

  const clearFilterInvoice = () => {
    if (uid) getInvoices(dispatch, uid);
  };

  // loading
  if (invoiceLoading) return <Loader visible />;

  return (
    <Invoices
      user={user}
      error={error}
      mainLoading={loading}
      invoiceLoading={invoiceLoading}
      getFilterInvoices={getFilterInvoices}
      invoicesArray={invoices}
      dispatch={dispatch}
      downloadInvoice={(invoice, fileType) => downloadInvoice(invoice, fileType)}
      sendInvoiceInMail={(emails, fileType, item) => sendInvoiceInMail(emails, fileType, item)}
      currentUser={currentUser}
      participantsHistory={participantsHistory}
      customInvoiceError={customError}
      clearFilterInvoice={clearFilterInvoice}
    />
  );
}

InvoicesScreen.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.object]),
  dispatch: PropTypes.func,
  invoices: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  currentUser: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  participantsHistory: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

InvoicesScreen.defaultProps = {
  loading: false,
  error: false,
  user: {},
  dispatch: () => {},
  invoices: [],
  currentUser: {},
  participantsHistory: [],
};

const mapStateToProps = state => {
  return {
    loading: state.firebase.loading || state.invoices.loading,
    error: state.firebase.error,
    user: state.firebase.user,
    invoices: state.invoices.invoices,
    currentUser: state.firebase.logginUser,
    participantsHistory: state.participantHistory.participants,
  };
};

export default connect(mapStateToProps)(InvoicesScreen);
