/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function Toast(props) {
  const [visible, setVisible] = useState(false);
  // const [_visible, setVisible] = useState(visible);
  useEffect(() => {
    const { visible: pVisible } = props;
    setVisible(pVisible);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
    }, 7000);
    const cleanup = () => {
      clearTimeout(timeoutId);
    };
    return cleanup;
  }, []);

  const { type, message } = props;
  let icon = <i className="far fa-check" />;
  let toastClass = 'bg-green-100 text-green-600 border-green-400';

  if (type === 'error') {
    icon = <i className="far fa-warning" />;
    toastClass = 'bg-red-100 text-red-600 border-red-400';
  }

  const close = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div className={`fixed top-10 right-10 border-2 space-x-2 py-3 font-semibold px-5 rounded-xl z-20 ${toastClass}`}>
        {icon}
        <span>{message}</span>
        <button type="button" onClick={close}>
          <i className="far fa-close ml-4" />
        </button>
      </div>
    )
  );
}

Toast.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  visible: PropTypes.bool,
};

Toast.defaultProps = {
  type: 'success',
  message: '',
  visible: false,
};
export default Toast;
