/* eslint-disable react/prop-types */
import './create-new.scss';

import React, { useCallback, useEffect, useState } from 'react';
import Header from 'components/header/Header';
import Calendar from 'react-calendar';
import ReactSelect from 'react-select';
import Switch from 'react-switch';
import WheelPicker from 'react-simple-wheel-picker';
import Slider from '@mui/material/Slider';
import ParticipantInput from 'components/participant-input/participant-input';
import GlobeIcon from 'components/icons/globe-icon';
import { timestamps } from 'utils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TimeZoneCardModal from 'components/timezone-input/timeZoneCard';
import ConfirmTransactionModal from 'components/genericModal/ConfirmTransactionModal';
import InvalidModal from 'components/genericModal/InvalidModal';
import Toast from 'components/toast';
import CardModal from 'components/profile/cards/CardModal';
import { resetMeetingErrors } from 'services/meetings.service';
import { DefaultMeetingData } from '../../utils';
import { extractMeetingData, generatePasscode, perUserPriceList } from '../../services/util.service';
import createMeetingValidationSchema from '../formGroups/createMeetingSchema';
import * as TimeZones from '../../assets/json/timezone.json';
import countries from '../../assets/json/countries.json';

const _ = require('underscore');

function CreateNew(props) {
  const {
    user,
    meeting,
    isExistingMeet,
    editMeeting,
    createMeeting,
    createMeetingError,
    googleContacts,
    editMeetingError,
    currentUser,
    isPaymentCard,
    setupIntent,
    getPaymentCard,
    dispatch,
  } = props;

  // Setup default / existing meeting data
  const [meetingDate, setMeetingDate] = useState(
    extractMeetingData(meeting, 'StartTime', isExistingMeet, createMeetingError),
  );
  const [meetingDuration, setMeetingDuration] = useState(
    extractMeetingData(meeting, 'Duration', isExistingMeet, createMeetingError),
  );
  const [_voucherPrice, setVoucherPrice] = useState(
    extractMeetingData(meeting, 'VoucherPrice', isExistingMeet, createMeetingError),
  );
  const [meetingTopic, setMeetingTopic] = useState(
    extractMeetingData(meeting, 'TopicName', isExistingMeet, createMeetingError),
  );
  const [_description, setDescription] = useState(
    extractMeetingData(meeting, 'Description', isExistingMeet, createMeetingError),
  );
  const [selectedParticipants, setSelectedParticipants] = useState(
    extractMeetingData(meeting, 'Participants', isExistingMeet, createMeetingError, user),
  );
  const [timezone, setTimeZone] = useState(extractMeetingData(meeting, 'TimeZone', isExistingMeet, createMeetingError));
  const [passCode, setPassCode] = useState(
    extractMeetingData(meeting, 'IsSecured', isExistingMeet, createMeetingError),
  );
  const [meetingTime, setMeetingTime] = useState(
    extractMeetingData(meeting, 'StartTime', isExistingMeet, createMeetingError),
  );
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [voucherWheelActive, setVoucherWheelActive] = useState(false);
  const [voucherError, setVoucherError] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [isActive, setIsActive] = useState('createNew');
  const [generatedPassCode, setGeneratedPassCode] = useState(
    extractMeetingData(meeting, 'Passcode', isExistingMeet, createMeetingError),
  );
  const [perUserPriceListData, setPerUserPriceListData] = useState([]);
  const [selectedTimeID, setSelectedTimeID] = useState(1);
  const [intervalId, setIntervalId] = useState();
  const [selectedTimeStamp, setSelectedTimeStamp] = useState();
  const [isExpiry, setIsExpiry] = useState(extractMeetingData(meeting, 'IsExpiry', isExistingMeet, createMeetingError));
  const [currency, setCurrency] = useState(extractMeetingData(meeting, 'Currency', isExistingMeet, createMeetingError));
  const [isVoucherClaimedOrRedeemed] = useState(
    isExistingMeet &&
      meeting?.Participants?.some(
        voucher => voucher.VoucherCodeStatus === 'claimed' || voucher.VoucherCodeStatus === 'redeemed',
      ),
  );
  // get functions to build form with useForm() hook
  const { register, formState, setError, clearErrors } = useForm({
    mode: 'all',
    resolver: yupResolver(createMeetingValidationSchema),
  });
  const { errors } = formState;
  const navigate = useNavigate();

  let lastElementOfStrik;
  const addStrikesOnMeetingTime = (currentTime, removeStrik) => {
    for (const _time of timestamps) {
      const element = document.getElementById(`wheel-picker-option-${_time.id}`);
      if (element) {
        const beginningTime = moment(currentTime?.value, 'h:mma');
        if (moment(_time.value, 'h:mma').isBefore(beginningTime) && removeStrik === false) {
          element.style.textDecoration = 'line-through';
          element.style.textDecorationColor = '#EF4B5A';
          lastElementOfStrik = _time;
        } else {
          element.removeAttribute('style');
          document.getElementById(`wheel-picker-option-${_time.id}`);
        }
      }
    }
  };

  const setNearestTime = async () => {
    let minuteToAdd = 0;
    let nearestTime = '';
    const selectedTimeZone = localStorage.getItem('selectedTimeZone');
    const time = isExistingMeet
      ? moment(meetingTime)
          .tz(selectedTimeZone || moment.tz.guess())
          .format('h:mm:a')
      : moment(new Date())
          .tz(selectedTimeZone || moment.tz.guess())
          .format('h:mm:a');
    const timeSplitted = time?.split(':') ?? [];
    if (timeSplitted.length === 3) {
      minuteToAdd = Math.ceil(parseInt(timeSplitted[1], 10) / 15) * 15 - parseInt(timeSplitted[1], 10);
      if (minuteToAdd) {
        nearestTime = moment(time, 'hh:mm:a').add(minuteToAdd, 'minutes').format('hh:mma');
      } else {
        nearestTime = moment(time, 'hh:mm:a').format('hh:mma');
      }
      setMeetingTime(nearestTime);
      const timestamp = _.find(timestamps, _time => {
        return _time.value === nearestTime;
      });
      if (timestamp) {
        setSelectedTimeID(timestamp.id);
        setSelectedTimeStamp(timestamp);
      } else {
        setSelectedTimeID(1);
      }
      const selectedMeetingDate = await localStorage.getItem('selectedMeetindDate');
      if (selectedMeetingDate && moment(selectedMeetingDate).isSame(moment(), 'day')) {
        addStrikesOnMeetingTime(timestamp, false);
      }
    }
  };

  const automaticStrike = () => {
    setNearestTime();
    const date = new Date();
    const delay = 60 - date.getSeconds() * 1000;
    setTimeout(() => {
      const intervalTime = setInterval(() => {
        setNearestTime();
      }, 60000);
      setIntervalId(intervalTime);
    }, delay);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('selectedMeetindDate', moment());
    automaticStrike();
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    if (createMeetingError) {
      setMeetingTopic(DefaultMeetingData.TopicName);
      setVoucherPrice(DefaultMeetingData.VoucherPrice);
      setSelectedParticipants(DefaultMeetingData.Participants);
      setDescription(DefaultMeetingData.Description);
    }
    if (isExistingMeet) {
      setSelectedParticipants(selectedParticipants?.map(s => ({ value: s.Email })));
    }
    window.addEventListener('resize', handleResize);
    setTimeZoneList(TimeZones.default);
    const perUserPriceListDataArray = perUserPriceList();
    setPerUserPriceListData(perUserPriceListDataArray);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let timeoutId;
    if (createMeetingError || editMeetingError) {
      timeoutId = setTimeout(() => {
        resetMeetingErrors(dispatch);
      }, 5000);
    }
    const cleanup = () => {
      clearTimeout(timeoutId);
    };
    return cleanup;
  }, []);

  const handleTimeChange = time => {
    setMeetingTime(time?.value);
  };

  const handlePriceChange = option => {
    if (option.id < 10 || option.id > 500) {
      setVoucherError(true);
    } else {
      setVoucherError(false);
    }
    setVoucherPrice(option.value);
  };

  const voucherInputChange = value => {
    if (value < 10 || value > 500) {
      setVoucherError(true);
    } else {
      setVoucherError(false);
    }
    setVoucherPrice(parseInt(value, 10));
  };

  const meetingTimeWheel = (
    <WheelPicker
      data={timestamps}
      onChange={handleTimeChange}
      height={windowSize > 1280 ? 780 : 480}
      titleText="Enter value same as aria-label"
      itemHeight={30}
      selectedID={selectedTimeID}
      color="#FFE1C2"
      activeColor="#3A2B46"
      backgroundColor="transparent"
      shadowColor="none"
      fontSize={windowSize > 1280 ? 94 : 48}
    />
  );

  const voucherWheel = (
    <WheelPicker
      data={perUserPriceListData}
      onChange={handlePriceChange}
      height={windowSize > 1280 ? 800 : 480}
      titleText="Enter value same as aria-label"
      itemHeight={30}
      selectedID={25}
      color="#FFE1C2"
      activeColor="#3A2B46"
      backgroundColor="transparent"
      shadowColor="none"
      fontSize={windowSize > 1280 ? 94 : 48}
    />
  );

  const getMeetingData = () => {
    return {
      description: _description,
      duration: meetingDuration,
      isSecured: passCode,
      participants: selectedParticipants?.message ? [] : _.pluck(selectedParticipants, 'value'),
      passcode: generatedPassCode === undefined ? null : generatedPassCode,
      startTime: moment(
        // eslint-disable-next-line prefer-template
        moment(meetingDate).format('YYYY-MM-DD') + ' ' + moment(meetingTime, ['h:mm A']).format('HH:mm'),
      ),
      timeZone: timezone && timezone?.value ? timezone?.value : timezone,
      topicName: meetingTopic,
      voucherPrice: _voucherPrice,
      host: process.env.REACT_APP_AUTH_DOMAIN,
      isExpiry: isExpiry,
      currency: currency,
    };
  };

  const submitForm = e => {
    e.preventDefault();
    let data = getMeetingData();
    const dateOfEvent = moment(meetingDate).format('DD/MM/YYYY');
    const isBeforeTime = moment
      .tz(moment(), data?.timeZone)
      .isAfter(moment.tz(`${dateOfEvent} ${meetingTime}`, 'DD/MM/YYYY HH:mma', data?.timeZone).toDate());
    if (!isPaymentCard) {
      setIsActive('addPayment');
    } else if (data.topicName === '' || data.topicName === null || data.topicName === undefined) {
      setError('topic', { message: 'Topic is required.' });
    } else if (data?.participants.length === 0 || data?.participants === undefined) {
      setError('participant', { message: 'Participant is required, Must be minimum 1 participant.' });
    } else if (data?.voucherPrice === '' || data?.voucherPrice === null || data?.voucherPrice === undefined) {
      setError('lunchVoucher', { message: 'Lunch vaucher is required' });
    } else if (data?.timeZone === '' || !data?.timeZone) {
      setError('timeZone', { message: 'TimeZone is required' });
    } else if (isBeforeTime) {
      setIsActive('invalidTimeModal');
    } else if (!isExistingMeet) {
      DefaultMeetingData.TopicName = data.topicName;
      DefaultMeetingData.VoucherPrice = data.voucherPrice;
      DefaultMeetingData.Description = data.description;
      DefaultMeetingData.Participants = selectedParticipants;
      DefaultMeetingData.IsExpiry = isExpiry;
      DefaultMeetingData.currency = data.currency;
      setIsActive('confirmTransaction');
    } else {
      if (isExistingMeet) data = { ...data, id: meeting.Id, isVouchersUpdate: true };
      if (isExistingMeet) editMeeting(data);
    }
  };

  const createMeetingCallback = e => {
    e.preventDefault();
    const data = getMeetingData();
    const dateOfEvent = moment(meetingDate).format('DD/MM/YYYY');
    const startDateTime = moment
      // eslint-disable-next-line prefer-template
      .tz(dateOfEvent + ' ' + meetingTime, 'DD/MM/YYYY HH:mma', timezone && timezone.value ? timezone.value : timezone)
      .toDate();
    data.startTime = startDateTime;
    createMeeting(data);
    localStorage.removeItem('selectedTimeZone');
  };

  const handleCallback = useCallback(childData => {
    if (childData?.message) {
      setError('participant', { message: childData?.message });
    } else {
      clearErrors(['participant']);
      setSelectedParticipants(childData);
    }
  }, []);

  const callbackFunction = useCallback(async data => {
    await setTimeZone(data);
    if (data && data.value) {
      localStorage.setItem('selectedTimeZone', data.value);
      automaticStrike();
    }
    setTimeout(() => {
      const lastElementID = parseInt(lastElementOfStrik?.id, 10) + 1;
      const scrollToElement = document.getElementById(`wheel-picker-option-${lastElementID}`);
      scrollToElement?.scrollIntoView();
      scrollToElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    });
    setIsActive('createNew');
  }, []);

  const handlePassCode = _passCode => {
    if (_passCode) {
      setGeneratedPassCode(Number(generatePasscode()));
    } else {
      setGeneratedPassCode('');
    }
  };

  const onChangeMeetingDate = date => {
    localStorage.setItem('selectedMeetindDate', date);
    if (moment(date).format('dd-mm-yyyy') !== moment().format('dd-mm-yyyy')) {
      addStrikesOnMeetingTime(selectedTimeStamp, true);
      clearInterval(intervalId);
    } else {
      automaticStrike();
    }
  };

  const setVoucherWheelActiveFunc = () => {
    setVoucherWheelActive(!voucherWheelActive);
    if (!voucherWheelActive) {
      addStrikesOnMeetingTime(selectedTimeStamp, true);
      clearInterval(intervalId);
    } else {
      automaticStrike();
    }
  };

  const options = countries.map(country => ({
    value: country?.currency?.code,
    label: (
      <div className="flex items-center">
        <img
          src={`data:image/png;base64,${country.flag}`}
          alt={country.name}
          style={{ width: '16px', height: '16px', marginRight: '8px' }}
        />
        <span className="text-[12px]">{country.name}</span>
      </div>
    ),
  }));

  const defaultOption = options.find(option => option.value === currency);

  const handleCurrencyChange = e => {
    setCurrency(e?.value);
  };

  return (
    <div className="container-fluid pb-24">
      <Header user={user} />
      {createMeetingError && <Toast visible message={createMeetingError} type="error" />}
      {editMeetingError && <Toast visible message={editMeetingError} type="error" />}
      <div className="flex justify-between items-baseline mb-12">
        <h1 className="text-4xl lg:text-7xl font-bold text-body flex-1 mt-10">
          {isExistingMeet ? 'Edit Meeting' : 'New Meeting'}
        </h1>
      </div>
      <form>
        <div className="grid grid-cols-1 xl:grid-cols-2 space-x-0 xl:space-x-12 space-y-12 xl:space-y-0">
          <div className="space-y-5">
            <div>
              <input
                type="text"
                placeholder="Meeting topic:"
                className="form-input"
                value={meetingTopic}
                {...register('topic', {
                  onChange: event => setMeetingTopic(event.target.value),
                })}
              />
            </div>
            {errors.topic?.message && (
              <div>
                <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.topic?.message}</div>
              </div>
            )}
            <div>
              <ParticipantInput
                title="Participants"
                participants={selectedParticipants}
                isExistingMeet={isExistingMeet}
                parentCallback={handleCallback}
                googleContacts={googleContacts}
                meeting={meeting}
                user={user}
                currentUser={currentUser}
              />
            </div>
            {errors.participant && (
              <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.participant?.message}</div>
            )}
            {windowSize > 1280 ? (
              <>
                <div className="form-input">
                  <div className="flex justify-between w-full items-center">
                    <div className="flex space-x-2 items-center w-full">
                      <div>Voucher:</div>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        $
                        <input
                          className="w-20 p-2 ml-2"
                          type="number"
                          disabled={isExistingMeet && isVoucherClaimedOrRedeemed}
                          value={_voucherPrice}
                          min={10}
                          max={500}
                          {...register('lunchVoucher', {
                            onChange: e => voucherInputChange(e.target.value),
                          })}
                        />
                      </span>
                      <div>
                        <button
                          type="button"
                          className="change-btn relative top-0 right-0"
                          disabled={isExistingMeet && isVoucherClaimedOrRedeemed}
                          onClick={() => setVoucherWheelActiveFunc()}
                        >
                          {voucherWheelActive ? 'Done' : 'Change'}
                        </button>
                      </div>
                      <div className="w-full">
                        <ReactSelect
                          options={options}
                          defaultValue={defaultOption}
                          onChange={e => handleCurrencyChange(e)}
                          className="timezone-list w-full"
                          classNamePrefix="timezone-list"
                          placeholder="Select a Currency"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {!isVoucherClaimedOrRedeemed ? (
                    <div className={`-mt-2 ml-6 ${voucherError ? 'text-red-600 font-semibold' : 'text-gray-600'}`}>
                      Please enter a value between $10 and $500
                    </div>
                  ) : (
                    isExistingMeet && (
                      <div
                        className={`-mt-2 ml-6 ${
                          isVoucherClaimedOrRedeemed ? 'text-red-500 font-semibold' : 'text-gray-600'
                        }`}
                      >
                        The price should remain unchanged as a participant has already claimed or redeemed a voucher
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="form-input">
                  <div className="flex space-x-4 items-center">
                    <div>Voucher</div>
                    <button
                      type="button"
                      disabled={isExistingMeet && isVoucherClaimedOrRedeemed}
                      onClick={() => (_voucherPrice > 0 ? setVoucherPrice(_voucherPrice - 1) : null)}
                      className="bg-[#3A2B46] h-5 w-5 rounded-full flex items-center text-center justify-center text-white pb-0.5 font-bold text-lg"
                    >
                      -
                    </button>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      $
                      <input
                        className="p-2"
                        style={{ width: '7ch' }}
                        type="number"
                        value={_voucherPrice}
                        disabled={isExistingMeet && isVoucherClaimedOrRedeemed}
                        min={10}
                        max={500}
                        {...register('lunchVoucher', {
                          onChange: e => voucherInputChange(e.target.value),
                        })}
                      />
                    </span>
                    <button
                      type="button"
                      disabled={isExistingMeet && isVoucherClaimedOrRedeemed}
                      className="bg-[#3A2B46] h-5 w-5 rounded-full flex items-center text-center justify-center text-white pb-0.5 font-bold text-lg"
                      onClick={e => {
                        e.preventDefault();
                        setVoucherPrice(_voucherPrice ? parseInt(_voucherPrice, 10) + 1 : 1);
                      }}
                    >
                      +
                    </button>
                    <div className="text-sm">per participant</div>
                  </div>
                </div>
                <div>
                  {!isVoucherClaimedOrRedeemed ? (
                    <div className={`-mt-2 ml-6 ${voucherError ? 'text-red-600 font-semibold' : 'text-gray-600'}`}>
                      Please enter a value between $10 and $500
                    </div>
                  ) : (
                    isExistingMeet && (
                      <div
                        className={`-mt-2 ml-6 ${
                          isVoucherClaimedOrRedeemed ? 'text-red-500 font-semibold' : 'text-gray-600'
                        }`}
                      >
                        The price should remain unchanged as a participant has already claimed or redeemed a voucher
                      </div>
                    )
                  )}
                </div>
                <div className="form-input">
                  <div className="w-full">
                    <ReactSelect
                      options={options}
                      defaultValue={defaultOption}
                      onChange={e => handleCurrencyChange(e)}
                      className="timezone-list w-full"
                      classNamePrefix="timezone-list"
                      placeholder="Select a Currency"
                    />
                  </div>
                </div>
              </>
            )}
            {errors.lunchVoucher?.message && (
              <div>
                <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.lunchVoucher?.message}</div>
              </div>
            )}

            <div className="form-input">
              <div className="flex justify-between w-full items-center">
                <div className="flex space-x-2 items-center">
                  <div className="f-14">Voucher Expiry:</div>
                  <div className="flex space-x-3 items-center">
                    <button
                      type="button"
                      onClick={() => setIsExpiry(false)}
                      className={`${isExpiry ? 'text-gray-900' : 'text-primary'} f-14 font-bold`}
                      disabled={isExistingMeet && !isExpiry}
                    >
                      No Expiry
                    </button>
                    <span>|</span>
                    <button
                      type="button"
                      onClick={() => setIsExpiry(true)}
                      className={`${!isExpiry ? 'text-gray-900' : 'text-primary'} f-14 font-bold`}
                      disabled={isExistingMeet}
                    >
                      24 Hours
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isExistingMeet && (
              <div>
                <div className="-mt-2 ml-6 text-gray-600">Voucher expiry date can only be increased</div>
              </div>
            )}
            <div className="flex space-x-5">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
                <div className="form-input">
                  <div className="flex ">
                    <div className="mr-4">Passcode</div>
                    <div className={`${passCode ? 'text-teal' : 'text-primary'} font-bold mr-6`}>
                      {passCode ? 'ON' : 'OFF'}
                    </div>
                    <Switch
                      onChange={() => {
                        setPassCode(!passCode);
                        handlePassCode(!passCode);
                      }}
                      checked={passCode}
                      className="react-switch"
                    />
                  </div>
                </div>
                {passCode && (
                  <div className="form-input">
                    <input
                      className="font-bold tracking-[10px] w-full"
                      type="text"
                      value={generatedPassCode}
                      disabled
                    />
                  </div>
                )}
              </div>
            </div>

            <textarea
              value={_description}
              onChange={event => setDescription(event.target.value)}
              className="form-input"
              placeholder="Enter a meeting description."
            />
            {errors.description?.message && (
              <div>
                <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.description?.message}</div>
              </div>
            )}
            <div className="space-x-5 justify-end hidden xl:flex">
              <button
                onClick={() => {
                  navigate('/dashboard');
                  clearInterval(intervalId);
                  localStorage.removeItem('selectedTimeZone');
                }}
                type="button"
                className="btn btn-secondary"
              >
                {' '}
                cancel
              </button>
              {/* <button
                type="button"
                onClick={e => submitForm(e)}
                disabled={voucherError}
                className="btn btn-primary w-56"
              >
                SUBMIT
              </button> */}
              <button type="button" disabled={voucherError} className="btn button w-56" onClick={e => submitForm(e)}>
                SUBMIT
              </button>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-start items-center overflow-hidden lg:overflow-visible">
            {!voucherWheelActive && (
              <div className="w-64 rounded-t-[20px]">
                <Calendar
                  onChange={date => {
                    setMeetingDate(date);
                    onChangeMeetingDate(date);
                  }}
                  value={meetingDate}
                  className="relative"
                  minDate={moment().toDate()}
                />
                <div className="bg-primary pt-10 px-8 -mt-6 rounded-bl-[20px] rounded-br-[20px]">
                  <h3 className="font-bold text-5xl text-[#FFE1C2] text-center mb-8">{meetingDuration} min</h3>
                  <div className="range-slider">
                    <Slider
                      aria-label="Small steps"
                      onChange={e => setMeetingDuration(e.target.value)}
                      value={meetingDuration}
                      step={30}
                      marks
                      min={30}
                      max={120}
                      valueLabelDisplay="auto"
                    />
                    <div className="flex text-sm text-[#FFE1C2] space-x-12 -ml-1 -mt-1 pb-6">
                      <span>30</span>
                      <span>60</span>
                      <span>90</span>
                      <span>120</span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setIsActive('selectTimeZoneModal');
                    window.scrollTo(0, 0);
                    clearErrors(['timeZone']);
                  }}
                  className="flex items-center justify-center mt-6 opacity-40 hover:opacity-100 w-full transition-opacity"
                >
                  <GlobeIcon className="w-5 mr-2" />
                  <span>
                    {
                      // timezone
                      timezone && timezone?.label ? timezone?.label : timezone
                    }
                  </span>
                </button>
                {errors.timeZone && (
                  <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.timeZone?.message}</div>
                )}
              </div>
            )}

            <div className="wheelpicker relative">{voucherWheelActive ? voucherWheel : meetingTimeWheel}</div>
          </div>
          <div className="flex flex-col md:flex-row md:space-y-0 space-y-6 justify-center items-center space-x-5 xl:hidden">
            <button
              type="button"
              onClick={() => {
                navigate('/dashboard');
                localStorage.removeItem('selectedTimeZone');
              }}
              className="btn btn-secondary"
            >
              cancel
            </button>
            {/* <button type="submit" className="btn btn-primary w-56" onClick={e => submitForm(e)}>
              SUBMIT
            </button> */}
            <button type="button" className="btn button w-56" disabled={voucherError} onClick={e => submitForm(e)}>
              SUBMIT
            </button>
          </div>
        </div>
      </form>
      {isActive === 'selectTimeZoneModal' && (
        <TimeZoneCardModal
          title="Select Meeting TimeZone"
          button="Select"
          close={() => setIsActive('createNew')}
          submit={() => setIsActive('createNew')}
          timeZones={timeZoneList}
          timeZone={timezone}
          callbackFunction={callbackFunction}
        />
      )}
      {isActive === 'confirmTransaction' && (
        <ConfirmTransactionModal
          title="CONFIRM TRANSACTION"
          submitButton="Confirm"
          cancelButton="Cancel"
          participant={{
            participant: selectedParticipants.length,
            voucherPrice: _voucherPrice,
            serviceFee: 20,
            time: meetingTime,
            date: moment(meetingDate).format('DD/MM/YYYY'),
          }}
          close={() => setIsActive('createNew')}
          submit={e => {
            createMeetingCallback(e);
            setIsActive('createNew');
          }}
        />
      )}
      {isActive === 'invalidTimeModal' && (
        <InvalidModal
          title="INVALID START TIME"
          cancelButton="Close"
          message="Select a meeting time in the future."
          close={() => setIsActive('createNew')}
        />
      )}
      {isActive === 'addPayment' && (
        <CardModal
          title="Add Card"
          button="Add Card"
          type="addCardModal"
          close={() => setIsActive('createNew')}
          submit={() => {
            setIsActive('createNew');
            getPaymentCard();
          }}
          isDelete={false}
          setupIntentData={setupIntent}
        />
      )}
    </div>
  );
}

CreateNew.propTypes = {
  editMeeting: PropTypes.func.isRequired,
  isExistingMeet: PropTypes.bool.isRequired,
  createMeeting: PropTypes.func.isRequired,
  getPaymentCard: PropTypes.func.isRequired,
};

export default CreateNew;
