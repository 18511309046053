import * as Yup from 'yup';

// custom validator for check unique value in Yup Schema

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

// create New Meeting Validation Schema

const createMeetingValidationSchema = Yup.object().shape({
  topic: Yup.string()
    .required('Topic is required')
    .min(3, 'Topic should be more than 2 characters')
    .max(128, 'Topic should be less than 128 characters'),
  startTime: Yup.date()
    .default(function () {
      return new Date();
    })
    .required('Start time is required'),
  duration: Yup.number().min(1).max(1440).required('Duration is required'),
  timeZone: Yup.string().required('Time zone is required'),
  participant: Yup.array().ensure().required('Participant is required'),
  lunchVoucher: Yup.number().typeError('Voucher price must be a number').required('Lunch vaucher is required'),
  isSecured: Yup.boolean().required(),
  // passcode: Yup.when('isSecured', {
  //     is: true,
  //     then: Yup.string().required().not().empty(),
  //     otherwise: Yup.string().allow('').max(0),
  // }),
  description: Yup.string().max(2000, 'Description should be less than 2000 characters'),
  isVouchersUpdate: Yup.boolean(),
  currency: Yup.string().required('Currency is required'),
});

export default createMeetingValidationSchema;
