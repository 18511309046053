export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_MEETINGS_REQUEST = 'GET_MEETINGS_REQUEST';
export const GET_MEETINGS_SUCCESS = 'GET_MEETINGS_SUCCESS';
export const GET_MEETINGS_FAILURE = 'GET_MEETINGS_FAILURE';

export const CREATE_MEETINGS_REQUEST = 'CREATE_MEETINGS_REQUEST';
export const CREATE_MEETINGS_SUCCESS_RESPONSE = 'CREATE_MEETINGS_SUCCESS_RESPONSE';
export const CREATE_MEETINGS_FAILURE = 'CREATE_MEETINGS_FAILURE';

export const EDIT_MEETINGS_REQUEST = 'EDIT_MEETINGS_REQUEST';
export const EDIT_MEETINGS_SUCCESS_RESPONSE = 'EDIT_MEETINGS_SUCCESS_RESPONSE';
export const EDIT_MEETINGS_FAILURE = 'EDIT_MEETINGS_FAILUR';

export const DELETE_MEETINGS_REQUEST = 'DELETE_MEETINGS_REQUEST';
export const DELETE_MEETINGS_SUCCESS_RESPONSE = 'DELETE_MEETINGS_SUCCESS_RESPONSE';
export const DELETE_MEETINGS_FAILURE = 'DELETE_MEETINGS_FAILURE';

export const GET_SINGLE_MEETING_REQUEST = 'GET_SINGLE_MEETING_REQUEST';
export const GET_SINGLE_MEETINGS_SUCCESS = 'GET_SINGLE_MEETINGS_SUCCESS';
export const GET_SINGLE_MEETINGS_FAILURE = 'GET_SINGLE_MEETINGS_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS_RESPONSE = 'CREATE_USER_SUCCESS_RESPONSE';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const ADD_STRIP_USER_REQUEST = 'ADD_STRIP_USER_REQUEST';
export const ADD_STRIP_USER_SUCCESS = 'ADD_STRIP_USER_SUCCESS';
export const ADD_STRIP_USER_FAILURE = 'ADD_STRIP_USER_FAILURE';

export const ADD_PAYMENT_CARD_REQUEST = 'ADD_PAYMENT_CARD_REQUEST';
export const ADD_PAYMENT_CARD_SUCCESS = 'ADD_PAYMENT_CARD_SUCCESS';
export const ADD_PAYMENT_CARD_FAILURE = 'ADD_PAYMENT_CARD_FAILURE';

export const GET_PAYMENT_CARD_REQUEST = 'GET_PAYMENT_CARD_REQUEST';
export const GET_PAYMENT_CARD_SUCCESS = 'GET_PAYMENT_CARD_SUCCESS';
export const GET_PAYMENT_CARD_FAILURE = 'GET_PAYMENT_CARD_FAILURE';

export const DELETE_PAYMENT_CARD_REQUEST = 'DELETE_PAYMENT_CARD_REQUEST';
export const DELETE_PAYMENT_CARD_SUCCESS = 'DELETE_PAYMENT_CARD_SUCCESS';
export const DELETE_PAYMENT_CARD_FAILURE = 'DELETE_PAYMENT_CARD_FAILURE';

export const SETUP_INTENT_REQUEST = 'SETUP_INTENT_REQUEST';
export const SETUP_INTENT_SUCCESS = 'SETUP_INTENT_SUCCESS';
export const SETUP_INTENT_FAILURE = 'SETUP_INTENT_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const SET_HEADER = 'SET_HEADER';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const REQUEST_DOWNLOAD_INVOICES = 'REQUEST_DOWNLOAD_INVOICES';
export const DOWNLOAD_INVOICES_SUCCESS = 'DOWNLOAD_INVOICES_SUCCESS';
export const DOWNLOAD_INVOICES_FAILURE = 'DOWNLOAD_INVOICES_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS_RESPONSE = 'UPDATE_PROFILE_SUCCESS_RESPONSE';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST,';
export const RESET_PASSWORD_SUCCESS_RESPONSE = 'RESET_PASSWORD_SUCCESS_RESPONSE,';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE,';

export const GET_ALL_PARTICIPANTSHISTORY_REQUEST = 'GET_ALL_PARTICIPANTSHISTORY_REQUEST';
export const GET_ALL_PARTICIPANTSHISTORY_SUCCESS_RESPONSE = 'GET_ALL_PARTICIPANTSHISTORY_SUCCESS_RESPONSE';
export const GET_ALL_PARTICIPANTSHISTORY_FAILURE = 'GET_ALL_PARTICIPANTSHISTORY_FAILURE';

export const SET_GOOGLE_CONTACTS = 'SET_GOOGLE_CONTACTS,';
export const SET_GOOGLE_CONTACTS_ERROR = 'SET_GOOGLE_CONTACTS_ERROR';

export const GET_LOGGIN_USER_REQUEST = 'GET_LOGGIN_USER_REQUEST';
export const GET_LOGGIN_USER_SUCCESS_RESPONSE = 'GET_LOGGIN_USER_SUCCESS_RESPONSE';
export const GET_LOGGIN_USER_FAILURE = 'GET_LOGGIN_USER_FAILURE';

export const SEND_GUESTS_INVITATIONS_REQUEST = 'SEND_GUESTS_INVITATIONS_REQUEST';
export const GUESTS_INVITATIONS_SUCCESS_RESPONSE = 'GUESTS_INVITATIONS_SUCCESS_RESPONSE';
export const GUESTS_INVITATIONS_FAILURE = 'GUESTS_INVITATIONS_FAILURE';

export const ACCEPT_MEETING_INVITATIONS_REQUEST = 'ACCEPT_MEETING_INVITATIONS_REQUEST';
export const ACCEPT_MEETING_INVITATIONS_SUCCESS_RESPONSE = 'ACCEPT_MEETING_INVITATIONS_SUCCESS_RESPONSE';
export const ACCEPT_MEETING_INVITATIONS_FAILURE = 'ACCEPT_MEETING_INVITATIONS_FAILURE';

export const START_MEETING_REQUEST = 'START_MEETING_REQUEST';
export const START_MEETING_SUCCESS_RESPONSE = 'START_MEETING_SUCCESS_RESPONSE';
export const START_MEETING_FAILURE = 'START_MEETING_FAILURE';

export const GENERATE_VIDEOCONFERENCEDATA_REQUEST = 'GENERATE_VIDEOCONFERENCEDATA_REQUEST';
export const GENERATE_VIDEOCONFERENCEDATA_SUCCESS_RESPONSE = 'GENERATE_VIDEOCONFERENCEDATA_SUCCESS_RESPONSE';
export const GENERATE_VIDEOCONFERENCEDATA_FAILURE = 'GENERATE_VIDEOCONFERENCEDATA_FAILURE';

export const RESET_MEETING_ERROR = 'RESET_MEETING_ERROR';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';

export const DELETE_USER_ACCOUNT_REQUEST = 'DELETE_USER_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS_RESPONSE = 'DELETE_ACCOUNT_SUCCESS_RESPONSE';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';
