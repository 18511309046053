import {
  DELETE_PAYMENT_CARD_REQUEST,
  DELETE_PAYMENT_CARD_SUCCESS,
  DELETE_PAYMENT_CARD_FAILURE,
} from '../../constants/actionTypes';

export default (
  state = {
    loading: false,
    error: null,
    message: null,
  },
  action,
) => {
  switch (action.type) {
    case DELETE_PAYMENT_CARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message.data.message,
      };
    case DELETE_PAYMENT_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message.data.message,
      };
    default:
      return state;
  }
};
