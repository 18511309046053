import {
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS_RESPONSE,
  UPDATE_PROFILE_FAILURE,
  DELETE_USER_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS_RESPONSE,
  DELETE_ACCOUNT_FAILURE,
} from '../constants/actionTypes';

// EDIT MEETINGS

export const requestUpdateProfile = () => {
  return {
    type: UPDATE_PROFILE_REQUEST,
  };
};

export const updateProfileSuccessResponse = message => {
  return {
    type: UPDATE_PROFILE_SUCCESS_RESPONSE,
    message: message,
  };
};

export const updateProfileFailure = error => {
  return {
    type: UPDATE_PROFILE_FAILURE,
    error: error,
  };
};

export const requestDeleteAccount = () => {
  return {
    type: DELETE_USER_ACCOUNT_REQUEST,
  };
};

export const deleteAccountSuccessResponse = message => {
  return {
    type: DELETE_ACCOUNT_SUCCESS_RESPONSE,
    data: message,
  };
};

export const deleteAccountFailure = error => {
  return {
    type: DELETE_ACCOUNT_FAILURE,
    error: error,
  };
};
