import React from 'react';
import PropTypes from 'prop-types';

function EditMeetingModal(props) {
  const { title, text, close } = props;
  return (
    <div>
      <div className="fixed h-screen w-full popup flex justify-center top-0 left-0 items-center z-[999]">
        <div
          role="button"
          tabIndex="0"
          aria-label="Close Modal"
          className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
          onKeyDown={() => {}}
          onClick={close}
        />
        <div className="relative popup-container">
          <h2 className="font-bold text-body">{title}</h2>
          <div className="mt-3" style={{ overflowX: 'auto', width: '99%' }}>
            <p>{text}</p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
            <button type="button" className="btn btn-secondary" onClick={close}>
              {' '}
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

EditMeetingModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  close: PropTypes.func,
};

EditMeetingModal.defaultProps = {
  title: '',
  text: '',
  close: () => {},
};
export default EditMeetingModal;
