import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useNavigate } from 'react-router-dom';

function VideoConference(props) {
  const apiRef = useRef();
  const navigate = useNavigate();
  const { roomName, jwt, topicName, startMeeting } = props;
  const [subject, setSubject] = useState(topicName);
  const handleJitsiIFrameRef1 = iframeRef => {
    iframeRef.style.border = '10px solid #3d3d3d';
    iframeRef.style.background = '#3d3d3d';
    iframeRef.style.height = '100vh';
  };

  const handleReadyToClose = async () => {
    await startMeeting();
    navigate(`/dashboard`);
  };

  useEffect(() => {
    setSubject(topicName);
  }, [topicName]);

  const handleApiReady = apiObj => {
    console.log('apiObj >>', apiObj);
    apiObj.executeCommand('subject', subject);
    apiRef.current = apiObj;
  };

  const renderSpinner = () => (
    <div
      style={{
        fontFamily: 'sans-serif',
        textAlign: 'center',
      }}
    >
      Loading..
    </div>
  );

  return (
    <JitsiMeeting
      domain="8x8.vc"
      roomName={roomName}
      spinner={renderSpinner}
      jwt={jwt}
      configOverwrite={{
        disableInviteFunctions: true,
        hiddenPremeetingButtons: ['invite'],
        subject: subject,
      }}
      onReadyToClose={handleReadyToClose}
      onApiReady={externalApi => handleApiReady(externalApi)}
      getIFrameRef={handleJitsiIFrameRef1}
    />
  );
}

VideoConference.propTypes = {
  roomName: PropTypes.string,
  jwt: PropTypes.string,
  topicName: PropTypes.string,
  startMeeting: PropTypes.func,
};

VideoConference.defaultProps = {
  roomName: null,
  jwt: null,
  topicName: null,
  startMeeting: () => {},
};

export default VideoConference;
