import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// components
import Login from 'components/login';

// services
import { emailLogin, googleLogin } from '../../services/firebase.service';

function LoginScreen(props) {
  const { dispatch, error, loading } = props;
  const onLogin = (event, email, password) => {
    event.preventDefault();
    emailLogin(dispatch, email, password);
  };

  const onGoogleLogin = event => {
    event.preventDefault();
    googleLogin(dispatch);
  };

  return (
    <Login
      onLogin={(event, email, password) => onLogin(event, email, password)}
      onGoogleLogin={event => onGoogleLogin(event)}
      error={error}
      loading={loading}
    />
  );
}

LoginScreen.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.bool,
};

LoginScreen.defaultProps = {
  error: false,
  dispatch: () => {},
  loading: false,
};

const mapStateToProps = state => {
  return {
    loading: state.firebase.loading,
    error: state.firebase.error,
  };
};

export default connect(mapStateToProps)(LoginScreen);
