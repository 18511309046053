import {
  GET_ALL_PARTICIPANTSHISTORY_REQUEST,
  GET_ALL_PARTICIPANTSHISTORY_SUCCESS_RESPONSE,
  GET_ALL_PARTICIPANTSHISTORY_FAILURE,
} from '../../constants/actionTypes';

export default (
  state = {
    participants: [],
    loading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case GET_ALL_PARTICIPANTSHISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        participants: [],
      };
    case GET_ALL_PARTICIPANTSHISTORY_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        participants: action.participantsHistory,
      };
    case GET_ALL_PARTICIPANTSHISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
        participants: [],
      };
    default:
      return state;
  }
};
