import {
  ACCEPT_MEETING_INVITATIONS_REQUEST,
  ACCEPT_MEETING_INVITATIONS_SUCCESS_RESPONSE,
  ACCEPT_MEETING_INVITATIONS_FAILURE,
} from '../../constants/actionTypes';

export default (
  state = {
    message: null,
    loading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case ACCEPT_MEETING_INVITATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
      };
    case ACCEPT_MEETING_INVITATIONS_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.message,
        error: null,
      };
    case ACCEPT_MEETING_INVITATIONS_FAILURE:
      return {
        ...state,
        message: null,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};
