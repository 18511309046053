import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Toast from 'components/toast';
import Header from 'components/header/Header';
import ParticipantInput from 'components/participant-input/participant-input';
import { filterInvoicesByMonth } from 'services/invoices.service';
import $ from 'jquery';
import Calendar from 'react-calendar';
import RangeSelection from 'components/rangeSelection';
import InvoicesRow from './invoicesRow';
import GenericModal from '../genericModal/GenericModal';
import PrintInvoiceContent from './invoicesRow/printInvoiceContent';
import allPeriod from '../../assets/all-period.svg';
import allPeriodBlack from '../../assets/all-period-black.svg';

const _ = require('underscore');

function Invoices(props) {
  const {
    user,
    downloadInvoice,
    sendInvoiceInMail,
    currentUser,
    getFilterInvoices,
    mainLoading,
    invoiceLoading,
    customInvoiceError,
    clearFilterInvoice,
  } = props;
  let { participantsHistory } = props;
  let { invoicesArray } = props;
  const [dropdownOpen] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [error, setError] = useState('');
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [singalitem, setSingleItem] = useState('');
  const [isAll, setIsAll] = useState(false);
  const [invoiceArrayForPrint, setInvoiceArrayForPrint] = useState([]);
  const [isInvoiceArrayForPrint, setIsInvoiceArrayForPrint] = useState(false);
  const [participant, setParticipant] = useState([]);
  const [totalVoucherPrice, setTotalVoucherPrice] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [calendarActive, setCalendarActive] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState([]);

  const sortInvoices = () => {
    invoicesArray = invoicesArray.sort((a, b) => {
      const bb = new Date(b.StartTime);

      const aa = new Date(a.StartTime);

      const bbb = bb.valueOf();
      const aaa = aa.valueOf();
      const result = bbb - aaa;

      if (result === 0) return 0;
      else if (result > 0) return 1;
      else return -1;
    });
  };

  useEffect(() => {
    sortInvoices();
  }, []);

  const groupByInvoices = invoices => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const filteredInvoices = [];
    for (let index = 0; index < invoices.length; index++) {
      const _invoice = invoices[index];
      if (index !== 0) {
        const InvoiceMonth = moment(_invoice.StartTime).month();
        const InvoiceYear = moment(_invoice.StartTime).year();
        const PInvoiceMonth = moment(invoices[index - 1].StartTime).month();
        const PInvoiceYear = moment(invoices[index - 1].StartTime).year();

        if (InvoiceMonth !== PInvoiceMonth || InvoiceYear !== PInvoiceYear) {
          filteredInvoices.push({
            isLastRowOfMonth: true,
            month: months[PInvoiceMonth],
            year: PInvoiceYear,
            startDate: invoiceDate[0],
            endDate: invoiceDate[1],
          });
        }
      }
      filteredInvoices.push(_invoice);
      if (invoices.length - 1 === index) {
        const InvoiceMonth = moment(_invoice.StartTime).month();
        const InvoiceYear = moment(_invoice.StartTime).year();
        filteredInvoices.push({
          isLastRowOfMonth: true,
          month: months[InvoiceMonth],
          year: InvoiceYear,
          startDate: invoiceDate[0],
          endDate: invoiceDate[1],
        });
      }
    }
    invoicesArray = filteredInvoices;
  };

  const handleCallback = childData => {
    if (childData?.message) {
      setError('participant', { message: childData?.message });
    } else {
      setError('participant', { message: 'some thing want worng' });
      setSelectedParticipants(childData);
    }
  };

  const sendInvoiceInMailToContainer = async fileType => {
    const entity = { emails: _.pluck(selectedParticipants, 'value') };
    if (singalitem && singalitem !== undefined && !isAll) {
      sendInvoiceInMail(entity, fileType, singalitem);
    } else {
      await groupByInvoices(invoicesArray);
      invoicesArray.forEach(_invoice => {
        if (_invoice.isLastRowOfMonth) {
          invoicesArray = _invoice;
        }
      });
      sendInvoiceInMail(entity, fileType, invoicesArray);
    }
    setInvoiceModal(false);
  };

  const invoiceModalHandler = (data, isOpen) => {
    setInvoiceModal(isOpen);
    setSingleItem(data);
  };

  const downloadAllInvoices = async fileType => {
    await groupByInvoices(invoicesArray);
    invoicesArray.forEach(_invoice => {
      if (_invoice.isLastRowOfMonth) {
        invoicesArray = _invoice;
      }
    });
    downloadInvoice(invoicesArray, fileType);
    setInvoiceModal(false);
  };

  const displayPrintWindow = () => {
    setTimeout(function () {
      $('#receipt').css('display', 'table');
      $('#receipt').addClass('d-print-table').removeClass('d-none');
      const _window = window.open('');
      if (_window) {
        _window.document.write($('#receipt')[0]?.outerHTML);
        $('#receipt').addClass('d-none').removeClass('d-print-table');
        _window.print();
        _window.close();
        setIsInvoiceArrayForPrint(false);
      }
    }, 500);
  };

  const printReceipt = async item => {
    // group by pariticipants for data formatting
    const participantsHistoryData = [];
    if (participantsHistory.length > 0) {
      participantsHistory.forEach(singleParticipant => {
        if (singleParticipant.MeetingId === item?.MeetingId) {
          participantsHistoryData.push(singleParticipant);
        }
      });
    }
    if (item) {
      participantsHistory = participantsHistory.filter(history => {
        return history.MeetingId === item?.MeetingId;
      });
    }
    const groupByParticipantHistory = _.groupBy(participantsHistory, 'VoucherPrice');
    const parseData = [];
    let totalVoucherPriceOfParticipants = 0;
    let serviceFeeOfParticipnats = 0;

    for (const key in groupByParticipantHistory) {
      const obj = {};
      obj.voucherCost = key;
      obj.quantity = groupByParticipantHistory[key].length;
      obj.totalPrice = obj.voucherCost * obj.quantity;
      obj.description = `$ ${obj.voucherCost} - Uber Eats Vouchers`;
      totalVoucherPriceOfParticipants += obj.totalPrice;
      serviceFeeOfParticipnats += obj.totalPrice * obj.quantity;
      parseData.push(obj);
    }
    setParticipant(parseData);
    setTotalVoucherPrice(totalVoucherPriceOfParticipants);
    setServiceFee(serviceFeeOfParticipnats);

    // invoice formatting

    let printInvoices = [];
    let displayInvoiceArray = [];
    setInvoiceArrayForPrint([]);
    const clone = invoicesArray.slice(0);
    if (!item && item === undefined && isAll) {
      await groupByInvoices(invoicesArray);
      invoicesArray.forEach(_invoice => {
        if (_invoice.isLastRowOfMonth) {
          displayInvoiceArray = _invoice;
        }
      });
    }
    if (displayInvoiceArray.isLastRowOfMonth) {
      printInvoices = await filterInvoicesByMonth(clone, displayInvoiceArray.month, displayInvoiceArray.year);
    } else {
      item.displayDate = await moment(item.StartTime).format('DD MMM, YYYY');
      printInvoices.push(item);
    }
    setInvoiceArrayForPrint(printInvoices);
    displayPrintWindow();
    setIsInvoiceArrayForPrint(true);
  };

  const handleCalendar = () => {
    if (calendarActive) {
      setCalendarActive(false);
    } else {
      setCalendarActive(true);
    }
  };

  const invoiceFilter = date => {
    setCalendarActive(false);
    setInvoiceDate(date);
    getFilterInvoices(date);
  };

  const clearFilter = () => {
    setCalendarActive(false);
    setInvoiceDate([]);
    clearFilterInvoice();
  };

  return (
    <div className="container-fluid invoice-container">
      <Header user={user} />
      {customInvoiceError && <Toast visible message={customInvoiceError} type="error" />}
      <div className="flex justify-between items-center mt-10">
        <h1 className="text-4xl lg:text-7xl font-bold text-body flex-1">Invoices</h1>
        <div className="relative">
          <button
            type="button"
            className={`uppercase font-bold items-center flex font-bold space-x-2${
              invoiceDate.length ? 'filter-block color-white' : 'color-black'
            }`}
            onClick={e => handleCalendar(e)}
          >
            <img alt="calendar" src={invoiceDate.length ? allPeriod : allPeriodBlack} />
            <RangeSelection date={invoiceDate} clearFilter={clearFilter} />
          </button>
          {calendarActive && (
            <Calendar
              onChange={date => {
                if (date && date.length === 2) {
                  invoiceFilter(date);
                }
              }}
              value={invoiceDate?.length > 0 ? invoiceDate : [new Date(), new Date()]}
              selectRange
              allowPartialRange
              className="dashboard-calendar"
            />
          )}
        </div>
      </div>
      <div
        className={`w-full whitespace-nowrap pb-10 ${
          dropdownOpen ? 'overflow-visible' : invoicesArray.length !== 1 && 'overflow-auto'
        }`}
      >
        {invoicesArray.length > 0 ? (
          <table className="invoices-table mt-10">
            <thead>
              <tr>
                <th width="25%" style={{ paddingLeft: '1.75rem' }}>
                  START TIME
                </th>
                <th width="10%">TOPIC</th>
                <th width="20%">PARTICIPANTS</th>
                <th>Voucher</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {invoicesArray?.map(item => {
                return [
                  <InvoicesRow
                    item={item}
                    key={item.InvoiceId}
                    invoicesArray={invoicesArray}
                    downloadInvoice={downloadInvoice}
                    sendInvoiceInMail={sendInvoiceInMail}
                    setInvoiceModal={(data, isOpen) => invoiceModalHandler(data, isOpen)}
                    printReceipt={data => printReceipt(data)}
                  />,
                ];
              })}
            </tbody>
          </table>
        ) : (
          <div style={{ height: '85vh' }}>
            <h3 className="text-1xl lg:text-3xl font-bold text-body flex-1 text-center">Invoices are not found</h3>
          </div>
        )}
      </div>
      {mainLoading || invoiceLoading ? (
        <div className="list-view container-fluid w-full">
          <div
            role="button"
            tabIndex="0"
            className="flex items-center justify-center fixed z-10"
            style={{ width: '65%' }}
          >
            <i className="far fa-3x fa-spin fa-spinner" />
          </div>
        </div>
      ) : (
        <>
          {invoicesArray.length > 0 && (
            <div className="md:space-x-5 justify-end flex flex-col md:flex-row space-y-4 md:space-y-0 mb-5">
              <a href="/dashboard">
                <button type="button" className="btn btn-secondary w-full md:w-auto">
                  <i className="fas fa-angle-left" />
                  <span>Back</span>
                </button>
              </a>
              <button
                onClick={() => {
                  setInvoiceModal(true);
                  setIsAll(true);
                }}
                className={!invoicesArray.length ? 'btn btn-secondary w-full md:w-auto' : 'btn button w-full md:w-auto'}
                disabled={!invoicesArray.length}
                type="button"
              >
                <i className="fas fa-arrow-down-to-bracket" />
                <span>All invoices</span>
              </button>
            </div>
          )}
          {invoiceModal && (
            <GenericModal
              title="Send Invoice"
              close={() => {
                setInvoiceModal(false);
                setIsAll(false);
              }}
              submit={fileType => sendInvoiceInMailToContainer(fileType)}
              button="Send"
              downloadAllInvoice={fileType => downloadAllInvoices(fileType)}
              printAllReceipt={() => printReceipt()}
              isAll={isAll}
              selectedParticipants={selectedParticipants}
              invoicesArray={invoicesArray.length}
            >
              <ParticipantInput title="To" participants={[]} parentCallback={handleCallback} />
              {error.message}
            </GenericModal>
          )}
          {invoiceArrayForPrint.length && isInvoiceArrayForPrint ? (
            <div id="receipt" className="d-none" style={{ width: '100%', color: '#6c757d' }}>
              <PrintInvoiceContent
                groupByParticipantHistory={participant}
                totalVoucherPrice={totalVoucherPrice}
                serviceFee={serviceFee}
                user={currentUser}
                invoiceArrayForPrint={invoiceArrayForPrint}
              />
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
}
Invoices.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
  invoicesArray: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  downloadInvoice: PropTypes.func,
  sendInvoiceInMail: PropTypes.func,
  currentUser: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  participantsHistory: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getFilterInvoices: PropTypes.func,
  mainLoading: PropTypes.bool,
  invoiceLoading: PropTypes.bool,
  customInvoiceError: PropTypes.string,
  clearFilterInvoice: PropTypes.func,
};

Invoices.defaultProps = {
  user: {},
  invoicesArray: [],
  downloadInvoice: () => {},
  sendInvoiceInMail: () => {},
  currentUser: {},
  participantsHistory: [],
  getFilterInvoices: () => {},
  mainLoading: false,
  invoiceLoading: false,
  customInvoiceError: '',
  clearFilterInvoice: () => {},
};
export default Invoices;
