import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import logo from '../../assets/logo.svg';
import resetPasswordValidationSchema from '../formGroups/resetPasswordSchema';

function RestPassword(props) {
  const { onResetPassword, passwordResetCode } = props;
  const [password, setPassword] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const [ooobCode, setOoobCode] = useState('');

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm({
    mode: 'all',
    resolver: yupResolver(resetPasswordValidationSchema),
  });

  const { errors, isDirty, isValid } = formState;

  useEffect(async () => {
    const oobCode = await queryParams.get('oobCode');
    setOoobCode(oobCode);
    passwordResetCode(oobCode);
  }, []);

  const onSubmit = e => {
    onResetPassword(e, password, ooobCode);
  };

  return (
    <div className="login-container lg:bg-cover min-h-screen md:bg-inherit">
      <div className="container-fluid text-center py-16">
        <img src={logo} className="h-28 w-24 m-auto" alt="Logo" />
        <h1 className="mt-20 text-7xl font-bold text-body">Welcome</h1>
        <form
          action=""
          onSubmit={e => handleSubmit(onSubmit(e))}
          className="p-5 md:p-0 mt-14 max-w-xl w-full m-auto space-y-5"
        >
          <input
            type="password"
            required
            autoComplete="off"
            className="form-input"
            placeholder="New Password"
            {...register('password', {
              onChange: e => setPassword(e.target.value),
            })}
          />
          {errors.password?.message && (
            <div>
              <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.password?.message}</div>
            </div>
          )}
          <input
            type="password"
            required
            autoComplete="off"
            className="form-input"
            placeholder="Confirm Password"
            {...register('confirmPassword', { onChange: e => setPassword(e.target.value) })}
          />
          {errors.confirmPassword?.message && (
            <div>
              <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.confirmPassword?.message}</div>
            </div>
          )}
          <div className="flex flex-col items-center space-y-6">
            <button type="submit" className="button btn btn-lg mt-10" disabled={!isDirty || !isValid}>
              Reset password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

RestPassword.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
  passwordResetCode: PropTypes.func.isRequired,
};
export default RestPassword;
