/* eslint-disable react/no-unstable-nested-components */
import React, { createRef, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import './sass/App.scss';

// moodules
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import ClearIcon from '@mui/icons-material/Clear';
import classNames from 'classnames/bind';
import Container from '@mui/material/Container';

// screens
import Footer from 'components/footer';
import LoginScreen from './containers/login';
import DashboardScreen from './containers/dashboard';
import MeetingScreen from './containers/meeting';
import ProfileScreen from './containers/profile';
import InvoicesScreen from './containers/invoices';
import RegisterScreen from './containers/register';
import ForgotPasswordScreen from './containers/forgotPassword';
import ResetPasswordScreen from './containers/resetPassword';
import AcceptInvitationScreen from './containers/accept-invitation';
import VideoConferenceScreen from './containers/videoConference';
import Page404 from './components/404';
import styles from './App.css';

function App(props) {
  const { isAuthenticated } = props;
  const cookies = new Cookies();
  const notistackRef = createRef();
  const onClickDismiss = () => {
    notistackRef.current.closeSnackbar();
  };
  const cx = classNames.bind(styles);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const expiration = new Date(Date.now() + 24 * 60 * 60 * 1000);
        user.getIdToken().then(idToken => {
          cookies.set('authToken', idToken, { path: '/', domain: 'greeteat.com', expires: expiration });
        });
      }
    });
  }, []);
  return (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      ref={notistackRef}
      action={() => (
        <div role="button" tabIndex="0" className="closeError point" onClick={onClickDismiss} onKeyDown={() => {}}>
          <ClearIcon />
        </div>
      )}
      maxSnack={5}
    >
      <Container component="main" className={cx('main')}>
        <div className="wrapper">
          <div className="content">
            <Router>
              <Routes>
                <Route path="/" element={!isAuthenticated ? <LoginScreen /> : <Navigate to="/dashboard" />} />
                <Route
                  path="/register"
                  element={!isAuthenticated ? <RegisterScreen /> : <Navigate to="/dashboard" />}
                />
                <Route
                  path="/forgot-password"
                  element={!isAuthenticated ? <ForgotPasswordScreen /> : <Navigate to="/dashboard" />}
                />
                <Route
                  path="/reset-password"
                  element={!isAuthenticated ? <ResetPasswordScreen /> : <Navigate to="/dashboard" />}
                />

                {/* Authenticated Routes */}
                <Route path="/dashboard" element={isAuthenticated ? <DashboardScreen /> : <Navigate to="/" />} />
                <Route path="/createNew" element={isAuthenticated ? <MeetingScreen /> : <Navigate to="/" />} />
                <Route path="/edit/:meetingId" element={isAuthenticated ? <MeetingScreen /> : <Navigate to="/" />} />
                <Route path="/profile" element={isAuthenticated ? <ProfileScreen /> : <Navigate to="/" />} />
                <Route path="/invoices" element={isAuthenticated ? <InvoicesScreen /> : <Navigate to="/" />} />

                <Route path="/meeting/accept-invitation/:meetingId/:token" element={<AcceptInvitationScreen />} />
                <Route path="/vc/meeting/:meetingId/participant/:participantId" element={<VideoConferenceScreen />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Router>
          </div>
        </div>
        <Footer />
      </Container>
    </SnackbarProvider>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.firebase.isAuthenticated,
  };
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(App);
