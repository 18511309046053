/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TimeZonesInput from '../timezone-input';

function TimeZoneCardModal(props) {
  const { title, close, timeZones, callbackFunction, submit, button, timeZone } = props;
  const handleCallback = data => {
    callbackFunction(data);
  };

  useEffect(() => {
    function handleKeypress(e) {
      if (e.key === 'Escape') {
        close();
      }
    }

    document.addEventListener('keydown', handleKeypress);

    return () => {
      document.removeEventListener('keydown', handleKeypress);
    };
  });

  return (
    <div className="absolute h-screen w-full popup flex justify-center top-0 left-0 items-center">
      <div
        className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
        role="button"
        tabIndex="0"
        aria-label="Time Modal"
        onClick={close}
        onKeyDown={() => {}}
      />
      <div className="relative popup-container bg-white px-14 py-11 ">
        <h2 className="font-bold text-body mb-7">{title}</h2>
        <TimeZonesInput title="Timezones" timeZones={timeZones} callbackFunction={handleCallback} timeZone={timeZone} />
        <div className="justify-end flex mt-14 space-x-7">
          <button type="button" className="btn btn-secondary w-40" onClick={close}>
            Cancel
          </button>
          <button type="button" className="btn button w-64" onClick={submit}>
            {button}
          </button>
        </div>
      </div>
    </div>
  );
}
TimeZoneCardModal.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func,
  callbackFunction: PropTypes.func,
  submit: PropTypes.func,
  button: PropTypes.string,
};

TimeZoneCardModal.defaultProps = {
  close: () => {},
  callbackFunction: () => {},
  submit: () => {},
  title: '',
  button: '',
};
export default TimeZoneCardModal;
