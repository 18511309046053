import {
  GET_SINGLE_MEETING_REQUEST,
  GET_SINGLE_MEETINGS_SUCCESS,
  GET_SINGLE_MEETINGS_FAILURE,
} from '../../constants/actionTypes';

export default (
  state = {
    meeting: {},
    loading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case GET_SINGLE_MEETING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SINGLE_MEETINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        meeting: action.meeting,
      };
    case GET_SINGLE_MEETINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
        meeting: {},
      };
    default:
      return state;
  }
};
