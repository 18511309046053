import {
  SEND_GUESTS_INVITATIONS_REQUEST,
  GUESTS_INVITATIONS_SUCCESS_RESPONSE,
  GUESTS_INVITATIONS_FAILURE,
} from '../../constants/actionTypes';

export default (
  state = {
    message: null,
    loading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case SEND_GUESTS_INVITATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
      };
    case GUESTS_INVITATIONS_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.message,
        error: null,
      };
    case GUESTS_INVITATIONS_FAILURE:
      return {
        ...state,
        message: null,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
