import axios from 'axios';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { Firebase, API_URL } from '../firebase/firebase';

axios.interceptors.request.use(async function (config) {
  const token = await Firebase.auth().currentUser.getIdToken();
  config.headers.Authorization = `Bearer ${token}`;
  config.baseURL = API_URL;
  return config;
});

const uninterceptedAxiosInstance = axios.create();
uninterceptedAxiosInstance.interceptors.request.use(async function (config) {
  config.baseURL = API_URL;
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error &&
      error?.response &&
      error?.response.status &&
      (error?.response.status === 404 || error?.response.status === 500)
    ) {
      Sentry.captureMessage(error?.response.statusText);
    }
    return Promise.reject(error?.response);
  },
);

// MEETINGS APIs
export const createMeetingApi = data => {
  return axios.post('api/meetings', data);
};
export const editMeetingApi = data => {
  return axios.put('api/meetings', data);
};
export const deleteMeetingApi = meetingId => {
  return axios.delete(`api/meetings/${meetingId}`);
};
export const startMeeting = meetingId => {
  return axios.post(`api/meetings/start-meeting/${meetingId}`);
};

// STRIP APIs
export const addStripUserApi = data => {
  return axios.post(`api/stripe?name=${data && data?.FullName ? data?.FullName : data?.displayName ?? ''}`);
};
export const addPaymentCardApi = data => {
  return axios.post('api/stripe/card', data);
};
export const getPaymentCardApi = () => {
  return axios.get('api/stripe/card');
};
export const getSetupIntentApi = () => {
  return axios.get('api/stripe/setup-intent');
};
export const deletePaymentCardApi = id => {
  return axios.delete(`api/stripe/card/${id}`);
};

// INVOICE APIs
export const downloadInvoiceApi = (invoice, fileType) => {
  let queryParams = {};
  if (invoice.isLastRowOfMonth) {
    if (moment(invoice.startDate).isSame(invoice.endDate)) {
      // eslint-disable-next-line prefer-template
      queryParams = { filterByMonth: invoice.month + '-' + invoice.year };
    } else {
      queryParams.startDate = invoice.startDate;
      queryParams.endDate = invoice.endDate;
    }
  } else {
    queryParams = { id: invoice.Id };
  }
  queryParams.type = fileType;
  return axios.get('api/invoice/download', { params: queryParams });
};

export const sendInvoiceApi = (invoice, emails) => {
  let queryParams = {};
  if (invoice.isLastRowOfMonth) {
    if (moment(invoice.startDate).isSame(invoice.endDate)) {
      // eslint-disable-next-line prefer-template
      queryParams = { filterByMonth: invoice.month + '-' + invoice.year, type: invoice.type };
    } else {
      queryParams.startDate = invoice.startDate;
      queryParams.endDate = invoice.endDate;
      queryParams.type = invoice.type;
    }
  } else {
    queryParams = { id: invoice.Id, type: invoice.type };
  }
  return axios.post('api/invoice/email', emails, { params: queryParams });
};

// PROFILE APIs
export const updateProfile = data => {
  return axios.put('api/profile', data);
};

// SEND GUESTS-INVITATION API
export const sendGuestsInvitation = data => {
  return axios.post('api/meetings/guests-invitation', data);
};

// ACCEPT-INVITATION API
export const acceptMeetingInvitation = (meetingId, token) => {
  return uninterceptedAxiosInstance.put(`api/accept-invitation/${meetingId}/${token}`);
};

// GENERATE VIDEO CONFERENCE DATA
export const generateVideoConferenceData = (meetingId, participantId) => {
  return uninterceptedAxiosInstance.get(`api/token/meeting/${meetingId}/participant/${participantId}`);
};

// DELETE USER ACCOUNT API
export const deleteAccount = () => {
  return axios.delete('api/profile');
};

// GET USER

export const getUserAccount = () => {
  return axios.get('api/profile/user');
};
