import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/loader';

// components
import RestPassword from 'components/resetPassword';

// services
import { verifyPasswordResetCodeHandler, confirmPasswordResetHandler } from '../../services/firebase.service';

function ResetPasswordScreen(props) {
  const { loading } = props;
  const navigate = useNavigate();

  const passwordResetCode = async oobCode => {
    verifyPasswordResetCodeHandler(oobCode);
  };

  const onResetPassword = (e, password, oobCode) => {
    e.preventDefault();
    confirmPasswordResetHandler(oobCode, password, navigate);
  };
  // loading
  if (loading) return <Loader visible />;

  return (
    <RestPassword
      passwordResetCode={oobCode => passwordResetCode(oobCode)}
      onResetPassword={(e, password, oobCode) => onResetPassword(e, password, oobCode)}
    />
  );
}

ResetPasswordScreen.propTypes = {
  loading: PropTypes.bool,
};

ResetPasswordScreen.defaultProps = {
  loading: false,
};

const mapStateToProps = state => {
  return {
    loading: state.forgotPassword.loading,
    error: state.firebase.error,
  };
};

export default connect(mapStateToProps)(ResetPasswordScreen);
