import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import configureStore from './redux/store';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  logLevel: process.env.REACT_APP_SENTRY_LOG_LEVEL,
  debug: true,
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

ReactDOM.render(
  <Provider store={configureStore()}>
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <Sentry.ErrorBoundary>
          <App />
        </Sentry.ErrorBoundary>
      </Elements>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);
