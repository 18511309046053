/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './index.css';
import linkedin from '../../assets/images/footer/social-linkedin.svg';
import instagram from '../../assets/images/footer/social-instagram.svg';
import tiktok from '../../assets/images/footer/social-tiktok.svg';
import twitter from '../../assets/images/footer/social-new-twitter.svg';
import facebook from '../../assets/images/footer/social-facebook.svg';
import logo from '../../assets/full-logo.png';

function Footer() {
  const changePath = _route => {
    window.location.href = _route;
  };

  return (
    // <div className="footer">
    //   <p className="text-center f-14">© 2023 GreetEat LLC. All rights reserved</p>
    //   <p className="text-center f-14">
    //     <span>v{process.env.REACT_APP_VERSION}</span>
    //     <span>{process.env.REACT_APP_ENV !== 'prod' && `-${process.env.REACT_APP_ENV}`}</span>
    //   </p>
    // </div>
    <div className="footer">
      <div className="footer-back">
        <div className="footer-container">
          <div className="footer-top">
            <div
              className="footer-logo point"
              role="presentation"
              onKeyDown={() => changePath('/dashboard')}
              onClick={() => changePath('/dashboard')}
            >
              <img
                src={logo}
                loading="eager"
                height="202"
                width="202"
                alt="GreetEat Icon"
                className="footer-logo-sign"
              />
            </div>
            <div />
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/greeteat"
                target="_blank"
                className="social-link w-inline-block"
              >
                <img src={linkedin} loading="lazy" width="40" height="40" alt="GreetEat LinkedIn" />
              </a>
              <a href="https://www.instagram.com/greeteat_/" target="_blank" className="social-link w-inline-block">
                <img src={instagram} loading="lazy" width="40" height="40" alt="GreetEat Instagram" />
              </a>
              <a href="https://www.facebook.com/GreetEatApp" target="_blank" className="social-link w-inline-block">
                <img src={facebook} loading="lazy" width="40" height="40" alt="GreetEat Facebook" />
              </a>
              <a
                href="https://www.tiktok.com/@greeteat?_d=secCgYIASAHKAESPgo8DABILgJycA%2F3F75mAuDetiF4jNF5fd%2FjJUVj14RrvxjW66wBEZA1mXLHIhKpa8fxrVHmex1f1iDxxTc%2BGgA%3D&amp;language=en&amp;sec_uid=MS4wLjABAAAA_AyFsTHj89rzyuhY45r7fPN_RPohLNeXQDn-LR4h1brGBvOtCSoPLAoae7QHr7jI&amp;sec_user_id=MS4wLjABAAAA_AyFsTHj89rzyuhY45r7fPN_RPohLNeXQDn-LR4h1brGBvOtCSoPLAoae7QHr7jI&amp;share_app_id=1233&amp;share_author_id=7000441914873316357&amp;share_link_id=BC19DDE6-4D70-48F7-A60E-49CFE26B1231&amp;tt_from=copy&amp;u_code=dka3dbjj5ecak4&amp;user_id=7000441914873316357&amp;utm_campaign=client_share&amp;utm_medium=ios&amp;utm_source=copy&amp;source=h5_m&amp;_r=1"
                target="_blank"
                className="social-link w-inline-block"
              >
                <img src={tiktok} loading="lazy" width="40" height="40" alt="GreetEat TikTok" />
              </a>
              <a href="https://twitter.com/greeteats" target="_blank" className="social-link w-inline-block">
                <img src={twitter} loading="lazy" width="40" height="40" alt="GreetEat Twitter" />
              </a>
            </div>
          </div>
          <div className="footer-back smaller" />
          <div className="footer-bottom">
            <div className="footer-menu">
              <a href="https://greeteat.com/privacy-policy.html" className="tiny-txt link">
                Privacy Policy
              </a>
              <div className="tiny-txt px-2"> | </div>
              <a href="https://greeteat.com/term-conditions.html" className="tiny-txt link">
                Terms &amp; Conditions
              </a>
            </div>
            <div>
              <p className="text-center tiny-txt">
                <span>v{process.env.REACT_APP_VERSION}</span>
                <span>{process.env.REACT_APP_ENV !== 'prod' && `-${process.env.REACT_APP_ENV}`}</span>
              </p>
            </div>
            <div className="tiny-txt copyright">© 2023 GreetEat LLC. All Rights Reserved.</div>
          </div>
          <div className="footer-back smaller final" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
