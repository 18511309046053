import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS_RESPONSE,
  CREATE_USER_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS_RESPONSE,
  RESET_PASSWORD_FAILURE,
  SET_GOOGLE_CONTACTS,
  SET_GOOGLE_CONTACTS_ERROR,
  GET_LOGGIN_USER_REQUEST,
  GET_LOGGIN_USER_SUCCESS_RESPONSE,
  CLEAR_ERROR_MESSAGE,
  GET_LOGGIN_USER_FAILURE,
} from '../constants/actionTypes';

// login handler

export const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

export const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user: user,
  };
};

export const loginError = message => {
  return {
    type: LOGIN_FAILURE,
    message: message,
  };
};

// logout handler

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

export const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

// create user handler

export const requestCreateUser = () => {
  return {
    type: CREATE_USER_REQUEST,
  };
};

export const createUserSuccessfully = message => {
  return {
    type: CREATE_USER_SUCCESS_RESPONSE,
    data: message,
  };
};

export const createUserError = message => {
  return {
    type: CREATE_USER_FAILURE,
    message: message,
  };
};

// password change handler

export const requestChangePassword = () => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
  };
};
export const changePasswordSuccessfully = () => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
};
export const changePasswordError = message => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    message: message,
  };
};

// reset password handler

export const requestResetPasswrod = () => {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
};
export const resetPasswordSuccessfully = message => {
  return {
    type: RESET_PASSWORD_SUCCESS_RESPONSE,
    message: message,
  };
};
export const resetPasswordError = message => {
  return {
    type: RESET_PASSWORD_FAILURE,
    message: message,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERROR_MESSAGE,
  };
};

// retrive google contact handler

export const setGoogleContacts = googleContacts => {
  return {
    type: SET_GOOGLE_CONTACTS,
    contacts: googleContacts,
  };
};

export const setGoogleContactsError = message => {
  return {
    type: SET_GOOGLE_CONTACTS_ERROR,
    message: message,
  };
};

// get current user handler

export const getLoggingUserRequest = () => {
  return {
    type: GET_LOGGIN_USER_REQUEST,
  };
};

export const getLoggingUserSuccessfully = user => {
  return {
    type: GET_LOGGIN_USER_SUCCESS_RESPONSE,
    user: user,
  };
};

export const getLoggingUserError = message => {
  return {
    type: GET_LOGGIN_USER_FAILURE,
    message: message,
  };
};
