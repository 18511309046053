import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Loader from 'components/loader';

// components
import Profile from 'components/profile';

// services
import { getPaymentCard, deletePaymentCard, setUpIntent } from '../../services/stripe.service';
import { changeUserPassword, getUserIdToken, getCurrentUserProfile } from '../../services/firebase.service';
import { updateUserProfile, deleteUserAccount } from '../../services/profile.service';

function ProfileScreen(props) {
  const navigate = useNavigate();
  const { dispatch, error, loading, paymentCards, _setUpIntent, user, currentUser } = props;

  // get paymentCards
  const onGetPaymentCard = () => {
    getPaymentCard(dispatch);
  };

  // delete paymentCard
  const onDeletePaymentCard = id => {
    deletePaymentCard(dispatch, id);
  };

  // change password
  const profilePasswordChanage = (oldPassword, newPassword) => {
    changeUserPassword(dispatch, oldPassword, newPassword, navigate);
  };

  // update profile
  const profileUpdate = data => {
    updateUserProfile(dispatch, data, navigate);
  };

  // link google account
  const linkGoogleUser = async () => {
    const token = await getUserIdToken();
    return token;
  };

  // get user profile
  const getUserProfile = async () => {
    getCurrentUserProfile(dispatch);
  };

  const _deleteUserAccount = async () => {
    deleteUserAccount(dispatch, navigate);
  };

  useEffect(async () => {
    await setUpIntent(dispatch);
    await onGetPaymentCard();
    await getUserProfile();
  }, []);

  // loading
  // if (loading) return <Loader visible />;

  return (
    <Profile
      error={error}
      loading={loading}
      paymentCards={paymentCards}
      setupIntent={_setUpIntent}
      deletePaymentCard={id => onDeletePaymentCard(id)}
      getPaymentCard={() => onGetPaymentCard()}
      profilePasswordChanage={(oldPassword, newPassword) => profilePasswordChanage(oldPassword, newPassword)}
      user={user}
      profileUpdate={data => profileUpdate(data)}
      deleteUserAccount={() => _deleteUserAccount()}
      linkGoogleUser={() => linkGoogleUser()}
      currentUser={currentUser}
    />
  );
}

ProfileScreen.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.string,
  paymentCards: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  _setUpIntent: PropTypes.oneOfType([PropTypes.object]),
  user: PropTypes.oneOfType([PropTypes.object]),
  currentUser: PropTypes.oneOfType([PropTypes.object]),
};

ProfileScreen.defaultProps = {
  error: '',
  dispatch: () => {},
  loading: false,
  paymentCards: [],
  _setUpIntent: {},
  user: {},
  currentUser: {},
};

const mapStateToProps = state => {
  return {
    loading:
      state.getPaymentCard.loading ||
      state.deletePaymentCard.loading ||
      state.changePassword.loading ||
      state.profile.loading,
    error: state.getPaymentCard.error,
    paymentCards: state.getPaymentCard.message?.data,
    _setUpIntent: state.setupIntent.message,
    user: state.firebase.user,
    currentUser: state.firebase.logginUser,
  };
};

export default connect(mapStateToProps)(ProfileScreen);
