import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from 'components/loader';
import { getCurrentUserProfile } from 'services/firebase.service';
import { getPaymentCard, setUpIntent } from '../../services/stripe.service';
import { getMeeting, createMeeting, editMeeting } from '../../services/meetings.service';
import CreateNew from '../../components/meeting/create-new';

function MeetingScreen(props) {
  const {
    user,
    meeting,
    createMeetingError,
    createMeetingLoading,
    dispatch,
    loading,
    googleContacts,
    editMeetingError,
    currentUser,
    isPaymentCard,
    _setUpIntent,
  } = props;
  const { meetingId } = useParams();
  const navigate = useNavigate();

  const getUserProfile = async () => {
    getCurrentUserProfile(dispatch);
  };

  const onGetPaymentCard = () => {
    getPaymentCard(dispatch);
  };

  useEffect(async () => {
    if (meetingId) getMeeting(dispatch, meetingId);

    getUserProfile();
    onGetPaymentCard();
    if (!isPaymentCard) {
      await setUpIntent(dispatch);
    }
  }, []);

  const createNewMeeting = data => {
    createMeeting(dispatch, data, navigate);
  };

  const editExistingMeeting = data => {
    editMeeting(dispatch, data, navigate);
  };

  // loading
  if (loading) return <Loader visible />;

  return (
    <CreateNew
      user={user}
      meeting={meeting}
      isExistingMeet={meetingId != null}
      createMeeting={data => createNewMeeting(data)}
      editMeeting={data => editExistingMeeting(data)}
      getPaymentCard={() => onGetPaymentCard()}
      createMeetingError={createMeetingError}
      createMeetingLoading={createMeetingLoading}
      googleContacts={googleContacts}
      editMeetingError={editMeetingError}
      currentUser={currentUser}
      isPaymentCard={isPaymentCard}
      setupIntent={_setUpIntent}
      dispatch={dispatch}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.firebase.user,
    createMeetingLoading: state.createMeeting.loading || state.editMeeting.loading,
    meeting: state.meeting.meeting,
    createMeetingError: state.createMeeting.error,
    editMeetingError: state.editMeeting.error,
    loading: state.meeting.loading || state.createMeeting.loading || state.editMeeting.loading,
    googleContacts: state.firebase.contacts,
    currentUser: state.firebase.logginUser,
    isPaymentCard: state.getPaymentCard?.message?.data.length > 0,
    _setUpIntent: state.setupIntent.message,
  };
}

MeetingScreen.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  createMeetingLoading: PropTypes.bool,
  createMeetingError: PropTypes.string,
  meeting: PropTypes.oneOfType([PropTypes.object]),
  googleContacts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  editMeetingError: PropTypes.string,
  currentUser: PropTypes.oneOfType([PropTypes.object]),
  isPaymentCard: PropTypes.bool,
  _setUpIntent: PropTypes.oneOfType([PropTypes.object]),
};

MeetingScreen.defaultProps = {
  user: {},
  meeting: {},
  createMeetingError: '',
  createMeetingLoading: false,
  dispatch: () => {},
  loading: false,
  googleContacts: [],
  editMeetingError: '',
  currentUser: {},
  isPaymentCard: false,
  _setUpIntent: {},
};

export default connect(mapStateToProps)(MeetingScreen);
