import {
  DELETE_MEETINGS_REQUEST,
  DELETE_MEETINGS_SUCCESS_RESPONSE,
  DELETE_MEETINGS_FAILURE,
  RESET_MEETING_ERROR,
} from '../../constants/actionTypes';

export default (
  state = {
    message: '',
    loading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case DELETE_MEETINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_MEETINGS_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        message: 'Meeting Was Deleted',
      };
    case DELETE_MEETINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RESET_MEETING_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
