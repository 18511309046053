import {
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS_RESPONSE,
  UPDATE_PROFILE_FAILURE,
} from '../constants/actionTypes';

export default (
  state = {
    message: null,
    loading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        message: null,
        loading: true,
        error: null,
      };
    case UPDATE_PROFILE_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.message,
        error: null,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
