import { SERVER_ERROR } from '../redux/constants/messages';
import { updateProfile, deleteAccount } from './api.service';
import {
  requestUpdateProfile,
  updateProfileSuccessResponse,
  updateProfileFailure,
  requestDeleteAccount,
  deleteAccountSuccessResponse,
  deleteAccountFailure,
} from '../redux/actions/profile';
import { getCurrentUserProfile } from './firebase.service';
import { Firebase } from '../firebase/firebase';

export const updateUserProfile = (dispatch, data, navigate) => {
  dispatch(requestUpdateProfile());

  updateProfile(data).then(
    result => {
      dispatch(updateProfileSuccessResponse(result.data.message));
      getCurrentUserProfile(dispatch);
      navigate('/profile');
    },
    () => {
      dispatch(updateProfileFailure(SERVER_ERROR));
    },
  );
};

export const deleteUserAccount = (dispatch, navigate) => {
  dispatch(requestDeleteAccount());
  const user = Firebase.auth().currentUser;
  deleteAccount().then(
    result => {
      if (user) {
        user.delete();
      }
      dispatch(deleteAccountSuccessResponse(result.data.message));
      navigate('/');
    },
    () => {
      dispatch(deleteAccountFailure(SERVER_ERROR));
    },
  );
};

export const extractFields = addressComponents => {
  const address = {
    addressLine1: '',
    addressLine2: '',
    postal_code: '',
    city: '',
    country: '',
    state: '',
  };
  if (addressComponents && addressComponents.length) {
    for (const component of addressComponents) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address.addressLine1 = `${component.long_name} ${address.addressLine1}`;
          break;
        }

        case 'route': {
          address.addressLine1 += component.short_name;
          break;
        }

        case 'postal_code': {
          address.postal_code = component.short_name;
          break;
        }

        case 'locality': {
          address.city = component.long_name;
          break;
        }

        case 'administrative_area_level_1': {
          address.state = component.long_name;
          break;
        }

        case 'country': {
          address.country = component.long_name;
          break;
        }
      }
    }
  }
  return address;
};
