import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS_RESPONSE,
  CREATE_USER_FAILURE,
  GET_LOGGIN_USER_REQUEST,
  GET_LOGGIN_USER_SUCCESS_RESPONSE,
  GET_LOGGIN_USER_FAILURE,
} from '../constants/actionTypes';

export default (
  state = {
    loading: false,
    error: false,
    isAuthenticated: false,
    user: {},
    createUserError: {},
    resetPasswordError: {},
    logginUser: {},
  },
  action,
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.message,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: {},
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: false,
      };
    case CREATE_USER_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        message: action.message,
        user: action.data.user,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        createUserError: action.message,
      };

    case GET_LOGGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        logginUser: {},
      };
    case GET_LOGGIN_USER_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        logginUser: action.user,
      };
    case GET_LOGGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.message,
      };
    // case SET_GOOGLE_CONTACTS:
    //   return {
    //     ...state,
    //     loading: false,
    //     isAuthenticated: true,
    //     contacts: action.contacts,
    //   };
    // case SET_GOOGLE_CONTACTS_ERROR:
    //   return {
    //     ...state,
    //     loading: false,
    //     isAuthenticated: false,
    //     message: action.message,
    //   };
    default:
      return state;
  }
};
