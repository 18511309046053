/* eslint-disable react/prop-types */
import React from 'react';
import '../index.scss';
import { getRandomColor, convertTimeStampToDate, getThumbnail } from '../../../services/util.service';
import Menu from '../../menu/Menu';

function InvoicesRow(props) {
  const { item, downloadInvoice, setInvoiceModal, printReceipt } = props;
  const firebaseDate = convertTimeStampToDate(item.StartTime, item.Duration);
  const participantist = item.Participants?.map((participantInner, participantIndex) => (
    <div key={participantIndex}>
      <div
        className="items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white text-center font-bold -ml-2"
        style={{
          background: getRandomColor(),
        }}
      >
        {getThumbnail(participantInner)}
      </div>
    </div>
  ));

  return (
    <tr>
      <td className="font-bold text-lg p-0 relative">
        <div className="px-7 py-3 flex flex-col">
          {firebaseDate.date}
          <span className=" text-sm font-normal">
            {firebaseDate.startTime} - {firebaseDate.endTime}
          </span>
        </div>
      </td>
      <td className="text-sm font-normal">{item.TopicName}</td>
      <td className="text-body">
        <div className="flex items-center ml-2">
          {participantist}
          {/* {item.Participants.length >= 4 && <span className="font-bold ml-2 text-body text-opacity-30">+ 1</span>} */}
        </div>
      </td>
      <td className="font-bold text-lg py-3 flex-col flex">
        {item.VoucherPrice ? `$${item.VoucherPrice}` : 0} / per person
        {/* <span className=" text-sm font-normal">{item.amount} for lunch</span> */}
      </td>
      <td className="relative">
        {item.IsDisabled ? (
          <p className="text-sm font-normal">Invoice will be available 24 hrs after the meeting.</p>
        ) : (
          <div className="flex space-x-5 relative">
            <Menu>
              <button
                type="button"
                className="items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white text-center bg-primary"
              >
                <i className="far fa-arrow-down-to-bracket text-lg" />
              </button>
              <div className="space-y-6">
                <button
                  type="button"
                  className="text-sm font-bold flex items-center space-x-5"
                  onClick={() => downloadInvoice(item, 'pdf')}
                >
                  <i className="fas fa-file-pdf text-xl" />
                  <div>Download as PDF</div>
                </button>
                <button
                  type="button"
                  className="text-sm font-bold flex items-center space-x-5"
                  onClick={() => downloadInvoice(item, 'xls')}
                >
                  <i className="fa fa-file-excel-o text-xl" />
                  <div>Download as CSV</div>
                </button>
              </div>
            </Menu>
            <button
              type="button"
              className="items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white  text-center bg-gray-700"
              onClick={() => setInvoiceModal(item, true)}
            >
              <i className="far fa-envelope text-lg" />
            </button>
            <button
              type="button"
              className="items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white text-center bg-teal"
              onClick={() => printReceipt(item)}
            >
              <i className="far fa-print text-lg" />
            </button>
          </div>
        )}
      </td>
    </tr>
  );
}

export default InvoicesRow;
