import {
  CREATE_MEETINGS_REQUEST,
  CREATE_MEETINGS_SUCCESS_RESPONSE,
  CREATE_MEETINGS_FAILURE,
  RESET_MEETING_ERROR,
} from '../../constants/actionTypes';

export default (
  state = {
    message: null,
    loading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case CREATE_MEETINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
      };
    case CREATE_MEETINGS_SUCCESS_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.message,
        error: null,
      };
    case CREATE_MEETINGS_FAILURE:
      return {
        ...state,
        message: null,
        loading: false,
        error: action.error,
      };
    case RESET_MEETING_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
