/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Loader from 'components/loader';
import Toast from 'components/toast';
import PropTypes from 'prop-types';
import { clearErrorMessage } from 'services/firebase.service';
import logo from '../../assets/logo.svg';

function ForgotPassword(props) {
  const { loading, sendPasswordReset, error, dispatch } = props;
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        clearErrorMessage(dispatch);
      }, 5000);
    }
  });

  return (
    <div className="login-container lg:bg-cover min-h-screen md:bg-inherit">
      {loading && <Loader visible />}
      {error && <Toast visible message={error} />}
      <div className="container-fluid text-center py-16">
        <img src={logo} className="h-28 w-24 m-auto" alt="Logo" />
        <h1 className="mt-20 text-7xl font-bold text-body">Welcome</h1>
        <form
          action=""
          onSubmit={e => sendPasswordReset(e, email)}
          className="p-5 md:p-0 mt-14 max-w-xl w-full m-auto space-y-5"
        >
          <div className="flex">
            <input
              type="text"
              required
              name="email"
              id="email"
              autoComplete="off"
              className="form-input order-1"
              placeholder="email:"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          {/* {error && <div className="bg-red-500 rounded-sm p-2 font-bold text-white mt-6">{error}</div>} */}
          <div className="flex flex-col items-center space-y-6">
            <button type="submit" className="button btn btn-md mt-10">
              Send Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  sendPasswordReset: PropTypes.func,
};

ForgotPassword.defaultProps = {
  error: '',
  loading: false,
  sendPasswordReset: () => {},
};
export default ForgotPassword;
