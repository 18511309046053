/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tick from '../../../assets/tick.svg';

const getThumbnail = name => {
  const fullName = name.split(' ');
  const initials = fullName.shift()?.charAt(0);
  return initials.toUpperCase();
};

export default function Participants(props) {
  const { isListView, list } = props;
  const [showAllParticipant, setShowAllParticipant] = useState(false);
  const displayData = showAllParticipant ? list : list.slice(0, 2);
  if (isListView) {
    return (
      <>
        {displayData.map((data, index) => (
          <div className="items-center" key={index}>
            {index < 3 && (
              <div
                className={`items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white text-[10px] text-center ${
                  index === 0 ? 'bg-yellow-800' : 'bg-blue-800'
                }`}
              >
                {getThumbnail(data)}
              </div>
            )}
          </div>
        ))}
        {displayData.length > 3 && (
          <div className="items-center flex justify-center rounded-[92px] rounded-bl-none h-8 w-8 -mr-0.5 text-white text-[12px] text-center bg-yellow-800">
            + {displayData.length - 3}
          </div>
        )}
      </>
    );
  }
  return (
    <div style={{ height: showAllParticipant ? 'auto' : '95px', overflow: 'hidden' }}>
      {displayData?.map((data, index) => (
        <div key={index} className="participants-row">
          <div className="flex items-center  space-x-2">
            <p className="bg-[#FBC734] rounded-full h-8 w-8 text-[10px] text-center flex items-center justify-center rounded-bl-none text-white">
              {getThumbnail(data)}
            </p>
            <h6 className="text-[10px] text-body text-opacity-60">{data}</h6>
          </div>
          <p className="items-center flex mr-1">
            <img src={Tick} alt="" className="h-5 w-5" />
          </p>
        </div>
      ))}
      <div className="text-center flex items-center justify-center text-[12px]">
        {list.length > 2 &&
          (!showAllParticipant ? (
            <button type="button" onClick={() => setShowAllParticipant(true)}>
              View More Participant
            </button>
          ) : (
            <button type="button" onClick={() => setShowAllParticipant(false)}>
              View Less
            </button>
          ))}
      </div>
    </div>
  );
}

Participants.propTypes = {
  isListView: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array,
};

Participants.defaultProps = {
  isListView: false,
  list: [],
};
