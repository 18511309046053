import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function ConfirmTransactionModal(props) {
  const { title, submitButton, cancelButton, close, submit, participant } = props;
  useEffect(() => {
    function handleKeypress(e) {
      if (e.key === 'Escape') {
        close();
      }
    }

    document.addEventListener('keydown', handleKeypress);

    return () => {
      document.removeEventListener('keydown', handleKeypress);
    };
  }, []);

  return (
    <div className="fixed h-screen w-full popup flex justify-center top-0 left-0 items-center z-[999]">
      <div
        role="button"
        tabIndex="0"
        aria-label="Close Modal"
        className="bg-white bg-opacity-70 w-full h-full absolute left-0 top-0 backdrop-blur-sm"
        onKeyDown={() => {}}
        onClick={close}
      />
      <div className="relative popup-container">
        <h2 className="font-bold text-body mb-7">{title}</h2>
        <div style={{ overflowX: 'auto', width: '99%' }}>
          <table className="grid-meeting table-responsive">
            <thead>
              <tr className="text-[13px]">
                <th className="bg-[#FFE1C2] font-normal rounded-tl-[20px] p-4">Date/Time</th>
                <th className="bg-[#FFE1C2] font-normal">Participants</th>
                <th className="bg-[#FFE1C2] font-normal">Subtotal</th>
                <th className="bg-[#FFE1C2] font-normal">Service Fee(20%)</th>
                <th className="bg-[#FFE1C2] font-normal">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="grid-meeting__time">
                  {participant.date}
                  <br />
                  {participant.time}
                </td>
                <td className="grid-meeting__time" style={{ fontSize: '13px' }}>
                  {participant.participant}
                </td>
                <td className="text-body text-center text-base">
                  <b>{participant.participant}</b> Participants x{' '}
                  <b>{`$${Number(participant.voucherPrice).toFixed(2)}`}</b> ={' '}
                  <b>{`$${(participant.participant * participant.voucherPrice).toFixed(2)}`}</b>
                </td>
                <td className="text-body text-center text-base">
                  {`$${((participant.serviceFee / 100) * (participant.participant * participant.voucherPrice)).toFixed(
                    2,
                  )}`}
                </td>
                <td className="text-body text-center text-base">
                  {`$${(
                    (participant.serviceFee / 100) * (participant.participant * participant.voucherPrice) +
                    participant.participant * participant.voucherPrice
                  ).toFixed(2)}`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
          <button type="button" className="btn btn-secondary w-60 w-sm-60" onClick={close}>
            {cancelButton}
          </button>
          <button type="button" className="btn button w-64 w-sm-60" onClick={e => submit(e)}>
            {submitButton}
          </button>
        </div>
      </div>
    </div>
  );
}

ConfirmTransactionModal.propTypes = {
  title: PropTypes.string.isRequired,
  submitButton: PropTypes.string.isRequired,
  cancelButton: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  participant: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default ConfirmTransactionModal;
