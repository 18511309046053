import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import VideoConference from 'components/videoConference';
import { initVideoConference, joinToMeeting } from '../../services/meetings.service';

function VideoConferenceScreen(props) {
  const { dispatch, data } = props;
  const { meetingId, participantId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicName = searchParams.get('topic');

  const joinMeeting = () => {
    joinToMeeting(dispatch, meetingId);
  };

  useEffect(() => {
    if (meetingId && participantId) {
      initVideoConference(dispatch, meetingId, participantId);
    }
  }, []);
  if (data && data.Roomname && data.Token) {
    return (
      <VideoConference
        roomName={data.Roomname}
        jwt={data.Token}
        topicName={topicName}
        startMeeting={() => joinMeeting()}
      />
    );
  } else {
    return <div />;
  }
}

VideoConferenceScreen.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  dispatch: PropTypes.func,
};

VideoConferenceScreen.defaultProps = {
  data: { Roomname: null, Token: null },
  dispatch: () => {},
};

const mapStateToProps = state => {
  return {
    data: state.videoConference.data,
  };
};

export default connect(mapStateToProps)(VideoConferenceScreen);
