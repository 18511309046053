import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

// components
// import Loader from 'components/loader';
import { getCurrentUserProfileById } from 'services/firebase.service';
import Dashboard from '../../components/dashboard/dashboard';

// services
import { getMeetings, getAllParticipants } from '../../services/meetings.service';

function DashboardScreen(props) {
  const { user, meetings, dispatch, participantsHistory, loading, currentUser } = props;
  const uid = user?.uid;

  useEffect(async () => {
    if (uid) getMeetings(dispatch, uid);
    getAllParticipants(dispatch, uid, null);
    setTimeout(() => {
      if (uid) getCurrentUserProfileById(dispatch);
    }, 1000);
  }, [user]);

  const getFilterMeetings = meetingDate => {
    const startDate = moment(meetingDate[0], 'DD-MM-YYYY').startOf('day').toDate();
    const endDate = moment(meetingDate[1], 'DD-MM-YYYY').endOf('day').toDate();
    getMeetings(dispatch, uid, startDate, endDate);
  };

  const clearFilterMeeting = () => {
    if (uid) getMeetings(dispatch, uid);
  };

  // if (loading) return <Loader visible />;

  return (
    <Dashboard
      loading={loading}
      user={user}
      meetings={meetings}
      participantsHistory={participantsHistory}
      getFilterMeetings={date => {
        getFilterMeetings(date);
      }}
      clearFilterMeeting={clearFilterMeeting}
      currentUser={currentUser}
    />
  );
}

// loading

DashboardScreen.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.object]),
  meetings: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  participantsHistory: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  currentUser: PropTypes.oneOfType([PropTypes.object]),
};

DashboardScreen.defaultProps = {
  user: {},
  dispatch: () => {},
  meetings: [],
  participantsHistory: [],
  loading: false,
  currentUser: {},
};

function mapStateToProps(state) {
  return {
    user: state.firebase.user,
    loading:
      state.meetings.loading ||
      state.participantHistory.loading ||
      state.getPaymentCard.loading ||
      state.deleteMeeting.loading,
    meetings: state.meetings.meetings,
    participantsHistory: state.participantHistory.participants,
    currentUser:
      state.firebase && state.firebase.logginUser && state.firebase.logginUser.data
        ? state.firebase.logginUser.data
        : state.firebase.logginUser,
  };
}

export default connect(mapStateToProps)(DashboardScreen);
