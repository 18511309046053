import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import changePasswordValidationSchema from '../../formGroups/changePasswordSchema';

function Password(props) {
  const { profilePasswordChanage } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm({
    mode: 'all',
    resolver: yupResolver(changePasswordValidationSchema),
  });
  const { errors, isDirty, isValid } = formState;

  return (
    <form
      action=""
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(profilePasswordChanage(oldPassword, password));
      }}
      className="w-full text-body placeholder-body h-full flex flex-col justify-between"
    >
      <div className="flex flex-col space-y-6" style={{ height: '50vh' }}>
        <input
          type="password"
          className="form-input"
          placeholder="Current Password"
          {...register('oldPassword', { onChange: e => setOldPassword(e.target.value) })}
        />
        {errors.oldPassword?.message && (
          <div>
            <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.oldPassword?.message}</div>
          </div>
        )}
        <input
          type="password"
          className="form-input"
          placeholder="New Password"
          {...register('password', { onChange: e => setPassword(e.target.value) })}
        />
        {errors.password?.message && (
          <div>
            <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.password?.message}</div>
          </div>
        )}
        <input
          type="password"
          required
          autoComplete="off"
          className="form-input"
          placeholder="Repeat New Password"
          {...register('confirmPassword', { onChange: e => setPassword(e.target.value) })}
        />
        {errors.confirmPassword?.message && (
          <div>
            <div className="-mt-2 ml-6 text-red-600 font-semibold">{errors.confirmPassword?.message}</div>
          </div>
        )}
        <div className="justify-end flex">
          <button
            type="submit"
            className={!isDirty || !isValid ? 'btn btn-secondary w-full' : 'btn button w-64'}
            disabled={!isDirty || !isValid}
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
}

Password.propTypes = {
  profilePasswordChanage: PropTypes.func.isRequired,
};
export default Password;
