import { GET_INVOICES_REQUEST, GET_INVOICES_SUCCESS, GET_INVOICES_FAILURE } from '../../constants/actionTypes';

export default (
  state = {
    loading: false,
    error: null,
    invoices: [],
  },
  action,
) => {
  switch (action.type) {
    case GET_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action?.invoices,
      };
    case GET_INVOICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};
