import React, { useState, useEffect } from 'react';
import Loader from 'components/loader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// import logo from '../../assets/images/Greet-Eat-Logo-transparent.Updated.svg';
import logo from '../../assets/full-logo.png';
// import logo from '../../assets/logo.svg';
// import google from '../../assets/google.svg';
import createUserValidationSchema from '../formGroups/createUserSchema';

function Register(props) {
  const { error, loading, onRegister } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const navigate = useNavigate();
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, setError } = useForm({
    mode: 'all',
    resolver: yupResolver(createUserValidationSchema),
  });

  const { errors, isDirty, isValid } = formState;

  useEffect(() => {
    if (props && error && error?.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('email', { message: 'This email address is already being used.' });
          break;
        case 'auth/invalid-email':
          setError('email', { message: 'The email address is badly formatted.' });
          break;
        case 'auth/weak-password':
          setError('password', { message: 'Password should be at least 6 characters.' });
          break;
        default:
          setError('email', { message: 'Error happend on the server side. Please try again later.' });
          break;
      }
    }
  }, [error]);

  const onRegisterValidation = (data, event) => {
    let isAnyFieldEmpty = false;
    Object.keys(data).forEach(fieldName => {
      if (!data[fieldName]) {
        setError(fieldName, { type: 'required', message: `${fieldName} is required` });
        isAnyFieldEmpty = true;
      }
    });

    if (isAnyFieldEmpty) {
      return;
    }
    if (isDirty && isValid) {
      handleSubmit(onRegister(event, email, password, fullName));
    }
  };

  return (
    <>
      {loading && <Loader visible />}

      <div className="login-container lg:bg-cover min-h-screen md:bg-inherit">
        <div className="container-fluid text-center py-16">
          <img src={logo} className="h-25 w-36 m-auto" alt="Logo" />
          <h1 className="mt-10 text-7xl font-bold text-body">Welcome</h1>
          <form
            action=""
            onSubmit={handleSubmit(onRegisterValidation)}
            className="p-5 md:p-0 mt-14 max-w-xl w-full m-auto space-y-5"
          >
            <input
              type="text"
              required
              autoComplete="off"
              className="form-input"
              placeholder="Full name"
              {...register('fullName', {
                onChange: e => setFullName(e.target.value),
              })}
            />
            {errors.fullName?.message && (
              <div>
                <div className="-mt-2 ml-6 text-black-600 font-semibold">{errors.fullName?.message}</div>
              </div>
            )}
            <input
              type="text"
              required
              autoComplete="off"
              className="form-input"
              placeholder="Email Address"
              {...register('email', {
                onChange: e => setEmail(e.target.value),
              })}
            />
            {errors.email?.message && (
              <div>
                <div className="-mt-2 ml-6 text-black-600 font-semibold">{errors.email?.message}</div>
              </div>
            )}
            <input
              type="password"
              required
              autoComplete="off"
              className="form-input"
              placeholder="Password"
              {...register('password', {
                onChange: e => setPassword(e.target.value),
              })}
            />
            {errors.password?.message && (
              <div>
                <div className="-mt-2 ml-6 text-black-600 font-semibold">{errors.password?.message}</div>
              </div>
            )}
            <input
              type="password"
              required
              autoComplete="off"
              className="form-input"
              placeholder="Confirm Password"
              {...register('confirmPassword', { onChange: e => setPassword(e.target.value) })}
            />
            {errors.confirmPassword?.message && (
              <div>
                <div className="-mt-2 ml-6 text-black-600 font-semibold">{errors.confirmPassword?.message}</div>
              </div>
            )}
            <div className="flex flex-col items-center space-y-6">
              <button type="submit" className="button btn btn-md mt-10">
                SIGN UP
              </button>
              <button type="button" className="button btn btn-sm mt-10" onClick={() => navigate('/')}>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

Register.propTypes = {
  loading: PropTypes.bool.isRequired,
  onRegister: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onGoogleLogin: PropTypes.func.isRequired,
};
export default Register;
